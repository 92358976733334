import React, {forwardRef, FunctionComponent, PropsWithChildren} from 'react'
import {WrapperItem, WrapperRoot, CheckboxAdditionalDetails} from "@biron-data/react-components"
import {CheckCircleIcon} from "@heroicons/react/outline"
import {TargetSelector} from "@biron-data/react-bqconf"
import {useLanguageResolver} from "@biron-data/react-contexts"

interface Props extends PropsWithChildren {
  name: string
  additionalDetails: CheckboxAdditionalDetails[]
}

export const TargetsWrapper: FunctionComponent<Props> = ({children, name, ...props}) => (
  <WrapperRoot {...{
    icon: CheckCircleIcon,
    label: "configuration-label-targets",
    ...props,
  }}>
    <WrapperItem name={name}>
    {children}
  </WrapperItem>
  </WrapperRoot>
)

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const TargetsField = forwardRef<any, any>(function Field({...props}, ref) {
  const languageResolver = useLanguageResolver()
  return <TargetSelector {...{...props, languageResolver}}/>
})
