import React, {FunctionComponent} from 'react'
import {WrapperItem, WrapperRoot} from "@biron-data/react-components"
import {CodeIcon} from "@heroicons/react/outline"
import {InputNumber} from "antd"

interface Props {
  name: string
  value?: number
  onChange?: (newValue: number) => void
}

const Position: FunctionComponent<Props> = ({
                                              name,
                                              onChange,
                                              value,
                                            }) => <WrapperRoot
  icon={CodeIcon}
  label={'form-label-position'}
  itemColumnWidth={8}>
  <WrapperItem name={name}>
    <InputNumber
      value={value}
      onChange={newValue => {
        if (newValue) {
          onChange?.(newValue)
        }
      }}/>
  </WrapperItem>
</WrapperRoot>

export default Position
