import React, {FunctionComponent, useCallback} from 'react'
import Language from 'language'
import {Radio} from 'antd'
import styled from "styled-components"
import {RadioChangeEvent} from "antd/lib/radio/interface"
import {Granularity} from '@biron-data/period-resolver'

interface Props {
  value: Granularity
  onChange: (newValue: Granularity | undefined) => void
}

const GranularitySelector: FunctionComponent<Props> = ({value, onChange}) => {
  const handleChange = useCallback(
    (e: RadioChangeEvent) => onChange(e.target.value),
    [onChange],
  )
  return <RadioGroup value={value} onChange={handleChange}>
    {[Granularity.DAY, Granularity.WEEK, Granularity.MONTH, Granularity.QUARTER, Granularity.YEAR].map(
      // @ts-ignore
      v => <RadioButton key={v} size="small"
                        value={v}><StyledSpan>{Language.get(`calendar-granularity-${v.toLowerCase()}`)}</StyledSpan></RadioButton>,
    )}
  </RadioGroup>
}

export default GranularitySelector

const RadioGroup = styled(Radio.Group)`
  max-height: 40px;
  border: 1px solid var(--border-color-base);
`

const StyledSpan = styled.span`
  @media (max-width: 850px) {
    &&& {
      overflow: hidden;
      width: 2.5ch;
      display: block;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
`

const RadioButton = styled(Radio.Button)`
  overflow: hidden;
`