import React, {FunctionComponent, useMemo, useRef, useState} from 'react'
import {DataSourceObjectType} from 'components/dataSourceDoc/DataSource.types'
import Language from "language"
import {ExpandedWorkspace, SummarizedWorkspace} from "redux/models/workspace"
import {TableColumnProps} from "antd"
import {MatchParams} from "components/dataSourceDoc/DataSource"
import AdministrationLayout, {ContentContainer, InformationsContainer} from "components/layout/AdministrationLayout"
import {CardEffectDiv} from "themes/SharedStyledComponent"
import {useNavigate, useParams} from "react-router-dom"
import DetailsInformations from "components/dataSourceDoc/details/Details.Informations"
import DatasourceTable from "components/dataSourceDoc/DataSource.Table"
import {MetaModelView} from "@biron-data/react-bqconf"
import {TableLink, MdView, ColorizedTag} from "@biron-data/react-components"
import {Search} from "components/search/Search"
import {
  usePercentageValueInPixels,
  useSearchOnValues,
  useTextWithHighLight,
  useTextWithHighLightAsString,
  useTypeRepresentationColor,
} from "components/dataSourceDoc/DataSource.hooks"
import {buildWorkspaceUri} from "components/workspace/Workspace.utils"
import {
  CardContentContainerWithLimitedHeight,
  DataSourceDocTitle,
  SizeReference,
  TableContainer,
} from "components/dataSourceDoc/DataSource.style"
import {useResizeDetector} from "@biron-data/react-hooks"
import DatadocBreadcrumb from "../utils/Breadcrumb";

interface Props {
  currentWorkspace: ExpandedWorkspace | SummarizedWorkspace
  views: MetaModelView[]
}
interface ViewColumn {
  type: DataSourceObjectType
  code: string
  alias: string
  description: string
}

export const DetailsView: FunctionComponent<Props> = ({views, currentWorkspace}) => {
  const navigate = useNavigate()
  const {code} = useParams<MatchParams>()
  const view = useMemo(() => {
    const matchingView = views.find(m => m.code === code)
    if (matchingView) {
      return matchingView
    } else {
      navigate("/")
      return views[0]
    }
  }, [code, navigate, views])

  const dataSource: ViewColumn[] = useMemo(() => [...view.metrics.map(m => ({
    type: DataSourceObjectType.METRIC,
    alias: m.alias,
    code: m.code,
    description: m.description,
  })), ...view.dimensions.map(d => ({
    type: DataSourceObjectType.DIMENSION,
    alias: d.alias,
    code: d.code,
    description: d.description,
  }))], [view.dimensions, view.metrics])

  const [consolidatedData, setConsolidatedData] = useState(dataSource)
  const [search, setSearch] = useState("")

  const filteredValues = useMemo(() => [['alias'], ['description']], [])

  useSearchOnValues(search, filteredValues, dataSource, setConsolidatedData)

  const getTextWithHighLightAsString = useTextWithHighLightAsString(search)
  const getTextWithHighLight = useTextWithHighLight(search)
  const getTypeRepresentationColor = useTypeRepresentationColor()
  const [size, setSize] = useState({
    width: 0,
  })
  const getPercentageValueInPixels = usePercentageValueInPixels(600, size.width)

  const columns: TableColumnProps<ViewColumn>[] = useMemo(() => size.width ? [
    {
      title: Language.get(`dataDoc.columns.object`),
      dataIndex: 'type',
      className: 'col-with-centered-content',
      width: getPercentageValueInPixels(13),
      filters: [DataSourceObjectType.DIMENSION, DataSourceObjectType.METRIC].map((source) => ({
        text: Language.get("dataDoc.details.type", source),
        value: source,
      })),
      onFilter: (value, record) => {
        return record.type === value.toString()
      },
      render: (source: DataSourceObjectType) => {
        return <ColorizedTag text={Language.get("dataDoc.details.type", source)} color={getTypeRepresentationColor(source)}/>
      },
      style: {
        display: "flex",
      },
    },
    {
      title: Language.get(`dataDoc.columns.name`),
      dataIndex: 'alias',
      render: (text: string, record: ViewColumn) => {
        const path = `help/${record.type}/${record.code}`
        const link = buildWorkspaceUri(currentWorkspace, path)
        return <TableLink link={link}>{getTextWithHighLight(text)}</TableLink>
      },
      width: getPercentageValueInPixels(24),
    },
    {
      title: Language.get(`dataDoc.columns.description`),
      dataIndex: 'description',
      width: getPercentageValueInPixels(63),
      render: function render(text: string) {
        return <MdView description={getTextWithHighLightAsString(text)} padding={0}/>
      },
    },
  ] : [], [currentWorkspace, getTypeRepresentationColor, getPercentageValueInPixels, getTextWithHighLight, getTextWithHighLightAsString, size.width])

  const container = useRef<any>()
  useResizeDetector(container, size, (newWidth) => {
    setSize({
      width: newWidth,
    })
  })
  const onSearch = (val: string) => {
    setSearch(val)
  }

  return <AdministrationLayout
  breadcrumb={<DatadocBreadcrumb code={code} alias={view.alias} type={DataSourceObjectType.VIEW}/>}>
    <InformationsContainer>
      <DetailsInformations description={view.description} links={[]} id={view.code}/>
    </InformationsContainer>
    <ContentContainer style={{maxHeight: "100%"}}>
      <CardEffectDiv>
        <CardContentContainerWithLimitedHeight>
          <DataSourceDocTitle>{Language.get('dataDoc.details.availableObject')}</DataSourceDocTitle>
          <SizeReference ref={container}>
            {size.width && <TableContainer>
              <Search
                width={45}
                onChange={onSearch}/>
              <DatasourceTable
                dataSource={consolidatedData}
                columns={columns}/>
            </TableContainer>}
          </SizeReference>
        </CardContentContainerWithLimitedHeight>
      </CardEffectDiv>
    </ContentContainer>
  </AdministrationLayout>
}
