import React, {forwardRef, FunctionComponent, PropsWithChildren} from 'react'
import {WrapperItem, WrapperRoot} from "@biron-data/react-components"
import ChartTypeSelector from '../selector/chartType/ChartTypeSelector'

export interface Props extends PropsWithChildren {
  name: string,
}

export const ChartTypeWrapper: FunctionComponent<Props> = ({children, name}) => <WrapperRoot>
  <WrapperItem name={name}>
    {children}
  </WrapperItem>
</WrapperRoot>

interface FieldProps {
  name: string,
  chartTypes: any
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const ChartTypeField = forwardRef<any, FieldProps>(function Field({...props}, ref) {
  return <ChartTypeSelector {...props}/>
})
