import {predefinedPeriod, Granularity, PeriodTypes} from "@biron-data/period-resolver"
import {useMemo} from "react"
import {WidgetTypes} from "commons/dashboard/dashboard.types"
import {Export} from "components/forms/Form.types"
import {ExportExtendedConfModel} from "components/forms/chart/types"
import {convertMetricToConfModel, convertSlicersToConfModel} from "commons/parsers/utils"
import {convertFilterDetailToConfFilter, extractSlicerDate, ConsolidatedDimension, GenericChartTypes} from "@biron-data/react-bqconf"
import {convertMetricHavingToConfFilters, convertOrderByToConfOrderBy} from "components/forms/chart/utils"

export const usePeriodFromCode = (periods: PeriodTypes[], code?: string) => useMemo(() => periods.find(p => p.code === code) ?? predefinedPeriod.CURRENT_MONTH, [code, periods])

export const useExportConfModel = (data: Export, period: PeriodTypes, availableDimensions: ConsolidatedDimension[]): ExportExtendedConfModel => useMemo(() => ({
  ...data,
  type: WidgetTypes.EXPORT,
  displayType: GenericChartTypes.TABLES,
  uniqueView: null,
  period,
  x: 0,
  y: 0,
  w: 0,
  h: 0,
  extraConf: {
    displayType: GenericChartTypes.TABLES,
  },
  dateSlicerGranularity: extractSlicerDate(data.slicers)?.granularity ?? Granularity.DAY,
  slicers: convertSlicersToConfModel(data.slicers),
  metrics: convertMetricToConfModel(data.metrics, availableDimensions),
  filters: convertFilterDetailToConfFilter(data.filters),
  metricFilters: convertMetricHavingToConfFilters(data.metrics),
  orderBys: convertOrderByToConfOrderBy(data.orderBys),
}), [availableDimensions, data, period])