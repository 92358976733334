import React, {FunctionComponent, useEffect} from 'react'
import {useSelector} from "react-redux"
import {getCurrentWorkspaceName} from "redux/workspace.selector"
import {getCurrentEnvironmentId} from "redux/environment.selector"
import {getCopiedChart} from "redux/clipboard.selector"
import {getCharts, getMenu, getSelection, getWorkspaceDashboard} from "redux/currentDashboard.selector"
import {getDashboardEditMode} from "redux/appEnvironment.selector"
import Dashboard from "./Dashboard"
import useDispatch from "hooks/useDispatch"
import DashboardErrorBoundary from 'components/dashboard/Dashboard.ErrorBoundary'
import {MetaModel} from "@biron-data/react-bqconf"
import {omit} from "lodash"

interface Props {
  metaModel: MetaModel
  canEditDashboardContent: boolean
  canEditDashboardProperties: boolean
}

const DashboardContainer: FunctionComponent<Props> = ({
                                                        metaModel,
                                                        canEditDashboardContent,
                                                        canEditDashboardProperties,
                                                      }) => {
  const dispatch = useDispatch()
  const currentWorkspaceName = useSelector(getCurrentWorkspaceName)
  const currentMenu = useSelector(getMenu)
  const dashboard = useSelector(getWorkspaceDashboard)
  const copiedChart = useSelector(getCopiedChart())
  const dashboardSelection = useSelector(getSelection)
  const environmentId: number = useSelector(getCurrentEnvironmentId) as number
  const editMode = useSelector(getDashboardEditMode)
  const charts = useSelector(getCharts)

  useEffect(() => {
    document.title = [currentWorkspaceName, dashboard?.title ?? "", 'Biron'].join(' - ')
    return () => {
      document.title = 'Biron'
    }
  }, [currentWorkspaceName, dashboard])

  const availableDimensions = metaModel?.listDimensions()

  return <DashboardErrorBoundary>
    {dashboard
      && dashboardSelection && <Dashboard {...{
        environmentId,
        metaModel,
        availableDimensions,
        dashboardTitlePrefix: currentMenu?.name,
        dashboard: omit(dashboard, "charts"),
        currentSelection: dashboardSelection,
        charts,
        canEditDashboardContent,
        canEditDashboardProperties,
        editMode,
        copiedChart,
        onConfChange: dispatch.currentDashboard.confUpdate,
        onLayoutChange: dispatch.currentDashboard.layoutUpdate,
        onChartAdd: dispatch.currentDashboard.chartAdd,
        onSelectionChange: dispatch.currentDashboard.selectionUpdate,
      }}/>}
  </DashboardErrorBoundary>
}

export default DashboardContainer
