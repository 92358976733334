import React, {useCallback, useEffect, useMemo, useState} from "react"
import {NormalizedDashboardTypes, NormalizedPersonalDashboardTypes} from "schemas/dashboard"
import Language from "language"
import {dayjs, Dayjs, getResolvedFromPeriod, predefinedPeriod} from "@biron-data/period-resolver"
import {IconContainer, TooltipPopover} from "@biron-data/react-components"
import {BookmarkIcon, DocumentIcon, MinusIcon, TrashIcon} from "@heroicons/react/outline"
import {ButtonWithoutMargin, DashboardTitle, FlexContainer, FlexContainerStart} from "components/mainLayout/menu/PersonalDashboardMenu.style"
import {DashboardTypes, Folder} from "types/dashboards"
import {notification} from "antd"
import {useSelector} from "react-redux"
import {getCurrentDashboard} from "redux/currentDashboard.selector"
import {NavigateFunction, useLocation} from "react-router-dom"
import {getDashboardFromList} from "redux/models/utils.model"
import {getPersonalDashboardCharts, getPersonalDashboards} from "redux/personalDashboard.selector"
import {getPeriods} from "redux/period.selector"
import {getLastEditedDashboard} from "redux/lastEditedDashboard.selector"
import {isEmpty} from "lodash"
import {Restore} from "@biron-data/icons/dist/outlined"

export const useCurrentUid = () => {
  const locationHooks = useLocation()

  return useMemo(() => {
    const splittedPathname = locationHooks.pathname.split("/").filter(element => !isEmpty(element))
    return splittedPathname[splittedPathname.length - 1]
  }, [locationHooks])
}

export const useUnStar = (updatePersonalDashboardFolder: ({dashboard, newFolderState}: {
  dashboard: Pick<NormalizedPersonalDashboardTypes, "uid" | "id">,
  newFolderState: Folder
}) => Promise<void>) => useCallback((dashboard: Pick<NormalizedPersonalDashboardTypes, "uid" | "id" | "title">) => {
  updatePersonalDashboardFolder({
    dashboard,
    newFolderState: Folder.DRAFT,
  }).then(() => {
    notification.info({
      duration: 2.5,
      key: 'unpin-draft',
      message: Language.get(`personal-dashboard.popup.unpin-draft`, dashboard.title),
      placement: 'bottomRight',
    })
  })
}, [updatePersonalDashboardFolder])

export const useRestore = (updatePersonalDashboardFolder: ({dashboard, newFolderState}: {
  dashboard: Pick<NormalizedPersonalDashboardTypes, "uid" | "id">,
  newFolderState: Folder
  redirect?: (removedUid: string) => void
}) => Promise<void>) => useCallback((dashboard: Pick<NormalizedPersonalDashboardTypes, "uid" | "id" | "title">) => {
  updatePersonalDashboardFolder({
    dashboard,
    newFolderState: Folder.DRAFT,
  }).then(() => {
    notification.info({
      duration: 2.5,
      key: 'restored-draft',
      message: Language.get(`personal-dashboard.popup.restored-draft`, dashboard.title),
      placement: 'bottomRight',
    })
  })
}, [updatePersonalDashboardFolder])

export const useDashboardsSort = () => useCallback((a: Pick<NormalizedPersonalDashboardTypes, "updatedAt">, b: Pick<NormalizedPersonalDashboardTypes, "updatedAt">): number => dayjs(a.updatedAt).isBefore(dayjs(b.updatedAt)) ? 1 : -1, [])

export const useTrash = (updatePersonalDashboardFolder: ({dashboard, newFolderState}: {
  dashboard: Pick<NormalizedPersonalDashboardTypes, "uid" | "id">
  newFolderState: Folder
  redirect?: (removedUid: string) => void
},) => Promise<void>, restore: (dashboard: Pick<NormalizedPersonalDashboardTypes, "uid" | "id" | "title">) => void, redirect: (removedUid: string) => void) => {
  return useCallback((dashboard: Pick<NormalizedPersonalDashboardTypes, "uid" | "id" | "title">) => {
    updatePersonalDashboardFolder({
      dashboard,
      newFolderState: Folder.TRASH,
      redirect
    }).then(() => {
      notification.info({
        duration: 30,
        key: 'trashed-draft',
        message: Language.get(`personal-dashboard.popup.trashed-draft`, dashboard.title),
        description: <ButtonWithoutMargin type={"link"} onClick={() => {
          restore(dashboard)
        }}>{Language.get('personal-dashboard.popup.restore')}</ButtonWithoutMargin>,
        placement: 'bottomRight',
      })
    })
  }, [redirect, restore, updatePersonalDashboardFolder])
}


export const useGetDraftCategory = (
  draftDashboards: NormalizedPersonalDashboardTypes[],
  updatePersonalDashboardFolder: ({dashboard, newFolderState}: {
    dashboard: Pick<NormalizedPersonalDashboardTypes, "uid" | "id">,
    newFolderState: Folder
    redirect?: (removedUid: string) => void
  },
  ) => Promise<void>, restore: ReturnType<typeof useRestore>, redirect: (removedUid: string) => void) => {
  const onTrash = useTrash(updatePersonalDashboardFolder, restore, redirect)

  return useCallback((label: string, startReference: Dayjs | undefined, endReference: Dayjs) => {
    const filteredDrafts = draftDashboards.filter(draftDashboard =>
      dayjs(draftDashboard.updatedAt).isBefore(endReference.endOf('day')) && (!startReference || dayjs(draftDashboard.updatedAt).isAfter(startReference.startOf('day'))),
    ).map(draftDashboard => ({
      key: draftDashboard.uid,
      label: <FlexContainer>
        <DashboardTitle>{draftDashboard.title}</DashboardTitle>
        <TooltipPopover size={'small'} mouseEnterDelay={0.4} content={Language.get('personal-dashboard.tooltip.trash')}>
          <div className={"trash"}>
            <IconContainer clickable={true} onClick={async () => {
              onTrash(draftDashboard)
            }}><TrashIcon/></IconContainer>
          </div>
        </TooltipPopover>
      </FlexContainer>,
      icon: <IconContainer size={14} margin={10}><DocumentIcon/></IconContainer>,
    }))
    return filteredDrafts.length === 0 ? [] : [
      {
        key: label,
        label,
        disabled: true,
      },
      ...filteredDrafts,
    ]
  }, [draftDashboards, onTrash])
}

export const useTodayDrafts = (getDraftCategory: ReturnType<typeof useGetDraftCategory>) => useMemo(() => getDraftCategory(Language.getTranslatedName(predefinedPeriod.TODAY), getResolvedFromPeriod(predefinedPeriod.TODAY).start.startOf("day"), getResolvedFromPeriod(predefinedPeriod.TODAY).end.endOf("day")), [getDraftCategory])

export const useLastSevenDaysDrafts = (getDraftCategory: ReturnType<typeof useGetDraftCategory>) => useMemo(() => getDraftCategory(Language.getTranslatedName(predefinedPeriod.LAST_7_DAYS), getResolvedFromPeriod(predefinedPeriod.LAST_7_DAYS).start, getResolvedFromPeriod(predefinedPeriod.YESTERDAY).end), [getDraftCategory])

export const useLastThirtyDaysDrafts = (getDraftCategory: ReturnType<typeof useGetDraftCategory>) => useMemo(() => getDraftCategory(Language.getTranslatedName(predefinedPeriod.LAST_30_DAYS), getResolvedFromPeriod(predefinedPeriod.LAST_30_DAYS).start, getResolvedFromPeriod(predefinedPeriod.LAST_7_DAYS).start), [getDraftCategory])

export const useOlderDrafts = (getDraftCategory: ReturnType<typeof useGetDraftCategory>) => useMemo(() => getDraftCategory('older', undefined, getResolvedFromPeriod(predefinedPeriod.LAST_30_DAYS).start), [getDraftCategory])


export const useStarredDrafts = (starredDashboards: NormalizedPersonalDashboardTypes[], updatePersonalDashboardFolder: ({dashboard, newFolderState}: {
  dashboard: Pick<NormalizedPersonalDashboardTypes, "uid" | "id">,
  newFolderState: Folder
}) => Promise<void>) => {
  const unStar = useUnStar(updatePersonalDashboardFolder)

  return useMemo(() => starredDashboards.length > 0 ? [{
    key: Language.get('personal-dashboard.pinned'),
    label: Language.get('personal-dashboard.pinned'),
    disabled: true,
  }, ...starredDashboards.map((starredDashboard) => ({
    key: starredDashboard.uid,
    label: <FlexContainer>
      <DashboardTitle>{starredDashboard.title}</DashboardTitle>
      <TooltipPopover size={'small'} mouseEnterDelay={0.4} content={Language.get('personal-dashboard.tooltip.unpin')}>
        <div className={"unpin"}>
          <IconContainer clickable={true} onClick={() => unStar(starredDashboard)}><MinusIcon/></IconContainer>
        </div>
      </TooltipPopover>
    </FlexContainer>,
    icon: <IconContainer size={14} margin={10}><BookmarkIcon/></IconContainer>,
  }))] : [], [starredDashboards, unStar])
}

export const useTrashedDrafts = (trashedDashboards: NormalizedPersonalDashboardTypes[], restore: ReturnType<typeof useRestore>) => useMemo(() => {
  return trashedDashboards.length > 0 ? [{
    key: 'trash',
    label: <FlexContainerStart>
      <IconContainer>
        <TrashIcon/>
      </IconContainer>
      {Language.get(`personal-dashboard.folder.${Folder.TRASH}`)}
    </FlexContainerStart>,
    children: [
      {
        key: "trash-count",
        label: `${trashedDashboards.length} ${Language.get(`personal-dashboard.element`)}${trashedDashboards.length > 1 ? 's' : ''}`,
        disabled: true,
      },
      ...trashedDashboards.map((trashedDashboard) => ({
        key: trashedDashboard.uid,
        label: <FlexContainer>
          <DashboardTitle>{trashedDashboard.title}</DashboardTitle>
          <TooltipPopover size={'small'} mouseEnterDelay={0.4} content={Language.get('personal-dashboard.tooltip.restore')}>
            <div className={"restore"}>
              <IconContainer clickable={true} onClick={() => restore(trashedDashboard)}>
                <Restore />
              </IconContainer>
            </div>
          </TooltipPopover>
        </FlexContainer>,
        icon: <IconContainer size={14} margin={10}><DocumentIcon/></IconContainer>,
      })),
    ],
  }] : []
}, [restore, trashedDashboards])

export const useLastUpdatedDraft = (currentUid: string, environmentId: number) => {
  const personalDashboards = useSelector(getPersonalDashboards)
  const currentDashboard = useSelector(getCurrentDashboard)
  const getCharts = useSelector(getPersonalDashboardCharts)
  const periods = useSelector(getPeriods)
  const lastOpenedPersonalDashboard = useSelector(getLastEditedDashboard)

  const [lastModifiedDraft, setLastModifiedDraft] = useState<({
    uid: string,
    environmentId: number
  } & Partial<NormalizedPersonalDashboardTypes>) | undefined>(lastOpenedPersonalDashboard ? {
    ...lastOpenedPersonalDashboard
  } : undefined)

  useEffect(() => {
    if (lastOpenedPersonalDashboard && environmentId === lastOpenedPersonalDashboard?.environmentId) {
      const dashboard = getDashboardFromList(lastOpenedPersonalDashboard.uid, personalDashboards)?.dashboard
      if (dashboard?.type === DashboardTypes.personal) {
        setLastModifiedDraft(({...lastOpenedPersonalDashboard, ...dashboard}))
      }
    }
  }, [currentDashboard, currentUid, environmentId, getCharts, lastOpenedPersonalDashboard, periods, personalDashboards])


  return lastModifiedDraft
}

export const useMenuAutomaticOpening = (
  currentUid: string,
  draftDashboards: Pick<NormalizedPersonalDashboardTypes, 'uid'>[],
  starredDashboards: Pick<NormalizedPersonalDashboardTypes, 'uid'>[],
  trashedDashboards: Pick<NormalizedPersonalDashboardTypes, 'uid'>[],
  setOpenDraftKeys: React.Dispatch<React.SetStateAction<string[]>>
) => {
  return useEffect(() => {
    if ([...draftDashboards, ...starredDashboards].find(draftDashboard => draftDashboard.uid === currentUid)) {
      setOpenDraftKeys(old => old.find(key => key === 'draft') ? old : [...old, 'draft'])
    } else if (trashedDashboards.find(draftDashboard => draftDashboard.uid === currentUid)) {
      setOpenDraftKeys(old => old.find(key => key === 'draft') && old.find(key => key === 'trash') ? old : [...old, 'draft', 'trash'])
    }
    // There is no need to recompute after list modification as the opened menu are only impacted by currentUid
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUid])
}

export const useDefaultRedirect = (
  currentUid: string,
  environmentId: number,
  draftDashboards: Pick<NormalizedPersonalDashboardTypes, "uid" | "updatedAt">[],
  navigate: NavigateFunction,
  workspaceUriBuilder: (pathOrDashboard?: string | NormalizedDashboardTypes) => string,
  sortDashboards: ReturnType<typeof useDashboardsSort>
) => {
  return useCallback((removedUid: string) => {
    const otherDraft = draftDashboards.filter(dashboard => ![currentUid, removedUid].includes(dashboard.uid))
    if(otherDraft.length === 0) {
      return navigate(workspaceUriBuilder())
    }
    return navigate(`/app/personal/${environmentId}/${otherDraft.sort(sortDashboards)[0].uid}`)
  }, [currentUid, draftDashboards, environmentId, navigate, sortDashboards, workspaceUriBuilder])
}
