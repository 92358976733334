import {denormalize, normalize, schema} from 'normalizr'
import {UAC, User} from "components/admin/user/UserManager.types"
import {ModelUser} from "redux/models/user"

const userResume = new schema.Entity(
  'users',
  {},
  {
    processStrategy: value => ({
      ...value,
      fullName: `${value.lastName} ${value.firstName}`,
    }),
  },
)
const userAssocClientResume = new schema.Entity(
  'userAssocClients',
  {
    user: userResume,
  },
)
const userAssocClientResumes = new schema.Array(userAssocClientResume)
export const normalizeUserResume = (data: User) => normalize(data, userResume)
export const normalizeAssocClientResume = (data: UAC) => normalize(data, userAssocClientResume)
export const normalizeAssocClientResumes = (data: UAC[]) => normalize(data, userAssocClientResumes)
export const denormalizeAssocClientResumes = (input: string[], entities: ModelUser["entities"]) => denormalize(input, userAssocClientResumes, entities)
