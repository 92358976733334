import React, {memo} from 'react'
import {Radio} from 'antd'
import Language from "language"
import {ExtendedWidgetTypes} from "commons/dashboard/dashboard.types"
import styled from "styled-components"
import {TooltipPopover, MdView} from "@biron-data/react-components"
import WidgetIcon from "components/widgetContainer/WidgetIcon"

interface Props {
  type: ExtendedWidgetTypes,
  disabledReasons: string[]
  checked?: boolean
}

export default memo<Props>(function ChartTypeItem({type, disabledReasons, checked}) {
  const disabled = disabledReasons.length > 0
  const fullDescription = Language.get(`dashboard-add-${type}`, false)
  const description = (disabled
        ? `${Language.get(`configuration-chart-type-disable.description`, disabledReasons.map(reason => Language.get(`configuration-chart-type-disable.reasons.${reason}`)))}\n`
        : ''
    )
    + fullDescription.substr(0, fullDescription.indexOf('\n###Options:'))
  return <RadioButton value={type} disabled={disabled} checked={checked}>
    <TooltipPopover
      size={'small'}
      placement="bottom"
      mouseEnterDelay={0.5}
      autoAdjustOverflow={false}
      content={<MdView description={description}/>}>
      <FlexDiv>
        <WidgetIcon type={type} size={18} margin={10}/>
        {Language.get(`dashboard-add-${type}-label`)}
      </FlexDiv>
    </TooltipPopover>
  </RadioButton>
})

const FlexDiv = styled.div`
  display: flex;
  align-items: center;
`
const RadioButton = styled(Radio.Button)`
  width: 100%;
  height: 100%;
  display: flex;
  
  &&& {
    border: 1px solid var(--border-color-input);
    border-right-color: var(--border-color-input) !important;
  }
`