import React, {FunctionComponent} from 'react'
import Language from 'language'
import {TooltipPopover, MdView} from "@biron-data/react-components"

// the 2 const bellow are exported to avoid a recreation of this object which could break the usage of React.memo
export const descriptionTypePopover = {isPopover: true}
export const descriptionTypeNotPopover = {isPopover: false}

interface Props {
  title?: string
  trigger: JSX.Element
  descriptionType?: {
    isPopover: boolean
  }
}

const FormTrigger: FunctionComponent<Props> = ({trigger, descriptionType, title = Language.get('configuration')}) => {
  if (descriptionType) {
    if (descriptionType.isPopover) {
      return <TooltipPopover
        size={'small'}
        content={title}>
        {trigger}
      </TooltipPopover>
    }
    return <trigger.type {...trigger.props} title={title}/>
  }
    return trigger
}
export default FormTrigger
