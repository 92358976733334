import Language from "language"
import {PeriodExtendedConfModel} from "components/forms/chart/types"
import {PeriodEdgePoint, PeriodType, PeriodTypes, dayjs} from "@biron-data/period-resolver"

export const verifyUniquenessOfCode = (periods: PeriodTypes[]) => ({getFieldValue}: { getFieldValue: any }) => ({
  validator(_: any, value: any) {
    if (!value || (getFieldValue('code').trim() === "" || !!periods.find(period => period.code === getFieldValue('code')))) {
      return Promise.reject(new Error(Language.get("period.form.code-error")))
    }
    return Promise.resolve()
  },
})

export const onValuesChange = (newValues: any, handleValuesChange: (values: PeriodExtendedConfModel) => void) => {
  const newFormattedValues = Object.assign({}, newValues)
  if (Object.keys(newValues)[0] === "type") {
    if (newValues.type === PeriodType.dynamic) {
      newFormattedValues.start = {
        value: PeriodEdgePoint.TODAY,
      }
      newFormattedValues.end = {
        value: PeriodEdgePoint.TODAY,
      }
    } else {
      newFormattedValues.start = dayjs().format()
      newFormattedValues.end = dayjs().format()
    }
  }
  handleValuesChange(newFormattedValues)
}