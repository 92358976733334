import ReactGA4 from "react-ga4"
import {WidgetTypes} from "commons/dashboard/dashboard.types"

let env: {
  key: string,
}

export const setEnv = (key: string) => {
  env = {
    key,
  }
}

export const init = (userId: string) => {
  const {key, ...otherEnv} = env

  if (key) {
    ReactGA4.initialize(key, {
      ...otherEnv,
    })
    ReactGA4.gtag("set", "user_properties", {
      bironUserId: userId,
    })
  }
}

export const captureEvent = (event: {
  category: string
  action: string
  widgetType?: WidgetTypes
}) => ReactGA4.event(event)

export const pageview = (navigationUrl: string) => ReactGA4.send({hitType: "pageview", page: navigationUrl})
