/* eslint-disable max-lines */
import React, {memo, useCallback, useEffect, useMemo, useRef, useState} from 'react'
import Language from 'language'
import {Layout, Menu} from 'antd'
import FormTriggerable from 'components/forms/Form.Triggerable'
import MainLayoutWorkspaceSelector from 'components/mainLayout/MainLayout.WorkspaceSelector'
import {useMediaDesktop} from "@biron-data/react-hooks"
import {FormItemKeys} from "commons/keywords/form"
import {useLocation, useNavigate} from "react-router-dom"
import {MetaModel} from "@biron-data/react-bqconf"
import {ExpandedWorkspace} from "redux/models/workspace"
import {MenuItemModel} from "types/navigation"
// @ts-ignore
import img from "themes/img/logo-biron.png"
import {useSelector} from "react-redux"
import {getUser} from "redux/appEnvironment.selector"
import {IconContainer} from "@biron-data/react-components"
import {AdjustmentsIcon, BookOpenIcon, PlusCircleIcon, SupportIcon, UserCircleIcon} from "@heroicons/react/outline"
import {HandleMenuActionParamTypes} from "components/workspace/WorkspaceBridge.SiderContainer"
import {FormKeys} from "components/forms/Form.types"
import {AuthContextProps} from "react-oidc-context"
import MainLayoutExtraActionsWrapper from "components/mainLayout/MainLayout.ExtraActionsWrapper"
import MainLayoutMenuLink from "components/mainLayout/MainLayout.MenuLink"
import {isEmpty, omit} from "lodash"
import {
  BottomPart,
  ButtonWithWhiteText,
  FlexContainer,
  FlexDiv,
  LabelContainer,
  LogoContainer,
  LogoLink,
  MenuContainer,
  MenuLayout,
  MenuWithTopBorder,
  SiderContent,
  UpperPart,
} from './MainLayout.Sider.style'
import PersonalDashboardMenu, {PersonalDashboardMenuRef} from "components/mainLayout/menu/PersonalDashboardMenu"
import {NormalizedDashboardTypes} from "schemas/dashboard"
import {PermissionsEnum} from "redux/models/appEnvironment"

interface Props {
  items: MenuItemModel[]
  canAddMenu: boolean
  currentWorkspace: ExpandedWorkspace
  // navigationUrl: string
  onMenuAction: (params: HandleMenuActionParamTypes) => void
  metaModel: MetaModel
  workspaces: ExpandedWorkspace[]
  workspace: ExpandedWorkspace
  getPermission?: (s: PermissionsEnum) => boolean
  isTicketUser?: boolean
  onLogout: ({auth}: { auth?: AuthContextProps }) => void
  workspaceUriBuilder: (pathOrDashboard?: string | NormalizedDashboardTypes) => string
}

// eslint-disable-next-line react/display-name
const MainLayoutSider = memo<Props>(({
                                       currentWorkspace,
                                       workspaces,
                                       // navigationUrl,
                                       items,
                                       canAddMenu,
                                       onMenuAction,
                                       metaModel,
                                       workspace,
                                       getPermission,
                                       isTicketUser,
                                       onLogout,
                                       workspaceUriBuilder,
                                     }) => {
  const userCanSetPowerUser = getPermission?.(PermissionsEnum.userCanManageAdvancedFeature)
  const withDashboardPPermission = getPermission?.(PermissionsEnum.withDashboardP)

  const isMediaDesktop = useMediaDesktop()
  const [editMode, setEditMode] = useState(false)
  const [openedMenus, setOpenedMenus] = useState<any[]>([])
  const [selectedDashboardMenuItem, setSelectedDashboardMenuItem] = useState<string[] | undefined>()
  const [selectedBottomMenuItem, setSelectedBottomMenuItem] = useState<string[] | undefined>()
  const userInfo = useSelector(getUser)
  const navigate = useNavigate()
  const personalDashboardMenuRef = useRef<PersonalDashboardMenuRef>(null)

  const location = useLocation()

  const selectedDashboardItem = useMemo(() => {
      const isPathNameStaringWithLink = (link: string) => `${link}-`?.startsWith(location.pathname)

      const matchingItems = items.map(item => {
        if (isPathNameStaringWithLink(item.link)) {
          return item
        }
        if (item.children?.filter(child => {
          return isPathNameStaringWithLink(child.link)
        }).length > 0) {
          return {
            ...item,
            children: item.children?.filter(child => isPathNameStaringWithLink(child.link)),
          }
        }
        return undefined
      }).filter(item => !isEmpty(item))
      return matchingItems[0]
    }, [items, location.pathname],
  )

  // sider is collapsible only for low resolution
  const siderCollapsible = !isMediaDesktop
  const [siderCollapsed, setSiderCollapsed] = useState(siderCollapsible)
  // when navigationUrl change collapse the sider
  useEffect(() => {
    if (siderCollapsible) {
      setSiderCollapsed(true)
    }
  }, [siderCollapsible, setSiderCollapsed, location.pathname])
  const siderStyle = useMemo(() => ({
    ...(siderCollapsible
        ? {
          position: "absolute",
          zIndex: 1,
          transition: "none",
        }
        : {}
    ),
  }), [siderCollapsible])

  useEffect(() => {
    const selectedDashboardItemKey: any = selectedDashboardItem?.key
    if (selectedDashboardItemKey && !openedMenus.includes(selectedDashboardItemKey)) {
      setOpenedMenus([...openedMenus, selectedDashboardItemKey])
    }
  }, [selectedDashboardItem, openedMenus])

  const addMenuDefaultValue: any = {
    [FormItemKeys.POSITION_MENU]: currentWorkspace.menus.length,
  }

  useEffect(() => {
    if (selectedDashboardItem) {
      setSelectedDashboardMenuItem([selectedDashboardItem.key, selectedDashboardItem.children?.[0].key])
    } else {
      setSelectedDashboardMenuItem([])
    }
  }, [selectedDashboardItem])

  const onSelectDashboardItem = useCallback((selection: { selectedKeys: string[] }) => {
    setSelectedDashboardMenuItem(selection.selectedKeys)
    setSelectedBottomMenuItem([])
  }, [])

  const onSelectBottomMenuItem = useCallback((selection: { selectedKeys: string[] }) => {
    setSelectedDashboardMenuItem([])
    setSelectedBottomMenuItem(selection.selectedKeys)
  }, [])

  const getMenuItem = (item: MenuItemModel, index: number, selected: boolean) => {
    if (item.type === 'menu') {
      const {label, icon: Icon, extraActions = []} = item
      return {
        key: item.key,
        label: <MenuLayout className={`${selected ? 'selected-menu' : ''}`}
        >
          <LabelContainer title={label}>
            {Icon && <IconContainer><Icon/></IconContainer>}{`${label}`}
          </LabelContainer>
          {editMode && extraActions && <MainLayoutExtraActionsWrapper {...{
            confs: extraActions,
            onAction: onMenuAction,
            metaModel,
            workspace,
          }}/>}
        </MenuLayout>,
        children: item.children.filter(Boolean).map(child => ({
          key: child.key,
            label: <MainLayoutMenuLink {...{
              ...omit(child, ['key']),
              onAction: onMenuAction,
              switchEditMode: index === 0 && canAddMenu ? setEditMode : false,
              metaModel,
              workspace,
              editMode,
              submenu: true,
            }} />,
          }),
        ),
      }
    } else {
      return {
        key: item.key,
        label: <MainLayoutMenuLink key={item.key} {...{
          ...omit(item, ['key']),
          onAction: onMenuAction,
          metaModel,
          workspace,
          switchEditMode: index === 0 && canAddMenu ? setEditMode : false,
          editMode,
        }}/>,
      }
    }
  }

  const onOpenChange = useCallback(() => {
    personalDashboardMenuRef.current?.close()
  }, [])

  return <Layout.Sider
    breakpoint="xl"
    collapsedWidth="0"
    collapsed={siderCollapsed}
    onCollapse={setSiderCollapsed}
    width={240}
    className="main-layout-sider"
    // @ts-ignore
    style={siderStyle}
  >
    <SiderContent>
      <UpperPart>
        <LogoContainer>
          <LogoLink to="/app"><img src={img} alt="logo"/></LogoLink>
        </LogoContainer>
        <MainLayoutWorkspaceSelector {...{
          currentWorkspace,
          workspaces,
          onOpenChange
        }}>
          <MenuContainer>
            <Menu mode="inline"
                  selectedKeys={selectedDashboardMenuItem}
                  openKeys={siderCollapsed ? [] : openedMenus}
                  onOpenChange={setOpenedMenus}
                  onSelect={onSelectDashboardItem}
                  onClick={e => {
                    e.domEvent.stopPropagation()
                  }}
                  items={[...items.filter(Boolean).map((item, index) => getMenuItem(item, index, item === selectedDashboardItem)), ...(canAddMenu && editMode ? [{
                    key: 'add-menu',
                    label: <FormTriggerable
                      trigger={
                        <ButtonWithWhiteText type={"link"}>
                          <IconContainer margin={10}><PlusCircleIcon/></IconContainer>
                          {Language.get("navigation-tools-add-menu")}
                        </ButtonWithWhiteText>
                      }
                      formType={{type: FormKeys.ADD_MENU}}
                      value={addMenuDefaultValue}
                      metaModel={metaModel}
                      workspace={workspace}
                      onChange={newConf => {
                        onMenuAction({
                          eventKey: FormKeys.ADD_MENU,
                          value: newConf,
                          specificId: undefined,
                        })
                      }}
                      title={Language.get('navigation-tools-add-menu')}
                    />,
                  }] : [])]}>
            </Menu>
          </MenuContainer>
        </MainLayoutWorkspaceSelector>
        {withDashboardPPermission && <PersonalDashboardMenu ref={personalDashboardMenuRef} workspaceUriBuilder={workspaceUriBuilder}/>}
      </UpperPart>
      <BottomPart>
        <MenuWithTopBorder
          theme={"biron" as any /* Menu types does not register additional themes */}
          mode="vertical"
          defaultSelectedKeys={[location.pathname]}
          subMenuOpenDelay={.5}
          triggerSubMenuAction={"click"}
          selectedKeys={selectedBottomMenuItem}
          onSelect={onSelectBottomMenuItem}
          onClick={(e) => {
            switch (e.key) {
              case "logout":
                onLogout({})
                break
              case "/delegate/kb":
                window.open("/delegate/kb", '_blank')
                break
              case `help`:
                navigate(workspaceUriBuilder(`help`))
                break
              case workspaceUriBuilder(`help`):
                navigate(workspaceUriBuilder(`help`))
                break
              case "/delegate/tickets":
                window.open("/delegate/tickets", '_blank')
                break
              case workspaceUriBuilder('admin/user'):
                navigate(workspaceUriBuilder('admin/user'))
                break
              case workspaceUriBuilder(`admin/exports`):
                navigate(workspaceUriBuilder(`admin/exports`))
                break
              case workspaceUriBuilder(`admin/periods`):
                navigate(workspaceUriBuilder(`admin/periods`))
                break
              case workspaceUriBuilder(`workspaces`):
                navigate(workspaceUriBuilder(`workspaces`))
                break
              default:
            }
          }}
          items={[
            {
              key: userInfo?.fullName ?? "",
              label: userInfo?.fullName,
              icon: <FlexContainer><IconContainer margin={10}><UserCircleIcon/></IconContainer></FlexContainer>,
              children: [{
                key: "logout",
                label: Language.get('navigation-log-out'),
              }],
            },
            {
              key: 'navigation-help',
              label: Language.get('navigation-help'),
              icon: <FlexContainer><IconContainer margin={10}><SupportIcon/></IconContainer></FlexContainer>,
              children: [{
                key: "/delegate/kb",
                label: Language.get('navigation-knowledge-base'),
              }, {
                key: workspaceUriBuilder(`help`),
                label: Language.get('definitions-link-label'),
              }, ...(isTicketUser ? [{
                key: "/delegate/tickets",
                label: Language.get('navigation-tickets'),
              }] : [])],
            },
            {
              key: "help",
              label: Language.get('definitions-link-label'),
              icon: <FlexContainer><IconContainer margin={10}><BookOpenIcon/></IconContainer></FlexContainer>,
            },
            ...(getPermission && getPermission(PermissionsEnum.userCanManageOnEnvironment) ? [{
              key: 'navigation-admin',
              label: Language.get("navigation-admin"),
              icon: <FlexDiv><IconContainer margin={10}><AdjustmentsIcon/></IconContainer></FlexDiv>,
              children: [{
                key: workspaceUriBuilder('admin/user'),
                label: Language.get("navigation-admin-user-list"),
              }, ...(userCanSetPowerUser ? [{
                key: workspaceUriBuilder(`workspaces`),
                label: Language.get("workspaces.sider-title"),
              }] : []),
                ...(userCanSetPowerUser ? [{
                  key: workspaceUriBuilder(`admin/exports`),
                  label: Language.get("export.sider-title"),
                }] : []),
                ...(userCanSetPowerUser ? [{
                key: workspaceUriBuilder(`admin/periods`),
                label: Language.get("period.sider-title"),
              }] : [])],
            }] : []),
          ]}
        />
      </BottomPart>
    </SiderContent>
  </Layout.Sider>
})

export default MainLayoutSider
