import React from 'react'
import {Dropdown, MenuProps} from 'antd'
import Language from 'language'
import {OnSnapshot} from "components/widgetContainer/WidgetContainer"
import {DocumentReportIcon, DownloadIcon, PhotographIcon} from "@heroicons/react/outline"
import styled from "styled-components"
import {TooltipPopover, IconContainer} from "@biron-data/react-components"

interface Props {
  onDownload: () => void
  onSnapshot?: OnSnapshot
  getPopupContainer?: (triggerNode: HTMLElement) => HTMLElement
}

function WidgetTopperDownload(props: Props): JSX.Element {
  const items: MenuProps['items'] = [
    {
      title: Language.get('chart-download-data-button'),
      icon: <StyledSpan>
        <IconContainer margin={5}><DocumentReportIcon/></IconContainer> csv
      </StyledSpan>,
      key: '0',
    },
    ...(props.onSnapshot ? [{
      title: Language.get('chart-download-png-button'),
      icon: <StyledSpan>
        <IconContainer margin={5}><PhotographIcon/></IconContainer> png
      </StyledSpan>,
      key: '1',
    }] : []),
  ]
  return <TooltipPopover
    size={"small"}
    trigger={"hover"}
    content={Language.get("chart-download-button")}>
    <div>
      <Dropdown menu={{
        items, onClick: (e) => {
          switch (e.key) {
            case "0":
              props.onDownload()
              break
            case "1":
              props.onSnapshot?.()
              break
            default:
              break
          }
        },
      }} trigger={['click']} getPopupContainer={props.getPopupContainer}>
        <IconContainer clickable={true} hoverColor={"var(--primary-light)"} containerSize={28}><DownloadIcon/></IconContainer>
      </Dropdown>
    </div>
  </TooltipPopover>
}


export default WidgetTopperDownload

const StyledSpan = styled.span`
  display: flex;
  align-items: center;
  `