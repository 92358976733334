import styled from "styled-components"
import {Button, Menu} from "antd"
import {Link} from "react-router-dom"

export const FlexDiv = styled.div`
    display: flex;
    align-items: center;
`

export const MenuContainer = styled.div`
`

export const ButtonWithWhiteText = styled(Button)`
    color: white;
    display: flex;
`

export const SiderContent = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    padding-top: 16px;
    padding-bottom: 16px;
`
export const FlexContainer = styled.div`
    display: flex;
    align-items: center;
`

export const UpperPart = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    min-height: 0;

    // Override antd default gap for menu childrens
`
export const BottomPart = styled.div`
`

export const LogoContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 34px;
    padding-left: 8px;
    margin-bottom: 8px;
    justify-content: center;
`

export const LogoLink = styled(Link)`
    height: 100%;
    flex-shrink: 0;

    > img {
        vertical-align: middle;
        margin-left: 6px;
        height: 100%;
    }

    @media (max-width: 992px) {
        flex-shrink: unset;
    }
`

export const MenuLayout = styled.div`
    display: flex;
    justify-content: space-between;
`
export const LabelContainer = styled.div`
    overflow: hidden;
    text-overflow: ellipsis;
`

export const MenuWithTopBorder = styled(Menu)`
    border-top: 1px var(--main-text-color) solid;
`