import * as React from 'react'
import {useCallback} from 'react'
import * as Sentry from "@sentry/react"
import {Provider} from 'react-redux'
import store from 'redux/store'
import {createGlobalStyle} from 'styled-components'
import AppSecured from "AppSecured"
import CacheBuster from "CacheBuster"
import {Button, ConfigProvider, Result} from "antd"
import Language from "language"
import {ErrorBoundaryResultContainer} from "@biron-data/react-components"
import {EventResolverContext} from '@biron-data/react-contexts'
import {captureEvent} from "services/GoogleAnalyticsService"
import {theme} from "@biron-data/biron-antd"
import "@biron-data/biron-antd/dist/standard.scss"

const App = () => {
  const handleReload = useCallback(() => {
    window.location.reload()
  }, [])

  const gaService = {
    capture: captureEvent,
  }


  return <Sentry.ErrorBoundary fallback={<ErrorBoundaryResultContainer>
    <Result
      status={"error"}
      title={Language.get("error-occurred")}
      extra={<Button type={"primary"} key={"reload"} onClick={handleReload}>{Language.get('reload-app')}</Button>}/>
  </ErrorBoundaryResultContainer>}>
    <ConfigProvider theme={theme}>
      <EventResolverContext.Provider value={gaService}>
        <CacheBuster>
          <Provider store={store}>
            <GlobalStyle/>
            <AppSecured/>
          </Provider>
        </CacheBuster>
      </EventResolverContext.Provider>
    </ConfigProvider>
  </Sentry.ErrorBoundary>
}

export default App

const GlobalStyle: any = createGlobalStyle`
    #app, #app > div, section.ant-layout {
        height: 100%;
    }

    #app > div.ant-spin.ant-spin-spinning, .dashboardportlet-body > div.ant-spin.ant-spin-spinning {
        height: 100%;
        width: 100%;
    }
`
