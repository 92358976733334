import Language from 'language'
import {ExpandedWorkspace} from "redux/models/workspace"
import {Position} from "classes/workflows/main-workflows/FormWorkflow"

export interface Option {
  value: number
  label: string,
  key?: string
}

interface DashboardPerMenu {
  [key: number]: Option[]
}

const positions = (array: Option[] = [], currentId?: number): Position[] => [
  {
    label: Language.get('form-position-at-the-start'),
    value: "0",
    key: 'first',
  },
  {
    label: Language.get('form-position-at-the-end'),
    value: "1000000",
    key: 'last',
  },
  ...array
    .filter(({value}) => value !== currentId)
    .map(({label}, index) => ({
      label: Language.get('form-position-after-xxx', {ref: label}),
      key: `${index + 1}`,
      value: `${index + 1}`
    }))
]

export default class WorkspaceModel {
  private readonly menus: Option[]
  private readonly dashboardsPerMenu: DashboardPerMenu

  constructor(workspace: Pick<ExpandedWorkspace, 'menus'>) {
    this.menus = workspace.menus.map(({id, name}) => ({
      value: id,
      label: name,
    }))
    this.dashboardsPerMenu = workspace.menus.reduce((acc, menu) => ({
      ...acc,
      [menu.id]: menu.dashboards?.map(({title, id}) => ({
        value: id,
        label: title,
      })),
    }), {})
  }

  getMenusAvailables = () => this.menus
  getMenusPositions = (currentMenuId: number) => positions(this.menus, currentMenuId)
  getDashboardsPositions = (menuId: number, currentDashboardId?: number) => positions(this.dashboardsPerMenu[menuId], currentDashboardId)
}
