import {GenericChartTypes} from "@biron-data/react-bqconf"

export enum WidgetTypes {
  DIVIDER = 'divider',
  GENERIC = 'generic',
  EXPORT = 'export',
  TARGET = 'target',
  UNKNOWN = 'unknown',
}

export type ExtendedWidgetTypes = WidgetTypes | GenericChartTypes