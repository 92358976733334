import React, {memo} from 'react'
import {FormItemKeys} from 'commons/keywords/form'
import {BironInput, BironSelect, Loader, SimpleSelect, WrapperItem, WrapperRoot} from "@biron-data/react-components"
import PositionMenu from '.././confItems/PositionMenu'
import {FormBodyComponentProps} from "components/forms/confBodies/FormBody"
import {Input} from "antd";
import {FolderIcon, PencilIcon} from "@heroicons/react/outline";

const DuplicateWorkspaceConf = <T, >({
                                   loading,
                                   availables: {menusPositions = []},
                                   getPopupContainer,
                                 }: FormBodyComponentProps<T>) => {
  return <Loader loading={loading}>
      <WrapperRoot
          icon={PencilIcon}
          label={'workspaces.form.workspace.name.title'}>
          <WrapperItem name={"name"}>
              <Input />
          </WrapperItem>
      </WrapperRoot>
  </Loader>
}

export default memo(DuplicateWorkspaceConf) as typeof DuplicateWorkspaceConf
