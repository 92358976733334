import React, {FunctionComponent, useCallback} from 'react'
import {
  ConsolidatedDimension,
  SemanticType,
  ConfDimensionFilterTypes,
  DimensionFilterDtoDetail,
  FilterType,
  SelectorFilterTypes,
  getDefaultDimensionPredicate, FilterPopover,
} from "@biron-data/react-bqconf"
import styled from "styled-components"
import {useMediaDesktop} from "@biron-data/react-hooks"
import {useDimensionOptions} from "@biron-data/react-bqconf/dist/hooks/useDimensionOptions"

interface Props {
  availableDimensions: ConsolidatedDimension[]
  view?: string
  onTemporaryFiltersChange: (filters: DimensionFilterDtoDetail[]) => void
  filters: DimensionFilterDtoDetail[]
}

const DashboardFilterableSettings: FunctionComponent<Props> = ({
                                                                 filters,
                                                                 availableDimensions,
                                                                 onTemporaryFiltersChange,
                                                               }) => {
  const isMediaDesktop = useMediaDesktop()
  const popoverPlacementOverride: any = useCallback((placement: string) => `${placement}Right`, [])

  const getOptions = useDimensionOptions(availableDimensions)

  const handleTemporaryFiltersChange = useCallback((confFilter: SelectorFilterTypes[]) => {
    onTemporaryFiltersChange(confFilter.map(filter => ({
      ...filter,
      type: FilterType.dimension,
      dimensionCode: filter.reference.code ?? "",
    })))
  }, [onTemporaryFiltersChange])

  return isMediaDesktop
    ? <DashboardFilterableSettingsContainer>
      <StyledDiv>
        <FilterPopover {...{
          withBackground: false,
          filters: filters.map(filter => ({
            ...filter,
            isValid: true,
            dataType: availableDimensions.find(dimension => dimension.code === filter.dimensionCode)?.semanticType ?? SemanticType.STRING,
          }) as ConfDimensionFilterTypes),
          getOptions,
          onTemporaryFiltersChange: handleTemporaryFiltersChange,
          placementOverride: popoverPlacementOverride,
          getDefaultValue: getDefaultDimensionPredicate,
        }}/>
      </StyledDiv>
    </DashboardFilterableSettingsContainer>
    : null
}

export default DashboardFilterableSettings

const DashboardFilterableSettingsContainer = styled.div`
    display: flex;
`

const StyledDiv = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
`
