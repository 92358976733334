import {normalize, schema} from "normalizr"
import {WidgetTypes} from "commons/dashboard/dashboard.types"
import {deserializeExtraConf} from "services/api"
import {omit} from "lodash"
import {RawChartTypes} from "types/charts"

export const chartDetail = new schema.Entity(
  'charts',
  {},
  {
    processStrategy: (chart) => {
      switch (chart.type) {
        case WidgetTypes.GENERIC:
          return deserializeExtraConf({
            ...omit(chart, ["bqConf"]),
            period: chart.bqConf.period ?? undefined,
            slicers: chart.bqConf.slicers,
            filters: chart.bqConf.filters,
            metrics: chart.bqConf.metrics,
            orderBys: chart.bqConf.orderBys,
            ignoreMetrics0: chart.bqConf.ignoreMetrics0,
          })
        default:
          return deserializeExtraConf(chart)
      }

    },
  },
)
export const normalizeChartDetail = (data: RawChartTypes) => normalize(data, chartDetail)