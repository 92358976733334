import ChartWorkflow, {DimensionGlobal} from 'classes/workflows/chart-workflows/ChartWorkflow'
import {TargetQueryWorkflowResult} from "classes/workflows/query-workflows/QueryWorkflow"
import {State} from "classes/workflows/main-workflows/FormWorkflow"
import {colorScale, numberScale} from "classes/workflows/chart-workflows/TargetChartWorkflow.utils"

export default class TargetChartWorkflow extends ChartWorkflow {
  constructor(widgetId: string, global: DimensionGlobal, data: TargetQueryWorkflowResult, setState: (newState: State) => void) {
    super(widgetId, global, data, setState)
    this.data = data
    this.dimensions = {
      global: {...global},
      enforcedLegend: {
        height: 27,
        width: 0,
      },
      svgLegend: {
        height: 14,
        width: 0,
      },
    };

    this.draw(this.data, this.dimensions)
  }

  update(data: TargetQueryWorkflowResult, key: string, dimensions: DimensionGlobal) {
    super.update(data, key, dimensions)
  }

  updateDimensions(key: string, dimensions: DimensionGlobal) {
    this.update(this.data as TargetQueryWorkflowResult, key, dimensions)
  }

  unsubscribe() {
    super.unsubscribe()
  }

  /**
   * process and compute the scales of the barchart
   * @param {object} chartData : chart data to print
   * @param {object} chartDimensions : {height, width} of the svgWrapper (minus the offset and the chart legend size)
   * @param {number} xOffset : offset x
   * @return {object} the state to update (use this.setState to update the chart)
   */
  getScales(chartData: TargetQueryWorkflowResult, chartDimensions: DimensionGlobal, xOffset = 0) {
    const {width} = chartDimensions
    const {series = []} = chartData
    const values = series.map(e => e.values[0])
    // @ts-ignore
    const colors = colorScale(series).domain(series)
    const x = numberScale([0].concat(values), [15 + xOffset, width - 15])
    return {
      colors,
      scale: {x},
    }
  }

  draw(chartData = this.data as TargetQueryWorkflowResult, dimensions = this.dimensions) {
    const {enforcedLegend, svgLegend} = dimensions
    const chartDimensions = {
      width: (dimensions.global?.width ?? 0),
      height: (dimensions.global?.height ?? 0) - (enforcedLegend?.height ?? 0) - (svgLegend?.height ?? 0),
    }
    dimensions.chartDimensions = chartDimensions
    const scale = this.getScales(chartData, chartDimensions, 0)
    this.setState({
      chartData,
      dimensions,
      // @ts-ignore
      offset: scale.offset,
      colors: scale.colors,
      scale: scale.scale,
      // @ts-ignore
    }, () => {
    })
  }
}
