import {createSelector} from "reselect"
import {RootState} from "redux/store"
import {PermissionsEnum} from "redux/models/appEnvironment"
import {DashboardTypes} from "types/dashboards"


export const getConsts = (state: RootState) => state.appEnvironment.consts

export const getUser = (state: RootState) => state.appEnvironment.user

export const getPermission = createSelector(
  (state: RootState) => state.appEnvironment.permissions,
  (permissions) => (perm: PermissionsEnum) => Boolean(permissions?.[perm]),
)

export const getClientAllowedEmailFqdns = (state: RootState) => state.appEnvironment?.environment.allowedEmailFqdns

export const hasAuthToken = (state: RootState) => Boolean(state.appEnvironment.authToken)

export const getDashboardEditMode = (state: RootState) => state.appEnvironment.dashboardEditMode
export const canEditDashboardProperties = (state: RootState) => Boolean(state.appEnvironment?.permissions?.workspaceCanEdit)
export const canEditDashboardContent = (state: RootState) => {
  switch (state.currentDashboard?.dashboard?.type) {
    case DashboardTypes.workspace:
      switch(state.currentDashboard?.dashboard?.protected) {
        case true:
          return Boolean(state.appEnvironment?.permissions?.workspaceCanEdit)
        case false:
          return Boolean(state.appEnvironment?.permissions?.workspaceCanExplore)
        default:
          return false
      }
    case DashboardTypes.personal:
      return Boolean(state.appEnvironment?.permissions?.workspaceCanEdit)
    default:
      return Boolean(state.appEnvironment?.permissions?.workspaceCanEdit)
  }
}
