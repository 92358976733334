export enum DataSourceObjectType {
  METRIC = "metric",
  DIMENSION = "dimension",
  VIEW = "view"
}

export enum ChartSource {
  FILTER = "filter",
  SLICER = "slicer",
  METRIC = "metric",
}

export enum ObjectType {
  CHART = 'CHART',
  EXPORT = 'EXPORT',
  DASHBOARD = 'DASHBOARD'
}

interface Usage {
  type: "Chart" | "Export" | "Dashboard"
  object: {
    type: ObjectType
    id: number
    title: string
  }
  source: ChartSource
}

export interface ExportUsage extends Usage {
  type: "Export"
}

export interface DashboardUsage extends Usage {
  type: "Dashboard"
  object: {
    type: ObjectType
    id: number
    title: string
    uri: string
  }
  menu: {
    title: string
  }
}

export interface ChartUsage extends Usage {
  type: "Chart"
  dashboard: {
    uri: string
    title: string
  }
  menu: {
    title: string
  }
}

export type UsageTypes = ExportUsage | DashboardUsage | ChartUsage

export interface Link {
  code: string,
  alias: string
}
