import API from './api'
import {ChartDtoDetailToAdd, RawChartTypes} from "types/charts"
import {LayoutWithId} from "redux/models/currentDashboard.types"
import {ChartWithMetricDefLayoutTypes} from "components/widgetContainer/WidgetContainer"
import {RawDashboardDtoTypes} from "types/dashboards"

export const updateDashboardLayout = (dashboardId: string | number | undefined, data: Pick<LayoutWithId, 'id' | 'x' | 'y' | 'w' | 'h'>[]) => API.instanceData.put<void, RawDashboardDtoTypes>(`/dashboard/${dashboardId}/layout`, data)
export const addChart = (dashboardId: number, data: ChartDtoDetailToAdd) => API.instanceData.post<void, RawChartTypes>(`/chart`, data, {
  params: {
    dashboardId,
  },
})
export const deleteChart = (chartId?: string | number) => API.instanceData.delete(`/chart/${chartId}`)
export const updateChartConf = (chartId: number, conf: ChartWithMetricDefLayoutTypes) => API.instanceData.put<void, RawChartTypes>(`/chart/${chartId}`, conf)

