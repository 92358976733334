import React, {useEffect} from 'react'
import {Loader} from "@biron-data/react-components"
import {useSelector} from "react-redux"
import {hasAuthToken} from "redux/appEnvironment.selector"
import {useParams} from "react-router-dom"

const openZendesk = (page: string) => {
  window.location.href = `https://biron.zendesk.com/hc/fr/${page}`
}

const Delegate = () => {
  const {target} = useParams()
  const hasAuthTokenSelector = useSelector(hasAuthToken)

  useEffect(() => {
    if (hasAuthTokenSelector) {
      if (target === 'kb') {
        openZendesk('')
      } else if (target === 'tickets') {
        openZendesk('requests')
      } else {
        window.location.href = '/'
      }
    }
  }, [target, hasAuthTokenSelector])

  return <Loader/>
}

export default Delegate;
