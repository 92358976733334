import React, {ReactElement, ReactNode, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState} from "react"
import styled from "styled-components"
import {Table, TableProps} from "antd"
import classNames from "classnames"
import {VariableSizeGrid as Grid} from 'react-window'
import {ColumnType} from "antd/lib/table"
import {isArray, omit} from "lodash"
import {OptionListPagination} from "@biron-data/react-bqconf"
import {useDebouncedCallback} from "use-debounce"
import {useResizeDetector} from "@biron-data/react-hooks"

export interface DatasourceTableRef {
  resetPagination: () => void
}

interface DatasourceTableProps<RecordType> {
  columns: (ColumnType<RecordType> & { style?: any })[]
  rowHeight?: number
  pagination?: OptionListPagination & { onPaginationChange: (newPagination: OptionListPagination) => void }
  includeDefaultSorter?: boolean
}

const DataSourceTable = <RecordType extends object = any, >({
                                                              rowHeight = 66,
                                                              pagination,
                                                              includeDefaultSorter = true,
                                                              ...props
                                                            }: Omit<TableProps<RecordType>, "pagination" | "dataIndex" | "scroll" | "columns"> & DatasourceTableProps<RecordType>, ref: React.ForwardedRef<DatasourceTableRef>) => {
  const [tableSize, setTableSize] = useState({
    width: 0,
    height: 0,
  })
  const gridRef = useRef<any>()
  const [currPage, setCurrPage] = useState(1)

  useImperativeHandle(ref, () => ({
    resetPagination: () => {
      setCurrPage(1)
    },
  }))

  const resetVirtualGrid = useCallback(() => {
    gridRef.current?.resetAfterIndices({
      rowIndex: 0,
      columnIndex: 0,
      shouldForceUpdate: true,
    })
  }, [])

  useEffect(() => resetVirtualGrid)

  const loadNextPage = useCallback(() => {
    setCurrPage(currPage + 1)
    pagination?.onPaginationChange({
      offset: (currPage * pagination.pageSize),
      pageSize: pagination.pageSize,
    })
  }, [currPage, pagination])

  const loadNextPageDebounced = useDebouncedCallback(loadNextPage, 150)

  const renderVirtualList = useCallback((rawData: readonly RecordType[], {scrollbarSize, onScroll}: {
    scrollbarSize: number;
    onScroll: (info: {
      currentTarget?: HTMLElement;
      scrollLeft?: number;
    }) => void;
  }) => {
    const totalHeight = rawData.length * 54
    return (
      // @ts-ignore
      props.columns && tableSize.width ? <Grid
        ref={gridRef}
        className="virtual-grid"
        columnCount={props.columns.length}
        columnWidth={(index: number) => {
          return totalHeight > Number(tableSize.height ?? 0) && index === props.columns!.length - 1
            ? (Number(props.columns![index].width)) - scrollbarSize - 1
            : (Number(props.columns![index].width))
        }}
        height={Number(tableSize.height) ?? 0}
        rowCount={rawData.length}
        rowHeight={() => rowHeight}
        onItemsRendered={(gridOnItemsRenderedProps) => {
          if (gridOnItemsRenderedProps.visibleRowStopIndex === rawData.length - 1) {
            loadNextPageDebounced()
          }
        }}
        onScroll={({scrollLeft}: { scrollLeft: number }) => {
          onScroll({scrollLeft})
        }}
        width={tableSize.width}
      >
        {({
            columnIndex,
            rowIndex,
            style,
          }: {
          columnIndex: number;
          rowIndex: number;
          style: React.CSSProperties;
        }) => {
          const col = (props.columns!)[columnIndex] as ColumnType<RecordType>
          const data = rawData[rowIndex] as any
          const value = isArray(col.dataIndex) ? data[col.dataIndex[0]]?.[col.dataIndex[1]] : data[col.dataIndex as string]
          return <div
            className={classNames('virtual-table-cell', {
              'virtual-table-cell-line-odd': rowIndex % 2 === 0,
              'virtual-table-cell-line-even': rowIndex % 2 === 1,
              // @ts-ignore
              'virtual-table-cell-last': columnIndex === props.columns.length - 1,
            })}
            style={{
              ...style,
              ...(props.columns!)[columnIndex].style,
              maxWidth: style.width,
              padding: 8,
            }}
          >
            <CellContentContainer
              $linecount={rowHeight < 40 ? 1 : 2}>{col.render ? col.render(value, data, columnIndex) as ReactNode : <>{value}</>}</CellContentContainer>
          </div>
        }}
      </Grid> : <></>
    )
  }, [loadNextPageDebounced, props.columns, rowHeight, tableSize.height, tableSize.width])

  const getPropertyValueByIndex = useCallback((object: { [key: string]: any }, indexs: (string | number)[]) => {
    let result = object
    indexs.forEach((index) => {
      result = result[index]
    })
    return result as unknown as (string | number)
  }, [])

  const consolidatedColumn = useMemo(() => includeDefaultSorter && props.columns ? props.columns.map((column: ColumnType<RecordType>) => ({
    ...column,
    sorter: {
      compare: (a, b) => {
        if (!column.dataIndex) {
          return 0
        }
        const testedProperty: (string | number)[] = isArray(column.dataIndex) ? column.dataIndex : [column.dataIndex]
        const firstValueToCompare = getPropertyValueByIndex(a, testedProperty)
        const secondValueToCompare = getPropertyValueByIndex(b, testedProperty)
        return String(firstValueToCompare).localeCompare(String(secondValueToCompare))
      },
    },
  }) as ColumnType<RecordType>) : props.columns, [includeDefaultSorter, props.columns, getPropertyValueByIndex])

  const containerRef = useRef<any>()

  useResizeDetector(containerRef, tableSize, (width, height) => {
    if (tableSize.width !== width || tableSize.height !== height - 37) {
      setTableSize({width, height: height - 37})
    }
  })

  return <div style={{minHeight: 0, height: "100%"}} ref={containerRef}><StyledTable
    {...omit(props, 'ref', "pagination", "columns")}
    tableLayout="fixed"
    scroll={{y: tableSize.height, x: 0}}
    pagination={false}
    columns={consolidatedColumn}
    components={{
      body: renderVirtualList,
    }}
  />
  </div>
}

export default React.forwardRef(DataSourceTable) as <RecordType extends object = any, >(props: Omit<TableProps<RecordType>, "pagination" | "dataIndex"> & DatasourceTableProps<RecordType>, ref: React.ForwardedRef<DatasourceTableRef>) => ReactElement

const CellContentContainer = styled.div<{ $linecount: number }>`
  display: -webkit-box;
  max-width: calc(100%);
  min-width: calc(90%);
    -webkit-line-clamp: ${props => props.$linecount};
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;

  p {
    display: -webkit-box;
    max-width: calc(100%);
      -webkit-line-clamp: ${props => props.$linecount};
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

const StyledTable = styled(Table)`

  &&& {
    .ant-table-thead > tr > th {
      border-radius: 0;
      border: none;
    }

    thead {
      background-color: var(--main-background-color) !important;
    }

    .ant-table-thead > tr > th {
      background-color: transparent;
    }

    .ant-table-cell-scrollbar {
      box-shadow: none;
    }
    th, td {
      padding: 8px;
      height: 100%;
    }

    .virtual-table-cell {
      overflow: hidden;
      word-break: break-word;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
    }

    .virtual-table-cell-line {
      &-odd {
        background-color: var(--row-odd);
      }

      &-even {
        background-color: var(--row-even);
      }
    }
  }

` as unknown as typeof Table
