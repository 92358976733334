import React, {forwardRef, FunctionComponent, PropsWithChildren} from 'react'
import {WrapperItem, WrapperRoot} from "@biron-data/react-components"
import {FilterIcon} from "@heroicons/react/outline"
import {
  DimensionFilterSelector,
  FilterSelectorProps,
} from "@biron-data/react-bqconf"
import {RuleObject} from "antd/lib/form"
import {RuleRender} from "rc-field-form/lib/interface"

interface WrapperProps extends PropsWithChildren {
  name: string
  rules?: (RuleObject | RuleRender)[]
  itemColumnWidth?: number
}

export const DimensionFiltersWrapper: FunctionComponent<WrapperProps> = ({children, itemColumnWidth, name, rules}) => (
  <WrapperRoot {...{
    itemColumnWidth,
    icon: FilterIcon,
    label: "configuration-label-filters",
    description: "configuration-description-filters",
  }}>
    <WrapperItem name={name} rules={rules}>
      {children}
    </WrapperItem>
  </WrapperRoot>
)

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const DimensionFiltersField = forwardRef<any, FilterSelectorProps>(function Field(props, ref) {
  return <DimensionFilterSelector {...props} />
})
