import React, {memo, useCallback, useEffect, useMemo, useRef, useState} from "react"
import {useSelector} from "react-redux"
import {getCurrentEnvironmentId} from "redux/environment.selector"
import DatasourceTable, {DatasourceTableRef} from "components/dataSourceDoc/DataSource.Table"
import {TableColumnProps} from "antd"
import Language from "language"
import {Search} from "components/search/Search"
import {useTextWithHighLight} from "components/dataSourceDoc/DataSource.hooks"
import {OptionListPagination} from "@biron-data/react-bqconf"
import {ConsolidatedEntryValue, loadDictionaryEntries} from "services/MetaModelService"
import {SizeReference, TableContainer} from "components/dataSourceDoc/DataSource.style"
import {useResizeDetector} from "@biron-data/react-hooks"

interface ValuesProps {
  dimensionCode: string
}

// eslint-disable-next-line react/display-name
const DetailsValues = memo<ValuesProps>(({dimensionCode}) => {
  const [options, setOptions] = useState<ConsolidatedEntryValue[]>([])
  const [size, setSize] = useState({
    width: 0,
  })
  const [search, setSearch] = useState("")
  const [loading, setLoading] = useState(false)
  const environmentId: number = useSelector(getCurrentEnvironmentId) as number
  const tableRef = useRef<DatasourceTableRef>(null)

  const query: (dictionaryCode: string, search?: string, pagination?: OptionListPagination) => Promise<ConsolidatedEntryValue[]> = useCallback((dictionaryCode: string, newSearch?: string, pagination?: OptionListPagination) => loadDictionaryEntries(environmentId, dictionaryCode, newSearch, pagination), [environmentId])

  const getOption = useCallback(() => {
    if (dimensionCode) {
      setLoading(true)
      query(dimensionCode, undefined, {
        offset: 0,
        pageSize: 100,
      })
        .then(newOptions => {
          setOptions(newOptions)
          setLoading(false)
        }).catch(() => setLoading(false))
    }
  }, [dimensionCode, query])

  const onPaginationChange = useCallback((pagination: OptionListPagination) => {
    if (dimensionCode) {
      query(dimensionCode, search, pagination)
        .then(newOptions => {
            setOptions(old => ([...old, ...newOptions]))
            setLoading(false)
          },
        ).catch(() => setLoading(false))
    }
  }, [dimensionCode, query, search])

  useEffect(() => {
    getOption()
  }, [dimensionCode, getOption, query])

  const getTextWithHighLight = useTextWithHighLight(search)

  const usageColumns: TableColumnProps<ConsolidatedEntryValue>[] = useMemo(() => [
    {
      title: Language.get(`dataDoc.columns.value`),
      dataIndex: 'label',
      render: (text: string) => getTextWithHighLight(text),
      width: size.width,
    },
  ], [getTextWithHighLight, size.width])

  const container = useRef<any>()
  useResizeDetector(container, size, (newWidth) => {
    setSize({
      width: newWidth,
    })
  })
  const onSearch = (newSearch: string) => {
    setSearch(newSearch)
    if (dimensionCode) {
      query(dimensionCode, newSearch, {
        offset: 0,
        pageSize: 20,
      })
        .then(newOptions => {
            setOptions(newOptions)
            setLoading(false)
          tableRef.current?.resetPagination()
          },
        ).catch(() => setLoading(false))
    }
  }

  return <SizeReference ref={container}>
    {size.width && <TableContainer>
      <Search
        width={45}
        onChange={onSearch}/>
      {/* @ts-ignore */}
      <DatasourceTable ref={tableRef}
                       loading={loading}
                       columns={usageColumns}
                       dataSource={options}
                       rowHeight={34}
                       pagination={{
        offset: 0,
        pageSize: 100,
        onPaginationChange: (pagination: OptionListPagination) => {
          onPaginationChange(pagination)
        },
                       }}
                       includeDefaultSorter={false}/>
    </TableContainer>
    }
  </SizeReference>
})

export default DetailsValues