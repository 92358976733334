import React, {forwardRef, useCallback} from 'react'
import styled from "styled-components"
import TextArea from "antd/lib/input/TextArea"
import Language from "language"

interface DescriptionSelectorProps {
  onChange?: (newValue: string) => void
  value?: string
}

const DescriptionSelector = forwardRef<any, DescriptionSelectorProps>(function DescriptionSelector({value, onChange}, ref) {
  const handleChange = useCallback((event: any) => {
    onChange?.(event.target.value)
  }, [onChange])

  return <StyledTextArea ref={ref} value={value} placeholder={Language.get("enter-description")} onChange={handleChange} autoSize={{
    minRows: 14,
    maxRows: 14,
  }}/>
})

export default DescriptionSelector

const StyledTextArea = styled(TextArea)`
  height: 100%;
  border: none;
  border-bottom-right-radius: var(--big-border-radius);
  border-bottom-left-radius: var(--big-border-radius);
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  
  :focus {
    border: none;
    box-shadow: none;
  }
`