import Workflow from 'classes/workflows/main-workflows/Workflow'
import {WorkflowResultTypes} from "classes/workflows/query-workflows/QueryWorkflow"
import {State} from "classes/workflows/main-workflows/FormWorkflow"

export interface DimensionGlobal {
  width: number
  height: number
}

export interface Dimensions {
  global: DimensionGlobal | null,
  chartDimensions?: {
    width: number
    height: number
  },
  chart?: {
    width: number
    height: number
  },
  enforcedLegend?: {
    height: number
    width: number
  },
  svgLegend?: {
    height: number
    width: number
  },
}

export default class ChartWorkflow extends Workflow {
  widgetId?: number
  // @ts-ignore
  dimensions: Dimensions
  // @ts-ignore
  data: WorkflowResultTypes

  constructor(widgetId: string, global: DimensionGlobal, data: WorkflowResultTypes, setState: (newState: State) => void) {
    super(widgetId, setState)
  }

  update(data: WorkflowResultTypes, key: string, dimension: DimensionGlobal) {
    let animation = 'none',
      reDraw = false
    // @ts-ignore
    const currentDimensions = this.dimensions[key] || {width: 0, height: 0}

    if (currentDimensions.height !== dimension.height || currentDimensions.width !== dimension.width) {
      // @ts-ignore
      this.dimensions[key] = JSON.parse(JSON.stringify(dimension))
      reDraw = true
    }

    if (this.data !== data) {
      this.data = data
      animation = 'reset'
      reDraw = true
    }
    if (reDraw) {
      // @ts-ignore children are implementing this
      this.draw(this.data, this.dimensions, animation);
    }
  }

  unsubscribe() {
    super.unsubscribe();
  }
}
