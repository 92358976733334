import React from 'react'
import App from './App'
// import 'antd/dist/main.css'
import '@biron-data/biron-antd/dist/standard.scss'
import {createRoot} from "react-dom/client"


const container = document.getElementById('app')
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!)
root.render(<App/>)
