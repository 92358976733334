import React, {memo, useCallback, useMemo} from 'react'
import {Radio} from "antd"
import {RadioChangeEvent} from "antd/lib/radio/interface"
import styled from "styled-components"
import {ColoredElement} from "@biron-data/react-components"
import {ColorName, strToColor} from "@biron-data/react-bqconf"

interface ColorSelectorProps {
  onChange?: (newValue: ColorName) => void
  value?: ColorName
}

const ColorSelector = memo<ColorSelectorProps>(function ColorSelector({value, onChange}) {
  const options = useMemo(() => {
    return Array.from(strToColor.entries()).map(([key, color]) => <RadioButton key={key} value={key} checked={value === key}>
      <ColoredElement $color={color}/>
    </RadioButton>)
  }, [value])

  const handleChange = useCallback((event: RadioChangeEvent) => {
    onChange?.(event.target.value)
  }, [onChange])

  return <RadioGroup onChange={handleChange} value={value}>
    {options}
  </RadioGroup>
})

export default ColorSelector

const RadioGroup = styled(Radio.Group)`
  // Override ant-radio-group in order to properly align content
  &&& {
    display: flex;
  }
  .ant-radio-button-wrapper {
    padding: 4px;
    border-radius: 4px;
  }

  &&& .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-child {
    border: 1px solid #425FF0;
  }

  gap: 8px;
  width: 100%;
  border: none;
  line-height: 36px;
`

const RadioButton = styled(Radio.Button)`
  display: flex;
  border: 1px solid var(--border-color-input);
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  line-height: var(--line-height);
  ${({disabled}) => disabled ? 'filter: grayscale(100%) opacity(20%);' : ''}
  height: 42px;
  width: 42px;
  
  &&& .ant-radio-button-wrapper-checked {
    box-shadow: 0 0 0 3px rgba(66, 95, 240, 0.12);
  }
  span {
    width: 100%;
    height: 100%;
  }
  display: flex;
`