import React, {FunctionComponent} from 'react'
import styled from "styled-components"

interface Props {
  asc?: boolean
}

const TableChartSorter: FunctionComponent<Props> = ({asc}) => {
  return <AntTableColumnSorter>
    <UpContainer>
      <svg width="6" height="4" viewBox="0 0 6 4" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3 0L0 4H6L3 0Z" fill={asc === true ? "var(--primary)" : "#BEC5CC"}/>
      </svg>
    </UpContainer>
    <DownContainer>
      <svg width="6" height="4" viewBox="0 0 6 4" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3 4L0 0H6L3 4Z" fill={asc === false ? "var(--primary)" : "#BEC5CC"}/>
      </svg>
    </DownContainer>
  </AntTableColumnSorter>
}
export default TableChartSorter

const AntTableColumnSorter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  font-size: 10px;
`

const UpContainer = styled.div`
  display: flex;
  align-items: center;
  `


const DownContainer = styled.div`
  display: flex;
  align-items: center;
`