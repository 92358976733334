import axios from 'axios'
import React, {FunctionComponent, PropsWithChildren, useCallback, useEffect, useState} from "react"
import {notification} from 'antd'
import Language from "./language"

const CacheBuster: FunctionComponent<PropsWithChildren> = ({children}) => {
  const loadedVersion = import.meta.env.VITE_VERSION// eslint-disable-line no-undef
  const [availableVersion, setAvailableVersion] = useState(loadedVersion)


  useEffect(() => {
    setInterval(checkAvailableVersion, 120000, setAvailableVersion)
  }, [setAvailableVersion])

  const reloadCallback = useCallback(() => window.location.reload(), [])

  useEffect(() => {
    if (availableVersion !== loadedVersion) {
      notification.info({
        message: Language.get('navigation-newVersion-title'),
        description: Language.get('navigation-newVersion-description'),
        placement: 'bottomRight',
        duration: 0,
        onClick: reloadCallback,
        onClose: reloadCallback,
      })
    }
  }, [availableVersion, loadedVersion, reloadCallback])

  return <React.Fragment>
    {children}
  </React.Fragment>
}

export default CacheBuster

const checkAvailableVersion = (availableVersionCallback: (version: string) => void) => {
  axios.get('/meta.json')
    .then(({data}) => availableVersionCallback(data.version))
    .catch(() => {
    })
}
