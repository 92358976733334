import API from './api'
import Target from "components/charts/target/TargetChart"

export const updateTargets = ({
                                environmentId,
                                viewCode,
                                metricCode,
                                targets = [],
                              }: {
  environmentId: number,
  viewCode: string,
  metricCode: string,
  targets: Target[]
}) => API.instanceData.put(`/target/byMetric/${environmentId}/${viewCode}/${metricCode}`, targets)
export const getTargets = ({
                             environmentId,
                             viewCode,
                             metricCode,
                           }: {
  environmentId: number,
  viewCode: string,
  metricCode: string
}) => API.instanceData.get(`/target/byMetric/${environmentId}/${viewCode}/${metricCode}`)
