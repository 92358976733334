import React, {memo} from 'react'
import {FormItemKeys} from 'commons/keywords/form'
import {Loader} from "@biron-data/react-components"
import PositionDashboard from '.././confItems/PositionDashboard'
import ParentMenu from '.././confItems/ParentMenu'
import {FormBodyComponentProps} from "components/forms/confBodies/FormBody"

const NavCloneDashboardFormBody = <T, >({
                                          loading,
                                          getPopupContainer,
                                          availables: {
                                            menus = [],
                                            dashboardsPositions = [],
                                          },
                                        }: FormBodyComponentProps<T>) => {
  return <Loader loading={loading}>
    <ParentMenu
      span={24}
      name={FormItemKeys.PARENT_MENU}
      options={menus}
      {...{
        getPopupContainer,
      }}/>
    <PositionDashboard
      name={FormItemKeys.POSITION_DASHBOARD}
      options={dashboardsPositions}
      span={24}
      {...{
        getPopupContainer,
      }}/>
  </Loader>
}

export default memo(NavCloneDashboardFormBody) as typeof NavCloneDashboardFormBody
