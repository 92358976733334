import {useCallback} from "react"
import {omit} from "lodash"
import Language from "language"
import {UseHandleChartAddType} from "hooks/useHandleAddChart"
import {captureEvent} from "services/GoogleAnalyticsService"
import {ChartDtoDetail} from "types/charts"

export const useHandleAddChartFromClipboard = (
  copiedChart: ChartDtoDetail | undefined,
  handleChartAdd: ReturnType<UseHandleChartAddType>,
) => {
  return useCallback(
    (buttonPosition?: ({
      x: number,
      y: number
    } | undefined)) => {
      if (copiedChart) {
        captureEvent({
          category: 'charts',
          action: 'copy_chart',
          widgetType: copiedChart?.type,
        })
        return handleChartAdd({
          ...omit(copiedChart, ['x', 'y']),
          title: Language.get(`dashboard-addFromClipboard-newTitle`, copiedChart),
        }, buttonPosition)
      } else {
        return null
      }
    },
    [copiedChart, handleChartAdd])
}
