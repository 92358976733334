import React, {memo} from "react"
import {isEmpty, isEqual, pick} from 'lodash'
import {textFormat} from "commons/format/formatter"
import {parseMetricAsAlt} from './genericDataSetUtil'
import {
  ChartSelection,
  ChartTableQueryContext,
  LoadedCache,
  TableQueryWorkflowResult,
  TableWorkflowFunction,
} from "classes/workflows/query-workflows/QueryWorkflow"
import {ChartGenericDetailWithoutLayout} from "components/widgetContainer/WidgetContainer"
import {GenericEffectiveConf} from "components/charts/line/LineChart.types"
import {QueryResponseDataWithCategory} from "services/QueryService"
import {ChartQueryParams} from "commons/parsers/queries"
import {extractSlicersDimension, isSlicerDimension, TooltipContentDimension, hasMultipleViews, parseFormat, DataSelection, GenericChartTypes, OrderBy, MetricDetails, ConsolidatedDimension} from "@biron-data/react-bqconf"
import {BoxColumn, TableColumn} from "types/charts"
import {useDataDocDimensionLink} from "components/dataSourceDoc/DataSource.hooks"

export const determinePaginationAndCache = (
  couldKeepPrevData: boolean,
  pageSize: number,
  chartQueryParams: Pick<ChartQueryParams, "pageOffset">,
  prevChartData: Pick<TableQueryWorkflowResult, "lineCount"> | undefined,
  prevLoadedCache: Pick<LoadedCache, "pageOffset" | "data"> | undefined): [boolean, number, number] => {
  const fromPrevDataStartIndex = ((chartQueryParams.pageOffset ?? 0) - (prevLoadedCache?.pageOffset ?? 0))

  const fromPrevDataStopIndex = prevChartData ? Math.min(fromPrevDataStartIndex + pageSize, prevChartData.lineCount - (prevLoadedCache?.pageOffset ?? 0)) : 0
  const keepPrevData = couldKeepPrevData
    && prevLoadedCache
    && fromPrevDataStartIndex >= 0
    && ((fromPrevDataStopIndex > fromPrevDataStartIndex) || (fromPrevDataStopIndex === 0 && fromPrevDataStartIndex === 0))
    && fromPrevDataStopIndex <= prevLoadedCache.data.length

  return [Boolean(keepPrevData), fromPrevDataStartIndex, fromPrevDataStopIndex]
}

export const tablesQueryWorkflow: TableWorkflowFunction = (qc: ChartTableQueryContext) => {
  const {dashboardSelection, chart, chartSelectionRaw, prevChartData} = qc
  const prevChartSelection = prevChartData?.chartSelection
  const prevLoadedCache = prevChartData?.loadedCache
  const chartSelection = computeChartSelection(dashboardSelection, chart, chartSelectionRaw, prevChartSelection)
  const withTotals = Boolean(qc.chart.extraConf.displayType === GenericChartTypes.TABLES && (
    chartSelection.withDateSlicer
    || extractSlicersDimension(chart.slicers).length > 0
    || (dashboardSelection.slicers && extractSlicersDimension(dashboardSelection?.slicers).length > 0)
  ))
  const {pagination: {pageSize}} = chartSelection
  const chartQueryParams: ChartQueryParams = {
    ...qc.getChartQueryParams(chartSelection),
    withTotalRowCount: true,
    withTotals,
  }
  const couldKeepPrevData = Boolean(prevChartData
    && isEqual(dashboardSelection, prevLoadedCache?.dashboardSelection)
    && isEqual({
      ...pick(chartSelection, 'search', 'withDateSlicer', 'withChartOverriddenPeriod'),
      sorters: chartSelection.sorters,
    }, {
      ...pick(prevChartSelection, 'search', 'withDateSlicer', 'withChartOverriddenPeriod'),
      sorters: prevChartSelection?.sorters,
    }))

  const [keepPrevData, fromPrevDataStartIndex, fromPrevDataStopIndex] = determinePaginationAndCache(couldKeepPrevData, pageSize, chartQueryParams, prevChartData, prevLoadedCache)

  if (keepPrevData && prevChartData && prevLoadedCache) {
    return Promise.resolve<any>({
        ...prevChartData,
        chartSelection,
        data: computeData(chart, pageSize, fromPrevDataStartIndex, fromPrevDataStopIndex, chartSelection, prevChartData, prevLoadedCache),
      },
    )
  } else {
    return qc.prepareQuery<QueryResponseDataWithCategory>(qc.query(chartQueryParams)).then((
      {data: dataWithLineCount, effectiveConf},
    ) => {
      if (!dataWithLineCount) {
        throw new Error(`dataWithLineCount is ${typeof dataWithLineCount}`)
      } else if (dataWithLineCount.length === 0) {
        throw new Error(`dataWithLineCount is empty`)
      }
      const lineCount = dataWithLineCount.slice(-1)[0][0] as (number | undefined)
      const totalData = withTotals ? dataWithLineCount.slice(-2, -1)[0] : undefined
      const loadedCache: LoadedCache = {
        dashboardSelection,
        pageOffset: chartQueryParams.pageOffset,
        data: dataWithLineCount.slice(0, withTotals ? -2 : -1),
      }

      const data = pageSize >= 0 ? loadedCache.data.slice(0, Math.min(pageSize, chart.extraConf.limits?.[0]?.limitSeries || 9999)) : undefined
      const columns = parseColumns(qc.chart.extraConf.displayType, effectiveConf as GenericEffectiveConf)
      const lineCountComputed = chart.extraConf?.limits?.[0]?.limitSeries ? Math.min(chart.extraConf?.limits?.[0].limitSeries, Number(lineCount)) : lineCount
      return {
        type: "table",
        meta: {
          effectiveConf,
          extraConf: chart.extraConf,
        },
        columns,
        chartSelection,
        loadedCache,
        data,
        totalData,
        lineCount: lineCountComputed,
      }
    })
  }
}

export const computeData = (chart: ChartGenericDetailWithoutLayout, pageSize: number, fromPrevDataStartIndex: number, fromPrevDataStopIndex: number | false | undefined, chartSelection: any, prevChartData: TableQueryWorkflowResult, prevLoadedCache: LoadedCache) => {
  const computed = Math.min(fromPrevDataStopIndex || 9999, chart.extraConf.limits?.[0]?.limitSeries || 9999)
  return prevLoadedCache.data.slice(fromPrevDataStartIndex, computed)
}

export function computeChartSelection(dashboardSelection: DataSelection, chart: ChartGenericDetailWithoutLayout, chartSelectionRaw: ChartSelection, prevChartSelection?: ChartSelection) {
  const {pagination = {pageSize: undefined, current: undefined}, search = '', ...otherSelection} = chartSelectionRaw
  const {pageSize = 10, current = 1} = pagination
  const sorters = computeChartSelectionSorter(chart, chartSelectionRaw, prevChartSelection)
  return {
    ...otherSelection,
    pagination: {
      pageSize,
      current,
    },
    sorters: sorters ?? [],
    search,
  }
}

export function computeChartSelectionSorter(
  chart: Pick<ChartGenericDetailWithoutLayout, "orderBys" | "slicers" | "metrics">,
  chartSelectionRaw: Pick<ChartSelection, "sorters" | "withDateSlicer">,
  prevChartSelection?: Pick<ChartSelection, "withDateSlicer">): OrderBy[] | undefined {
  const {sorters: newSorters, withDateSlicer} = chartSelectionRaw
  const previousWithDateSlicer = prevChartSelection?.withDateSlicer

  const columnOfDateSlicer = chart.slicers.findIndex(slicer => slicer.type === "date")
  const consolidatedColumnOfDateSlicer = columnOfDateSlicer === -1 ? 0 : columnOfDateSlicer

  if (withDateSlicer === undefined || previousWithDateSlicer === undefined || withDateSlicer === previousWithDateSlicer) {
    return newSorters
  } else {
    if (withDateSlicer && newSorters) {
      return [
        {
          column: consolidatedColumnOfDateSlicer,
          asc: false,
        },
        ...newSorters.filter(sort => sort.column !== consolidatedColumnOfDateSlicer).map(sort => sort.column > consolidatedColumnOfDateSlicer ? ({
          ...sort,
          column: sort.column + 1,
        }) : sort),
      ]
    } else {
      const consolidatedSorts = newSorters?.filter(sort => sort.column !== consolidatedColumnOfDateSlicer).map(sort => sort.column > consolidatedColumnOfDateSlicer ? ({
        ...sort,
        column: sort.column - 1,
      }) : sort)
      return isEmpty(consolidatedSorts) ? [{
        asc: false,
        column: chart.slicers.filter(isSlicerDimension).length > 0 ? chart.metrics.length : 0,
      }] : consolidatedSorts
    }
  }
}

const TooltipContentDimensionWithLink = memo<{dimension: ConsolidatedDimension}>(function
  TooltipContentDimensionWithLink({
                                                        dimension,
                                                      }) {
  const getDatadocLink = useDataDocDimensionLink()

  return <TooltipContentDimension dimension={dimension} getMoreInformationLink={getDatadocLink}/>
})

function parseColumns(chartType: GenericChartTypes, effectiveConf: GenericEffectiveConf) {
  const columns: (TableColumn | BoxColumn)[] = []

  effectiveConf.slicers.forEach(slicer => {
    switch (slicer.type) {
      case "dimension":
        if (slicer?.dimension) {
          columns.push({
            title: slicer.dimension.alias,
            titleAlt: <TooltipContentDimensionWithLink dimension={slicer.dimension}/>,
            key: columns.length,
            dataIndex: columns.length,
            nature: 'dimension',
            code: slicer.dimensionCode,
            format: textFormat,
            hidden: false,
          })
        }
        break
      case "date":
        columns.push({
          title: 'Date',
          key: columns.length,
          dataIndex: columns.length,
          nature: 'date',
          code: '',
          format: textFormat,
          hidden: false,
        })
        break
      default:
    }
  })
  const isMultiView = hasMultipleViews(effectiveConf.metrics)
  effectiveConf.metrics.forEach(metric => {
    columns.push({
      title: metric.metricAlias,
      details: <MetricDetails metric={metric}/>,
      titleAlt: parseMetricAsAlt(metric, effectiveConf),
      titleView: isMultiView ? metric.view.alias : undefined,
      key: columns.length,
      dataIndex: columns.length,
      nature: 'metric',
      format: parseFormat(metric),
      code: metric.metricCode,
      hidden: false,
    })
  })
  return columns
}