import React from 'react'
import {Tooltip} from 'antd'
import ChartSummary from "components/charts/Chart.Summary"
import styled from 'styled-components'
import {BoxQueryWorkflowResult} from "classes/workflows/query-workflows/QueryWorkflow"
import {ShapeDimension} from "types/charts"
import BoxChartData from "components/charts/box/BoxChart.Data"
import {MetricDtoDetail} from "@biron-data/react-bqconf"
import {FormattedText} from "@biron-data/react-components"

interface Props {
  chartData: BoxQueryWorkflowResult
  withSummary: boolean
  dimensions: ShapeDimension
  metrics: MetricDtoDetail[],
}

class BoxChart extends React.Component<Props, any> {
  boxesWidth() {
    const {metrics = []} = this.props
    const {width} = this.props.dimensions
    const maxBoxesPerLines = Math.floor(width / 180) // 180 here is the minimal width of a box (170 + 2x5 margin)
    const noMetric = metrics.length
    if (noMetric <= maxBoxesPerLines && noMetric > 0) {
      // if the minimal size of 180px is enough to put all boxes in the line, we process the maximal width
      return (width / noMetric) - 14 // column gap
    }

    return (width / maxBoxesPerLines) - 14 // column gap
  }

  render() {
    const {chartData, dimensions: {width, height}, withSummary}: Props = this.props
    const {data, columns, meta} = chartData
    // sometimes (when resizing) width or height return NaN
    return (data && data[0] && width && height)
      ? <BoxChartContent style={{
        width,
        height,
      }}>
        {withSummary && <ChartSummary effectiveConf={meta.effectiveConf} showMetricSlicer={false}/>}
        <BoxLine>
          {data[0].map((d, index) => {
            const {title, titleAlt = title, details, format} = columns[index]
            return <BoxContent key={`${index}`} $boxwidth={this.boxesWidth()}>
              <StyledBoxLabelContainer $titlelength={title.length}>
                <Tooltip placement="top" title={titleAlt} mouseEnterDelay={1} overlayStyle={{minWidth: 400}}>
                  <FlexDiv>
                    <FormattedText text={title}>
                      {details}
                    </FormattedText>
                  </FlexDiv>
                </Tooltip>
                <BoxChartData format={format} value={d}/>
              </StyledBoxLabelContainer>
            </BoxContent>
          })}
        </BoxLine>
      </BoxChartContent>
      : null
  }
}

export default BoxChart

const FlexDiv = styled.div`
    display: flex;
    gap: 5px;
`

const BoxChartContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  
  @media (max-width: 767px) {
    width:              100%;
    -webkit-flex-shrink: 0;
    flex-shrink:         0;
  }
`

const BoxLine = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  overflow-y: auto;
  gap: 10px 14px;
  
  @media (max-width: 767px) {
    -webkit-flex-wrap: wrap;
    flex-wrap:         wrap;
    -webkit-justify-content: center;
    justify-content: center;
  }

    &:after {
    content: "";
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
    pointer-events: none;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 90%);
    width: 100%;
    height: 4em;
  }
`

const StyledBoxLabelContainer = styled.div<{ $titlelength: number }>`
  display: flex;
  flex-direction: column;
  align-item: start;
  color: var(--medium-grey);
  justify-content: center;
  height: 100%;
    font-size: ${props => props.$titlelength > 40 ? "15px" : "inherit"}px;
`

const BoxContent = styled.div<{ $boxwidth: number }>`
  ${({$boxwidth}) => `
    width: ${$boxwidth}px;
    flex-basis: ${$boxwidth}px;
  `}
  -webkit-order:       0;
  order:               0;
  -webkit-flex-grow: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  flex-shrink:         0;
  -webkit-align-self:  auto;
  align-self:          auto;
  margin: 0px 0px;
  display: flex;
  justify-content: start;
  
  @media (max-width: 767px) {
    width:              100%;
    -webkit-flex-shrink: 0;
    flex-shrink:         0;
    justify-content: center;
  }
`
