import {DashboardFormats, DashboardTypes, PersonalDashboardDto, RawDashboardDtoTypes, WorkspaceDashboardDto} from "types/dashboards"
import {normalize, NormalizedSchema, schema} from "normalizr"
import {chartDetail} from "schemas/chart"
import {ChartDtoDetailTypes} from "types/charts"

export interface NormalizedWorkspaceDashboard extends Omit<WorkspaceDashboardDto, "charts"> {
  type: DashboardTypes.workspace
  charts: number[]
}

export interface NormalizedPersonalDashboard extends Omit<PersonalDashboardDto, "charts"> {
  type: DashboardTypes.personal
  charts: number[]
}

export interface NormalizedSummarizedPersonalDashboard extends Omit<NormalizedPersonalDashboard, "period" | "filters" | "charts"> {
  format: DashboardFormats.summarized
}

export interface NormalizedDetailedPersonalDashboard extends NormalizedPersonalDashboard {
  format: DashboardFormats.detailed
}

export type NormalizedPersonalDashboardTypes = NormalizedSummarizedPersonalDashboard | NormalizedDetailedPersonalDashboard

export interface WorkspaceDashboardWithoutCharts extends Omit<WorkspaceDashboardDto, "charts"> {
  type: DashboardTypes.workspace
}

export interface PersonalDashboardWithoutCharts extends Omit<PersonalDashboardDto, "charts"> {
  type: DashboardTypes.personal
}

export type NormalizedDashboardTypes = NormalizedWorkspaceDashboard | NormalizedPersonalDashboard
export type DashboardWithoutChartsTypes = WorkspaceDashboardWithoutCharts | PersonalDashboardWithoutCharts

export const dashboardDetail = new schema.Entity(
  'dashboards',
  {
    charts: [chartDetail],
  },
  {
    processStrategy: dashboard => ({
      ...dashboard,
      uri: `${dashboard.relativeId}-${dashboard.title.replace(/\W/g, '-').replace(/-{2,}/g, '-')}`,
    }),
  },
)

export interface NormalizedDashboardModels {
  dashboards: Record<string, NormalizedDashboardTypes>
  charts: Record<string, ChartDtoDetailTypes>
}

export const normalizeDashboardDetail: (data: RawDashboardDtoTypes) => NormalizedSchema<NormalizedDashboardModels, number> = (data) => normalize(data, dashboardDetail)
export const normalizeDashboardDetails = (data: RawDashboardDtoTypes[]) => {
  return {
    entities: {
      charts: data.map(d => normalize(d, dashboardDetail)).reduce((acc, curr) => {
        return {
          ...acc,
          ...curr.entities.charts,
        }
      }, {}),
      dashboards: data.map(d => normalize(d, dashboardDetail)).reduce((acc, curr) => {
        return {
          ...acc,
          ...curr.entities.dashboards,
        }
      }, {}),
    },
    result: 0,
  }

}
