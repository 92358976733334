import {init, RematchDispatch, RematchRootState} from '@rematch/core'
import RootModel, {models} from "redux/models"

const additionalReduxConf: Record<string, any> = {
  middlewares: [] // To add redux middlewares such as redux-logger...
}

const store = init({
  models,
  redux: {...additionalReduxConf}
})

export type Store = typeof store;
export type Dispatch = RematchDispatch<RootModel>;
export type RootState = RematchRootState<RootModel>;

export default store
