import React, {forwardRef, useCallback} from "react"
import {Col, Form, Row} from 'antd'
import {useFields} from "@biron-data/react-hooks"
import {ConsolidatedFormProps} from "components/forms/Form.Modal"
import {SimplifiedChartTargetFormProps} from "components/forms/chart/types"
import styled from "styled-components"
import {useChartTypes} from "components/forms/chart/useChartTypes"
import {ViewWrapper} from ".././confItems/View"
import {ViewField} from "components/forms/confItems/View"
import {MetricsField, MetricsWrapper} from "components/forms/confItems/Metrics"
import {TargetsField, TargetsWrapper} from "components/forms/confItems/Targets"
import {ChartTypeField, ChartTypeWrapper} from "components/forms/confItems/ChartType"
import {PeriodWrapper} from "components/forms/confItems/Period"
import {PeriodSelector} from "@biron-data/react-bqconf"
import {useSelector} from "react-redux"
import {getCopiedMetric} from "redux/clipboard.selector"
import {useDataDocMetricLink} from "components/dataSourceDoc/DataSource.hooks"
import {useLoadPeriods} from "hooks/useLoadPeriods"
import {TargetExtendedConfModel} from "components/forms/chart/FormChartTargetCmp"


type Props = SimplifiedChartTargetFormProps & ConsolidatedFormProps<TargetExtendedConfModel>


// eslint-disable-next-line react/display-name
const FormComponentTarget = forwardRef<any, Props>(({
                                                    onValuesChange,
                                                    uniqueViewOptions,
                                                    data,
                                                    errors,
                                                    viewsWithMetrics,
                                                    unavailableViews,
                                                    additionalDetails,
                                                    getPopupContainer,
                                                    metaModel,
                                                    dashboardSelection,
                                                    environmentId,
                                                    loadDictionaryEntries
                                                  }, ref) => {
    const fields = useFields(data, errors)

  const chartTypes = useChartTypes(data.type)

  const handleValuesChange = useCallback((value: { [key: string]: any }, values: Partial<TargetExtendedConfModel>) => {
    const modifiedProperty = Object.keys(value)[0]
    if (["uniqueView", "metrics"].includes(modifiedProperty)) {
      onValuesChange(values)
    }
  }, [onValuesChange])

  const copiedMetric = useSelector(getCopiedMetric())
  const getDatadocLink = useDataDocMetricLink()

  const loadPeriods = useLoadPeriods()

  return <Form ref={ref} name={'editChartTarget'} fields={fields} onValuesChange={handleValuesChange}>
    <Row>
      <Col span={14}>
        <ViewWrapper name={'uniqueView'}>
          <ViewField {...{
            name: 'uniqueView',
            options: uniqueViewOptions,
            getPopupContainer,
          }}/>
        </ViewWrapper>
        <MetricsWrapper name={'metrics'} rules={[{required: true, message: "Required field"}]}>
          <MetricsField {...{
            environmentId,
            name: 'metrics',
            groupMetricsByView: !data.uniqueView,
            optionsDisabled: true,
            viewsWithMetrics,
            unavailableViews,
            getPopupContainer,
            metaModel,
            dashboardSelection,
            copiedMetric,
            getMoreInformationLink: getDatadocLink,
            loadDictionaryEntries,
            loadPeriods
          }}/>
        </MetricsWrapper>
        <TargetsWrapper name={'targets'} additionalDetails={additionalDetails}>
          <TargetsField {...{
            name: 'targets',
          }}/>
        </TargetsWrapper>
      </Col>
      <RightCol span={10}>
        <ChartTypeWrapper name={'displayType'}>
          <ChartTypeField {...{
            name: 'displayType',
            chartTypes,
          }}/>
        </ChartTypeWrapper>
        <PeriodWrapper name={'period'} rules={[{required: true, message: "Required field"}]}>
          <PeriodSelector {...{
            name: 'period',
            forTarget: true,
            withOverrideOption: false,
            withDateSlicerGranularity: false,
            getPopupContainer,
            environmentId,
            loadPeriods
          }}/>
        </PeriodWrapper>
      </RightCol>
    </Row>
  </Form>
})


export default FormComponentTarget

const RightCol = styled(Col)`
  border-left: 1px solid var(--main-background-color);
  
  & > .ant-row {
    padding-left: 24px;
  }
`