// @ts-nocheck   no check as this file should be deleted
import React from 'react'
import SimpleTooltip from './SimpleTooltip'
import styled from "styled-components"

class SimpleTooltipChart extends React.PureComponent<any, any> {
  render() {
    const {position, data, total, format} = this.props
    const {x, y} = position

    const style = {}
    style.top = y
    if (x > 0) {
      style.left = x
    } else {
      style.right = -x
    }

    return <TooltipContainer style={style}><SimpleTooltip label={data.label} values={data.values} total={total}
                                                          format={format}/></TooltipContainer>
  }
}

SimpleTooltipChart.defaultProps = {
  position: {
    y: 0,
    x: 0,
  },
}

export default SimpleTooltipChart

const TooltipContainer = styled.div`
  position: fixed;
  background: white;
  padding: 5px 10px;
  border: 1px solid var(--border-color-base);
  border-radius: 3px;
  pointer-events: none;
  font-weight: 400;
  z-index: 1000;
  
  td {
    padding-bottom: 2px;
    padding-top: 2px;
  }
`
