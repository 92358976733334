/* eslint-disable max-lines */
import {getResolvedFromPeriod} from "@biron-data/period-resolver"
import {getCurrentEnvironmentId} from "redux/environment.selector"
import {getDashboardId} from "redux/currentDashboard.selector"
import {getPeriods} from "redux/period.selector"
import {addChart, deleteChart, updateChartConf, updateDashboardLayout} from "services/DashboardService"
import {updateDashboardPeriodAndFilters} from "services/NavigationService"
import {captureError} from "services/SentryService"
import {updateTargets} from "services/TargetService"
import {saveSessionTime} from "services/TimeService"
import {pick} from "lodash"
import * as DashboardSchemas from "schemas/dashboard"
import {NormalizedDashboardTypes, NormalizedPersonalDashboard, NormalizedWorkspaceDashboard} from "schemas/dashboard"
import * as ChartSchemas from "schemas/chart"
import {returnOrExecBatch} from "commons/batch"
import {WidgetTypes} from "commons/dashboard/dashboard.types"
import {createModel} from "@rematch/core"
import RootModel from "redux/models/index"
import {ChartDtoDetailToAdd, ChartDtoDetailTypes, ChartGenericDtoDetail, ChartTargetDtoDetail, RawChartTypes} from "types/charts"
import {serializeChart} from "services/api"
import {ChartWithMetricDefLayoutTypes} from "components/widgetContainer/WidgetContainer"
import {consolidatedChartPeriod, consolidateRawDashboardPeriod, getDashboardAndChartsFromUri} from "redux/models/utils.model"
import {isFilterEmpty, DataSelection} from "@biron-data/react-bqconf"
import {DashboardTypes} from "types/dashboards"
import {updatePersonalDashboard} from "services/PersonalDashboard"
import {computeDefaultGranularity} from "components/forms/selector/dashboardDate/DashboardDateSelector"
import {getPersonalDashboardCharts, getPersonalDashboards} from "redux/personalDashboard.selector"
import {CurrentDashboardTypes, CurrentPersonalDashboard, CurrentWorkspaceDashboard, LayoutWithId} from "redux/models/currentDashboard.types"
import {applyModification, findFromUri, getChart, getDashboard, makeRightRequest, setDashboard} from "redux/models/currentDashboard.utils"

const initialState: CurrentDashboardTypes = {}

export default createModel<RootModel>()({
  state: initialState,
  reducers: {
    setSelection(state: CurrentDashboardTypes, selection: DataSelection) {
      return {
        ...state,
        selection,
      }
    },
    setDashboard(state: CurrentDashboardTypes, dashboard: NormalizedDashboardTypes) {
      switch (state.type) {
        case DashboardTypes.workspace:
          return {
            ...state,
            dashboard: dashboard.type === DashboardTypes.workspace ? dashboard : undefined,
          }
        case DashboardTypes.personal:
          return {
            ...state,
            dashboard: dashboard.type === DashboardTypes.personal ? dashboard : undefined,
          }
        default:
          return undefined
      }
    },
    setCharts(state: CurrentDashboardTypes, charts: Record<string, ChartDtoDetailTypes | undefined>) {
      return {
        ...state,
        charts: Object.fromEntries(Object.entries({
          ...state.charts,
          ...charts,
        }).filter(([, value]) => value !== undefined)) as Record<string, ChartDtoDetailTypes>,
      }
    },
    set(state, newState: CurrentDashboardTypes) {
      switch (newState.type) {
        case DashboardTypes.workspace:
          return {
            ...state,
            ...newState,
            selection: newState.selection ?? state.selection,
          } as CurrentWorkspaceDashboard
        case DashboardTypes.personal:
          return {
            ...state,
            ...newState,
            selection: newState.selection ?? state.selection,
          } as CurrentPersonalDashboard
        default:
          return undefined
      }
    },
    clear() {
      return initialState
    },
    'workspace/cleanStates': () => {
      return initialState
    },
  },
  effects: (dispatch) => {
    return {
      setFromUri({uri, timestamp, chartHashLink}: { uri: string, timestamp: number, chartHashLink?: string }, state) {
        const {redirect, dashboard, menu, charts} = findFromUri(state, uri, chartHashLink)
        setDashboard({
          type: DashboardTypes.workspace,
          dashboardId: dashboard?.id,
          redirect,
          menu,
        } as CurrentWorkspaceDashboard, dashboard, charts, timestamp, true, dispatch)
      },
      async setFromUid({uid, timestamp, chartHashLink, environmentId}: {
        uid: string,
        timestamp: number,
        chartHashLink: string,
        environmentId: number
      }, state) {
        const normalizedDashboard = getPersonalDashboards(state).find(dash => dash.uid === uid)
        const {
          dashboard,
          charts,
          ownedByUser,
          isFromServer,
        } = await getDashboardAndChartsFromUri(
          uid,
          environmentId,
          normalizedDashboard ? {
            normalizedDashboard,
            charts: getPersonalDashboardCharts(state)(normalizedDashboard.id),
          } : undefined,
          getPeriods(state),
          chartHashLink)
        setDashboard({
          type: DashboardTypes.personal,
          uid,
          dashboardId: dashboard.id,
        } as CurrentPersonalDashboard, dashboard, charts, timestamp, ownedByUser, dispatch)

        if (isFromServer) {
          dispatch.personalDashboards.setEntities({
            dashboards: {
              [dashboard.id]: dashboard,
            },
            charts,
          })
        }
      },
      async confUpdate(data: Omit<NormalizedWorkspaceDashboard, 'charts'> | Omit<NormalizedPersonalDashboard, 'charts'>, state): Promise<void> {
        const dashboard = getDashboard(state)
        if (!dashboard) {
          captureError("Could not edit dashboard, because the dashboard could not be found", {})
          return
        }
        const periods = getPeriods(state)

        const dashboardToUpdate = await makeRightRequest(
          dashboard,
          {
            ...data,
            title: data.title ?? dashboard.title
          },
          async (dto) => updateDashboardPeriodAndFilters(dashboard.id, {
            ...dto,
            period: {
              code: dto.period.code,
            },
          }),
          async (dto) => updatePersonalDashboard(dashboard.id, {
            ...dto,
            period: {
              code: dto.period.code,
            },
          }),
        )

        if (!dashboardToUpdate) {
          captureError("Could not edit dashboard, because the dashboard type could not be determined", {})
          return
        }

        const newDashboardRaw = consolidateRawDashboardPeriod(dashboardToUpdate, periods)

        applyModification(
          state,
          () => dispatch.workspace.setEntities({
            // update only dashboards entities because charts should be deep equals to their existing version although they are not referentially equals
            dashboards: DashboardSchemas.normalizeDashboardDetail(newDashboardRaw).entities.dashboards,
            charts: DashboardSchemas.normalizeDashboardDetail(newDashboardRaw).entities.charts,
          }),
          () => {
            dispatch.personalDashboards.setEntities({
              // update only dashboards entities because charts should be deep equals to their existing version although they are not referentially equals
              dashboards: DashboardSchemas.normalizeDashboardDetail(newDashboardRaw).entities.dashboards,
              charts: DashboardSchemas.normalizeDashboardDetail(newDashboardRaw).entities.charts,
            })
          },
        )

        const date = data.period ? getResolvedFromPeriod(data.period) : undefined
        const granularity = date ? computeDefaultGranularity({start: date?.start, end: date?.end}) : undefined
        dispatch.currentDashboard.setDashboard(
          DashboardSchemas.normalizeDashboardDetail(newDashboardRaw).entities.dashboards[newDashboardRaw.id],
        )
        dispatch.currentDashboard.setSelection({
          ...state.currentDashboard.selection as DataSelection,
          ...{
            filters: data.filters,
            date: {
              ...(state.currentDashboard.selection as DataSelection).date,
              ...date,
              granularity: granularity ?? (state.currentDashboard.selection as DataSelection).date.granularity,
            },
          },
        })
      },
      async layoutUpdate(data: LayoutWithId[], state) {
        const newDashboard = await updateDashboardLayout(
          getDashboardId(state),
          data.map((chart: LayoutWithId) => pick(chart, ['id', 'x', 'y', 'w', 'h'])),
        )
        const periods = getPeriods(state)
        dispatch.currentDashboard.setCharts(DashboardSchemas.normalizeDashboardDetail(consolidateRawDashboardPeriod(newDashboard, periods)).entities.charts)
        applyModification(
          state,
          () => dispatch.workspace.setEntities(DashboardSchemas.normalizeDashboardDetail(consolidateRawDashboardPeriod(newDashboard, periods)).entities),
          () => dispatch.personalDashboards.setEntities(DashboardSchemas.normalizeDashboardDetail(consolidateRawDashboardPeriod(newDashboard, periods)).entities),
        )
      },
      selectionUpdate(data: DataSelection) {
        if (data.date) {
          saveSessionTime(data.date)
        }
        dispatch.currentDashboard.setSelection(data)
      },
      async chartAdd({
                       // @ts-ignore
                       targets,
                       ...data
                     }, state) {
        if (!state.currentDashboard) {
          return undefined
        }
        const dashboard = getDashboard(state)
        if (!dashboard) {
          return undefined
        }
        const periods = getPeriods(state)
        const newChart = consolidatedChartPeriod(await addChart(dashboard.id, {
            ...serializeChart({
              ...data,
              period: data?.period ? {
                code: data?.period.code,
              } : undefined,
            }),
            id: undefined,
          },
        ), periods)

        const normalized = ChartSchemas.normalizeChartDetail(newChart)

        dispatch.currentDashboard.setDashboard({
          ...dashboard,
          charts: [...dashboard.charts, newChart.id],
        })
        if (normalized.entities.charts) {
          dispatch.currentDashboard.setCharts(normalized.entities.charts)
        }
        applyModification(
          state,
          () => dispatch.workspace.setEntities({
            ...normalized.entities,
            dashboards: {
              [dashboard.id]: {
                ...dashboard,
                charts: [...dashboard.charts, newChart.id],
              },
            },
          }),
          () => dispatch.personalDashboards.setEntities({
            ...normalized.entities,
            dashboards: {
              [dashboard.id]: {
                ...dashboard,
                charts: [...dashboard.charts, newChart.id],
              },
            },
          }),
        )

        if (targets) {
          const environmentId = getCurrentEnvironmentId(state)
          await updateTargets({
            environmentId,
            viewCode: (data as ChartTargetDtoDetail).viewCode,
            metricCode: (data as ChartTargetDtoDetail).metricCode,
            targets,
          })
        }
        return newChart
      },
      async chartConfUpdate(
        {
          forBatch = false,
          data: {
            // @ts-ignore
            targets,
            ...data
          },
        }: { forBatch: boolean, data: ChartDtoDetailToAdd & { id: number } },
        state) {
        const chart = getChart(data.id, state)

        if (!chart) {
          throw Error("Unable to find the chart to update")
        }

        const periods = await getPeriods(state)
        const consolidatedData = {...chart, ...data} as ChartDtoDetailTypes
        if (targets) {
          const environmentId = getCurrentEnvironmentId(state)
          await updateTargets({
            environmentId,
            viewCode: (consolidatedData as ChartTargetDtoDetail).viewCode,
            metricCode: (consolidatedData as ChartTargetDtoDetail).metricCode,
            targets,
          })
        }
        const serializedExtraConf = serializeChart({
          ...consolidatedData,
          period: (consolidatedData.type !== WidgetTypes.DIVIDER) && consolidatedData.period ? {
            code: consolidatedData.period.code,
          } : undefined,
          filters: consolidatedData && consolidatedData.type === WidgetTypes.GENERIC && (consolidatedData as ChartGenericDtoDetail).filters ? (consolidatedData as ChartGenericDtoDetail).filters.filter(filter => !isFilterEmpty(filter)) : [],
        } as ChartWithMetricDefLayoutTypes)
        const newChart = consolidatedChartPeriod(await updateChartConf(chart.id, serializedExtraConf), periods)

        return returnOrExecBatch(forBatch,
          () => {
            const normalizedCharts = ChartSchemas.normalizeChartDetail(newChart as RawChartTypes).entities.charts
            if (normalizedCharts) {
              dispatch.currentDashboard.setCharts(normalizedCharts)
            }

            applyModification(
              state,
              () => dispatch.workspace.setEntities(ChartSchemas.normalizeChartDetail(newChart as RawChartTypes).entities),
              () => dispatch.personalDashboards.setEntities(ChartSchemas.normalizeChartDetail(newChart as RawChartTypes).entities),
            )
          },
        )
      },
      async chartDelete(id: number, state) {
        const dashboard = getDashboard(state)
        if (!dashboard) {
          return
        }
        await deleteChart(id ?? dashboard.id)

        dispatch.currentDashboard.setDashboard({
          ...dashboard,
          charts: dashboard.charts.filter(candidateId => candidateId !== id),
        })
        dispatch.currentDashboard.setCharts({
          [id]: undefined,
        })
        applyModification(
          state,
          () => dispatch.workspace.setEntities({
            dashboards: {
              [dashboard.id]: {
                ...dashboard,
                charts: dashboard.charts.filter(candidateId => candidateId !== id),
              },
            },
          }),
          () => dispatch.personalDashboards.setEntities({
            dashboards: {
              [dashboard.id]: {
                ...dashboard,
                charts: dashboard.charts.filter(candidateId => candidateId !== id),
              },
            },
          }),
        )
      },
    }
  },
})