/* eslint-disable max-lines */
import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {
  buildMetricLabel,
  converterFilterToConfModel,
  GenericChartTypes,
  getAvailableDimensions,
  getMetricDef,
  MetaModel,
  MetricDef,
  OptionListPagination,
} from "@biron-data/react-bqconf"
import {useSelector} from "react-redux"
import {getCurrentEnvironmentId, getCurrentMetaModel} from "redux/environment.selector"
import {getPermission} from "redux/appEnvironment.selector"
import {getExportDestinations} from "redux/exportDestination.selector"
import {getCurrentWorkspaceExpanded, getDashboards} from "redux/workspace.selector"
import HideableForm from "components/forms/Form.Hideable"
import {
  Days,
  Export,
  ExportBasicDtoForm,
  ExportDestination,
  ExportDtoForm,
  ExportExecutionDto,
  FormExportBasicConfProps,
  FormExportExecutionConfProps,
  FormKeys,
  FormType,
} from "components/forms/Form.types"
import FormExportBasicCmp from "components/forms/export/FormExportBasicCmp"
import {loadDictionaryEntries} from "services/MetaModelService"
import {WidgetTypes} from "commons/dashboard/dashboard.types"
import useDispatch from "hooks/useDispatch"
import {Alert, Button, Popover, Table, TableColumnProps} from "antd"
import ExportManagerInformation from "components/admin/exports/ExportManager.Information"
import {PlusIcon, XCircleIcon} from "@heroicons/react/outline"
import styled from "styled-components"
import Language from "language"
import {Confirm, IconContainer, PageTitle, TooltipPopover} from "@biron-data/react-components"
import ExportManagerOptions from "components/admin/exports/ExportManager.Options"
import FormExportExecutionCmp from "components/forms/export/FormExportExecutionCmp"
import {ExpandedWorkspace} from "redux/models/workspace"
import {predefinedPeriod} from "@biron-data/period-resolver"
import {useNavigate} from "react-router-dom"
import useUpdatedExports from "hooks/useUpdatedExports"
import {useLanguageResolver} from "@biron-data/react-contexts"
import {PermissionsEnum} from "redux/models/appEnvironment"

type ExportWithMetricDef = Omit<Export, "metrics"> & { metrics: (Export["metrics"][number] & { metricDef?: MetricDef })[] }

const ExportManager = () => {
  const getPermissionSelector = useSelector(getPermission)
  const userCanSetPowerUser = getPermissionSelector?.(PermissionsEnum.userCanManageAdvancedFeature)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const environmentId: number = useSelector(getCurrentEnvironmentId) as number
  const metaModel = useSelector(getCurrentMetaModel) as MetaModel
  const workspace = useSelector(getCurrentWorkspaceExpanded) as ExpandedWorkspace
  const currentDashboards = useSelector(getDashboards)
  const dashboard = useMemo(() => Object.values(currentDashboards)[0], [currentDashboards])
  const exports = useUpdatedExports(true)
  const exportDestinations = useSelector(getExportDestinations)
  const [isAlertDisplayed, setIsAlertDisplayed] = useState(false)
  const [editedData, setEditedData] = useState<ExportWithMetricDef>()
  const [isPopupOpen, setIsPopupOpen] = useState(false)
  const [selectedExportDestination, setSelectedExportDestination] = useState<ExportDestination>(Object.values(exportDestinations)[0])
  const lr = useLanguageResolver()

  useEffect(() => {
    if (!userCanSetPowerUser) {
      navigate("/")
    }
  }, [navigate, userCanSetPowerUser])

  const defaultExportProperties: Export = useMemo(() => ({
    id: -1,
    title: Language.get('new-export-title'),
    type: WidgetTypes.EXPORT,
    displayType: GenericChartTypes.TABLES,
    period: predefinedPeriod.LAST_YEAR,
    filters: [],
    slicers: [],
    metrics: [],
    days: [
      Days.MONDAY,
      Days.TUESDAY,
      Days.WEDNESDAY,
      Days.THURSDAY,
      Days.FRIDAY,
      Days.SATURDAY,
      Days.SUNDAY,
    ],
    destination: selectedExportDestination,
    ignoreMetrics0: false,
    override: false,
    orderBys: [],
  }), [selectedExportDestination])

  const [basicConfEdit, setBasicConfEdit] = useState(false)
  const [executionConfEdit, setExecutionConfEdit] = useState(false)

  const formValue: ExportWithMetricDef = useMemo(
    () => {
      return editedData ? ({
        ...defaultExportProperties,
        ...editedData,
      }) : ({
        ...defaultExportProperties,
        id: -1,
      })
    },
    [defaultExportProperties, editedData],
  )

  const handleConfSubmit = useCallback((newConf: ExportDtoForm) => {
    setBasicConfEdit(false)
    setExecutionConfEdit(false)
    return new Promise(() => {
      if (newConf.id === -1) {
        dispatch.exports.createExport({
          data: newConf,
        })
      } else {
        dispatch.exports.updateExport({
          data: newConf,
        })
      }
    })
  }, [dispatch.exports])

  const handleConfCancel = useCallback(
    () => {
      setBasicConfEdit(false)
      setExecutionConfEdit(false)
      setEditedData(undefined)
    },
    [],
  )

  const onLoadDictionaryEntries = useCallback(
    (dictionaryCode: string, search?: string, pagination?: OptionListPagination) => loadDictionaryEntries(environmentId, dictionaryCode, search, pagination),
    [environmentId],
  )

  const formType: FormType = useMemo(
    () => ({
      type: FormKeys.EXPORT_CONF,
    }),
    [],
  )

  const onBasicConfEdit = useCallback(
    (exportData: Export) => {
      setBasicConfEdit(true)
      setEditedData({
        ...exportData,
        metrics: exportData.metrics.map(m => {
          const metricDef = getMetricDef(metaModel, m)
          return {
            ...m,
            metricDef,
            metricAlias: metricDef ? buildMetricLabel({
              ...m,
              additionalFilters: converterFilterToConfModel(m.additionalFilters, getAvailableDimensions(metaModel, exportData.metrics.map(metric => metric.viewCode))),
              metricDef,
            }, lr) : '',
          }
        }),
      })
    }, [lr, metaModel],
  )

  const onExecutionConfEdit = useCallback(
    (exportData: Export) => {
      setExecutionConfEdit(true)
      setEditedData({
        ...exportData,
        metrics: exportData.metrics.map(m => ({
          ...m,
          metricDef: getMetricDef(metaModel, m),
        })),
      })
    }, [metaModel],
  )

  const onClose = useCallback(
    (id: number) => {
      dispatch.exports.deleteExport(id)
    },
    [dispatch.exports],
  )

  const columns: TableColumnProps<Export>[] = useMemo(() => ([
    {
      title: Language.get("export.informations"),
      width: '48%',
      sorter: (a, b) => {
        return `${a.title} ${a.title}`.localeCompare(`${b.title} ${b.title}`)
      },
      render: function render(user, exportData) {
        return <ExportManagerInformation data={exportData} metaModel={metaModel} onConfEdit={() => onBasicConfEdit(exportData)}
                                         dashboardId={dashboard?.id}/>
      },
    },
    {
      title: Language.get("export.execution"),
      width: '48%',
      render: function render(user, exportData) {
        return <ExportManagerOptions {...exportData} onConfEdit={() => onExecutionConfEdit(exportData)}/>
      },
    },
    {
      title: Language.get("export.action"),
      width: '4%',
      render: function render(user, exportData) {
        return <TooltipPopover
          size={'small'}
          placement="topRight"
          content={Language.get('chart-remove-button')}>
          <Confirm
            title={Language.get('chart-delete-confirmation')}
            trigger={<div><IconContainer clickable={true} hoverColor={"var(--primary-light)"}
                                         containerSize={28}><XCircleIcon/></IconContainer>
            </div>}
            on={{
              cancel: () => {
              },
              open: () => {
              },
              ok: () => {
                onClose(exportData.id)
              },
            }}/>
        </TooltipPopover>
      },
    },
  ]), [dashboard?.id, metaModel, onBasicConfEdit, onClose, onExecutionConfEdit])

  const addExportButton = useMemo(() => {
    if (exportDestinations.length <= 1) {
      return <Button onClick={() => {
        if (exportDestinations.length === 0) {
          setIsAlertDisplayed(true)
        } else {
          setSelectedExportDestination(exportDestinations[0])
          setBasicConfEdit(true)
        }
      }} className="dashboard-add-widget-button" type="primary">
        <FlexDiv>
          <IconContainer margin={10}><PlusIcon/></IconContainer>
          {Language.get("export.add", exportDestinations[0]?.name ?? '')}
        </FlexDiv>
      </Button>
    } else {
      return <Popover
        trigger="click"
        placement="left"
        open={isPopupOpen}
        onOpenChange={(state) => setIsPopupOpen(state)}
        content={<div>
          {exportDestinations.map((exportDestination) => <Button type={"link"} key={exportDestination.id} onClick={() => {
            setSelectedExportDestination(exportDestination)
            setBasicConfEdit(true)
          }}>{exportDestination.name}</Button>)}
        </div>}>
        <Button className="dashboard-add-widget-button" type="primary">
          <FlexDiv>
            <IconContainer margin={10}><PlusIcon/></IconContainer>
            {Language.get('dashboard-add-chart')}
          </FlexDiv>
        </Button>
      </Popover>
    }
  }, [exportDestinations, isPopupOpen])

  return <div>
    <HideableForm<ExportBasicDtoForm, FormExportBasicConfProps> {...{
      renderFormComponent: (props) => <FormExportBasicCmp {...props}/>,
      visible: basicConfEdit,
      data: formValue as ExportBasicDtoForm,
      formType,
      metaModel,
      workspace,
      environmentId,
      dashboardId: dashboard?.id,
      loadDictionaryEntries: onLoadDictionaryEntries,
      onConfirm: (newValue: ExportBasicDtoForm) => handleConfSubmit({
        ...formValue,
        ...newValue,
      }),
      onCancel: handleConfCancel,
    }}/>
    <HideableForm<ExportExecutionDto, FormExportExecutionConfProps> {...{
      renderFormComponent: (props) => <FormExportExecutionCmp {...props}/>,
      visible: executionConfEdit,
      data: {
        ...formValue,
      } as ExportExecutionDto,
      title: Language.get("export.execution"),
      formType,
      metaModel,
      exportDestinations: Object.values(exportDestinations),
      onConfirm: (newValue: ExportExecutionDto) => {
        return handleConfSubmit({
          ...formValue,
          ...Object.fromEntries(Object.entries(newValue).filter(([key]) => key !== 'title')),
        })
      },
      onCancel: handleConfCancel,
    }}/>
    <PageTitle>{Language.get("export.title")}</PageTitle>
    <Header>
      {isAlertDisplayed && <Alert
        type={"error"}
        message={Language.get("export.alert.no-destination")}
        banner
      />}
      {addExportButton}
    </Header>

    <Table {...{
      size: 'small',
      bordered: true,
      pagination: false,
      columns,
      dataSource: exports,
      rowKey: 'id',
      scroll: {y: 650},
    }}/>
  </div>
}

export default ExportManager

const Header = styled.div`
    display: flex;
    justify-content: end;
    margin: 10px;
`

const FlexDiv = styled.div`
    display: flex;
    align-items: center;
`