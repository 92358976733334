import React, {useCallback, useEffect, useMemo} from 'react'
import {useSelector} from 'react-redux'
import AppContainer from "AppContainer"
import {getConsts, getUser} from "redux/appEnvironment.selector"
import {Loader} from "@biron-data/react-components"
import {BrowserRouter as Router, useNavigate} from 'react-router-dom'
import useDispatch from "hooks/useDispatch"
import {AuthProvider, AuthProviderProps} from "react-oidc-context"
import {oidcConfigTemplate, onSigninCallback} from "@biron-data/react-oidc"
import {LanguageResolver, LanguageResolverContext} from "@biron-data/react-contexts"
import Language from "language"
import {captureError} from "services/SentryService"
import * as FRLanguage from "./language/language_fr";
import * as ENLanguage from "./language/language_en";

const AppSecuredContent = () => {
  const dispatch = useDispatch()
  const consts = useSelector(getConsts)
  const user = useSelector(getUser)
  const loadedConsts = Boolean(consts.OIDC_ISSUER)

  const navigate = useNavigate()

  const oidcConfig: AuthProviderProps = {
    ...oidcConfigTemplate,
    authority: consts?.OIDC_ISSUER ?? "",
    // eslint-disable-next-line camelcase
    client_id: consts?.OIDC_CLIENTID ?? "",
    onSigninCallback: useCallback(() => onSigninCallback(navigate), [navigate]),
  }

  const languageResolver = useMemo(
    () =>
      new LanguageResolver(
        user?.locale?.substring(0, 2).toLowerCase() ?? Language.getLabel(),
        {
          fr: FRLanguage.default,
          en: ENLanguage.default,
        },
        (key, locale) => {
          captureError('Wording - text not found', {
            key,
            lang: locale,
          })
        },
      ),
    [user],
  );

  useEffect(() => {
    dispatch.appEnvironment.loadStatic()
  }, [dispatch])

  return <LanguageResolverContext.Provider value={languageResolver}>
    {loadedConsts
      ? <AuthProvider
        {...oidcConfig}
      >
        <AppContainer/>
      </AuthProvider>
      : <Loader/>}
  </LanguageResolverContext.Provider>
}

const AppSecured = () => {
  return <Router>
    <AppSecuredContent/>
  </Router>
}

export default AppSecured
