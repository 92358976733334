import {UsageTypes} from "components/dataSourceDoc/DataSource.types"
import Router from "react-router-dom"
import Language from "language"

export const getDashboardLink = (record: UsageTypes, location: Router.Location) => {
  switch (record.type) {
    case "Chart":
      return `${location.pathname.substring(0, location.pathname.indexOf("help"))}${record.dashboard?.uri}`
    case "Export":
      return undefined
    case "Dashboard":
      return `${location.pathname.substring(0, location.pathname.indexOf("help"))}${record.object.uri}`
    default:
      return undefined
  }
}

export const getUsageLink = (record: UsageTypes, location: Router.Location) => {
  switch (record.type) {
    case "Chart":
      return `${location.pathname.substring(0, location.pathname.indexOf("help"))}${record.dashboard?.uri}#${encodeURI(`${record.object.id}-${record.object.title ?? Language.get('new-chart-title')}`)}`
    case "Export":
      return `${location.pathname.substring(0, location.pathname.indexOf("help"))}admin/exports#${encodeURI(`${record.object.id}-${record.object.title ?? Language.get('new-export-title')}`)}`
    case "Dashboard":
      return `${location.pathname.substring(0, location.pathname.indexOf("help"))}${record.object.uri}`
    default:
      return ""
  }
}