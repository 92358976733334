import {useEffect, useState} from "react"
import {Export} from "components/forms/Form.types"
import useDispatch from "hooks/useDispatch"
import {useSelector} from "react-redux"
import {getCurrentEnvironmentId} from "redux/environment.selector"
import {getPermission} from "redux/appEnvironment.selector"
import {PermissionsEnum} from "redux/models/appEnvironment"

const useLoadExports = (loadDestination: boolean) => {
  const currentClientId = useSelector(getCurrentEnvironmentId)
  const [currentExports, setCurrentExport] = useState<Export[]>([])
  const getPermissionSelector = useSelector(getPermission)
  const userCanManageAdvancedFeature = getPermissionSelector(PermissionsEnum.userCanManageAdvancedFeature)
  const dispatch = useDispatch()

  useEffect(() => {
    if (userCanManageAdvancedFeature) {
      dispatch.exports.loadExports({forBatch: false, environment: {id: currentClientId}}).then(async (exportsResult) => {
        if (loadDestination) {
          dispatch.exportDestination.loadExportDestination({
            forBatch: false,
            environment: {id: currentClientId},
          }).then((destinationResult) => {
            setCurrentExport(exportsResult.payload.map(e => ({
              ...e,
              destination: destinationResult.payload.find(destination => destination.id === e.destination.id) ?? e.destination,
            })))
          })
        } else {
          setCurrentExport(exportsResult.payload)
        }
      })
    }
  }, [currentClientId, dispatch.exportDestination, dispatch.exports, loadDestination, userCanManageAdvancedFeature])

  return currentExports
}


export default useLoadExports