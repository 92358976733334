import React, {memo} from 'react'
import TableChartDataCell from 'components/charts/table/TableChart.DataCell'
import {TableColumn, TableColumnWithProcess} from "types/charts"
import {QueryResponseDataWithCategory} from "services/QueryService"
import styled from "styled-components"
import Language from "language"

/* eslint-disable react/prop-types */

interface Props {
  row: QueryResponseDataWithCategory
  columns: TableColumn[]
  widths: number[]
  totalData: QueryResponseDataWithCategory
  printPercentage?: boolean
  isTotal?: boolean
  isOther?: boolean
}

const TableChartDataRow = memo<Props>(function DataRow({
                                               row,
                                               columns,
                                               widths,
                                               totalData,
                                               printPercentage,
                                               isTotal,
                                               isOther,
                                             }) {
  return <RowData $istotal={isTotal ? 1 : 0}>
      {columns.map((column, i) => {
        let newColumn: TableColumnWithProcess = Object.assign({processValue: (v: string | number) => v}, column)
        const columnIndex = newColumn.key
        let rawValue = row[columnIndex]
        if (isTotal) {
          if (columnIndex === 0) {
            rawValue = 'TOTAL'
            newColumn = {...newColumn, processValue: () => 'TOTAL'}
          } else if (newColumn.nature !== 'metric') {
            rawValue = ''
            newColumn = {...newColumn, processValue: v => v}
          }
        }
        if (isOther) {
          if (columnIndex === 0) {
            rawValue = Language.get("chart-others-series")
            newColumn = {...newColumn, processValue: () => Language.get("chart-others-series")}
          } else if (newColumn.nature !== 'metric') {
            rawValue = ''
            newColumn = {...newColumn, processValue: v => v}
          }
        }
        const total = !isTotal && totalData && typeof totalData[columnIndex] === 'number' ? totalData[columnIndex] : 0
        return <TableChartDataCell key={columnIndex} {...{
          rawValue,
          total,
          printPercentage,
          column: newColumn,
          width: widths[i],
        }}/>
      })}
    </RowData>
  },
)

export default TableChartDataRow

const RowData = styled.tr<{ $istotal: number }>`
    font-weight: ${props => props.$istotal ? '500' : '300'};

    ${props => props.$istotal ? `
  background-color: var(--main-background-color) !important;` : ''}
  &:nth-of-type(odd) {
    background-color: var(--row-odd);
  }

    &:nth-of-type(even) {
    background-color: var(--row-even);
  }

    &:not(.total-row):hover {
    background-color: var(--option-background);
  }
`