import {captureWarn} from "services/SentryService"

export function downloadDataUrl(filename: string, dataUrl?: string) {
  if (!dataUrl) {
    captureWarn("Attempt to download empty data url")
    return
  }
  // Construct the 'a' element
  const link = document.createElement("a")
  link.download = filename
  link.target = "_blank"

  // Construct the URI
  link.href = dataUrl
  document.body.appendChild(link)
  link.click()

  // Cleanup the DOM
  document.body.removeChild(link)
}
