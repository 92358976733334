import {Layout} from 'antd'
import styled from "styled-components"

export const FlexDiv = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
`

export const StyledHeader = styled(Layout.Header)`
  display: flex;
  align-items: center;
  flex-flow: row nowrap;
  justify-content: space-between;
`

export const SwitchState = styled.div`
  margin-left: 8px;
`

export const EditMode = styled.div<{ $editmode: number, $enabled: number }>`
  display: flex;
  align-items: center;
  white-space: nowrap;
    cursor: pointer;
  
  &:hover {
    color: var(--primary);

      ${({$editmode}) => $editmode ? '' : `
      .ant-switch{
          background-image: linear-gradient(to right, var(--light-grey), var(--light-grey)), linear-gradient(to right, #fff, #fff);
      }
    `}
  }

    ${({$enabled}) => $enabled ? '' : `
    display: none;
  `}
}
`
