import React, {memo} from 'react'
import {Loader} from "@biron-data/react-components"
import ParentMenu from '.././confItems/ParentMenu'
import PositionDashboard from '.././confItems/PositionDashboard'
import {FormItemKeys} from "commons/keywords/form"
import {FormBodyComponentProps} from "components/forms/confBodies/FormBody"

const NavAddDashboardFormBody = <T, >({
                                        loading,
                                        getPopupContainer,
                                        availables: {
                                          menus = [],
                                          dashboardsPositions = [],
                                        },
                                      }: FormBodyComponentProps<T>) => {

  return <Loader loading={loading}>
    <ParentMenu
      name={FormItemKeys.PARENT_MENU}
      span={24}
      options={menus}
      {...{
        getPopupContainer,
      }}/>
    <PositionDashboard
      name={FormItemKeys.POSITION_DASHBOARD}
      options={dashboardsPositions}
      span={24}
      {...{
        getPopupContainer,
      }}/>
  </Loader>
}

export default memo(NavAddDashboardFormBody) as typeof NavAddDashboardFormBody
