import {createSelector} from "reselect"
import {RootState} from "redux/store"
import {DashboardFormats, DashboardTypes} from "types/dashboards"
import {NormalizedPersonalDashboardTypes, NormalizedWorkspaceDashboard} from "schemas/dashboard"
import {ChartDtoDetailTypes} from "types/charts"

export const isPersonalDashboard = <T extends NormalizedWorkspaceDashboard>(dashboard: NormalizedPersonalDashboardTypes | T): dashboard is NormalizedPersonalDashboardTypes => dashboard.type === DashboardTypes.personal

export const getPersonalDashboards = createSelector(
  (state: RootState) => state.personalDashboards.entities.dashboards,
  (entities) => Object.values(entities).filter(isPersonalDashboard),
)

export const getPersonalDashboardCharts = createSelector(
  (state: RootState) => state.personalDashboards.entities.charts,
  (state: RootState) => state.personalDashboards.entities.dashboards,
  (charts, dashboards) => (id: number | undefined): Record<string, ChartDtoDetailTypes> => {
    if (id) {
      const dashboard = dashboards[id]
      if (dashboard.format === DashboardFormats.detailed) {
        return Object.fromEntries(dashboard.charts.map(chartId => [chartId, charts[chartId]]))
      }
    }
    return {}
  },
)


