import {useCallback} from "react"
import {State} from "redux/models/clipboard"
import {notification} from "antd"
import Language from "language"
import {ChartDtoDetail} from "types/charts"

export const useHandleChartCopyToClipboard = (copyChart: (state: State) => void) => useCallback(
  (chart: ChartDtoDetail | null) => {
    notification.info({
      duration: 2.5,
      key: 'dashboard-chartCopied-confirmation',
      message: Language.get(`dashboard-chartCopied-confirmation-title`),
      description: Language.get(`dashboard-chartCopied-confirmation-description`),
      placement: 'bottomRight',
    })
    if (chart) {
      copyChart({chart} as State)
    }
  },
  [copyChart],
)
