import React, {PropsWithChildren, ReactNode} from "react"
import styled from "styled-components"
import {captureError} from "services/SentryService"
import Language from "language"
import {Button, Result} from 'antd'

interface State {
  hasError: boolean
}

export interface GlobalErrorBoundaryProps extends PropsWithChildren {
  children: ReactNode
}

export default class GlobalErrorBoundary extends React.Component<GlobalErrorBoundaryProps, State> {
  protected actions: ReactNode[]

  constructor(props: GlobalErrorBoundaryProps) {
    super(props)
    this.state = {hasError: false}
    this.actions = [<Button type={"primary"} key={"reload"} onClick={this.handleReload}>{Language.get('reload-app')}</Button>]
  }

  static getDerivedStateFromError() {
    return {hasError: true}
  }

  handleReload = () => {
    window.location.reload()
  }

  componentDidCatch(error: Error | string) {
    captureError("Error boundary error", [], error)
  }

  render() {
    if (this.state.hasError) {
      return <StyledDiv>
        <Result
          status={"error"}
          title={Language.get("error-occurred")}
          extra={this.actions}/>
      </StyledDiv>
    }
    return this.props.children
  }
}

const StyledDiv = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  height: 100%;
`
