import {useCallback} from "react"
import {WidgetTypes} from "commons/dashboard/dashboard.types"
import {useGetChartLayoutPosition, useGetLayoutVisibleBounds} from "components/dashboard/grid/DashboardGrid.utils"
import {Layout} from "react-grid-layout"
import {GetWidgetRef} from "components/dashboard/grid/dashboardGridLayoutUtils"
import {ChartDtoDetailTypes} from "types/charts"
import {ButtonPosition} from "hooks/useHandleAddChart"

export type UseGetChartLayoutForAddType = (
  grid: HTMLDivElement | null,
  lgLayout: Layout[],
  getWidgetRef: GetWidgetRef,
  sortedCharts: ChartDtoDetailTypes[],
  isChartsMounted: boolean) => (widgetTypes: WidgetTypes | undefined, buttonPosition?: {
  x: number,
  y: number
}) => ({
  w: number,
  h: number,
  x: number,
  y: number,
})
export const useGetChartLayoutForAdd = (
  grid: HTMLDivElement | null,
  lgLayout: Layout[],
  getWidgetRef: GetWidgetRef,
  sortedCharts: ChartDtoDetailTypes[],
  isChartsMounted: boolean) => {
  const getLayoutVisibleBounds = useGetLayoutVisibleBounds(grid)
  const getChartLayoutPosition = useGetChartLayoutPosition(getWidgetRef, sortedCharts, isChartsMounted)

  return useCallback((widgetTypes: WidgetTypes | undefined, buttonPosition?: ButtonPosition) => {
    const visibleBounds = getLayoutVisibleBounds()
    if (buttonPosition) {
      return {
        w: 2,
        h: widgetTypes === WidgetTypes.DIVIDER ? 1 : 3,
        x: buttonPosition.x,
        y: buttonPosition.y - 1,
      }
    }
    const lastVisibleItem = lgLayout
      .map((li: any) => ({
        bottomPosition: li.y + li.h - 1,
        bottomPixel: getChartLayoutPosition(Number(li.i))?.bottom,
      }))
      .filter((item: any) => visibleBounds && item.bottomPixel <= visibleBounds.bottom)
      .sort((a: any, b: any) => b.bottomPosition - a.bottomPosition)[0]
    const y = lastVisibleItem?.bottomPosition || 0
    return {
      w: 2,
      h: widgetTypes === WidgetTypes.DIVIDER ? 1 : 3,
      x: 0,
      y,
    }
  }, [getChartLayoutPosition, getLayoutVisibleBounds, lgLayout])
}