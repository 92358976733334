import Workflow from 'classes/workflows/main-workflows/Workflow'
import WorkspaceModel, {Option} from '../../WorkspaceModel'
import {FormItemKeys} from "commons/keywords/form"
import {BaseOption} from "@biron-data/react-components"
import {BaseEditionInformation, HandleMenuActionParamValueTypes} from "components/workspace/WorkspaceBridge.SiderContainer"
import {ExpandedWorkspace} from "redux/models/workspace"
import {FormKeys, FormType} from "components/forms/Form.types"

// @ts-ignore
const keysForm: string[] = Object.keys(FormItemKeys).map(k => FormItemKeys[k])

export interface Position extends BaseOption {
  label: string,
  value: string,
  key: string
}

export interface State {
  value?: HandleMenuActionParamValueTypes & { title?: string, name?: string, cssClass?: string },
  availables: {
    menus: number[],
    dashboardsPositions: Position[],
    menusPositions: Position[],
  }
  loading: boolean,
}

export default class FormWorkflow extends Workflow {
  private readonly defaultData: BaseEditionInformation
  private readonly dashboardId?: number
  private readonly environmentId?: number
  private readonly workspaceModel: WorkspaceModel
  private availables?: {
    menus: Option[]
    menusPositions: Position[]
    dashboardsPositions: Position[]
  } = undefined

  constructor(formType: FormType, data: BaseEditionInformation, setState: (state: State) => void, workflowName: string, workspace: Pick<ExpandedWorkspace, 'menus'> = {menus: []}, dashboardId?: number, environmentId?: number) {
    super(workflowName, setState, formType)
    this.defaultData = data
    this.dashboardId = dashboardId
    this.environmentId = environmentId
    this.formType = formType
    this.workspaceModel = new WorkspaceModel(workspace)

    keysForm.forEach(k => {
      // @ts-ignore
      this[k] = this.defaultData[k]
    })
    // @ts-ignore   return instructions can be removed, but for now I only want to type
    return this.loadAvailables();
  }

  /**
   * load the availables views(if asked), metrics, slicers, anf filters dimensions
   * @return {null} : launch a updateConfBody
   */
  loadAvailables() {
    this.availables = {
      menus: this.workspaceModel?.getMenusAvailables(),
      menusPositions: this.workspaceModel?.getMenusPositions(this.formType?.type === FormKeys.EDIT_MENU && this.defaultData.id ? this.defaultData.id : -1),
      dashboardsPositions: this.workspaceModel
        // @ts-ignore
        ?.getDashboardsPositions(this[FormItemKeys.PARENT_MENU], this.formType.type === FormKeys.EDIT_DASHBOARD ? this.dashboardId : -1),
    }
    this.updateConfBody()
  }

  updateConfBody() {
    super.setState({
      value: keysForm.reduce((acc, k) => ({
        ...acc,
        // @ts-ignore
        [k]: this[k],
      }), {}),
      availables: this.availables,
      loading: false,
    });
  }

  getConfResult() {
    const result = {};
    keysForm.forEach(k => {
      // @ts-ignore
      if (typeof this[k] !== 'undefined') {
        // @ts-ignore
        result[k] = this[k];
      }
    });
    return result;
  }

  saveConf() {
    keysForm.forEach(k => {
      // @ts-ignore
      this.defaultData[k] = this[k];
    });
    this.updateConfBody()
  }

  update(key: string, value: string) {
    // @ts-ignore
    this[key] = value
    if (key === FormItemKeys.PARENT_MENU && this.availables) {
      this.loadAvailables()
      this.update(FormItemKeys.POSITION_DASHBOARD, `${this.availables.dashboardsPositions[1].value}`)
    } else {
      this.updateConfBody()
    }
  }

  unsubscribe() {
    super.unsubscribe();
  }

}
