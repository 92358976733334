import {Models} from "@rematch/core"
import {default as environment} from 'redux/models/environment'
import {default as clipboard} from 'redux/models/clipboard'
import {default as appEnvironment} from 'redux/models/appEnvironment'
import {default as user} from 'redux/models/user'
import {default as workspace} from 'redux/models/workspace'
import {default as exports} from 'redux/models/export'
import {default as exportDestination} from 'redux/models/exportDestination'
import {default as periods} from 'redux/models/period'
import {default as personalDashboards} from 'redux/models/personalDashboards'
import {default as currentDashboard} from 'redux/models/currentDashboard'
import {default as lastEditedDashboard} from "redux/models/lastEditedDashboard"

export default interface RootModel extends Models<RootModel> {
  environment: typeof environment
  appEnvironment: typeof appEnvironment
  clipboard: typeof clipboard
  workspace: typeof workspace
  user: typeof user
  currentDashboard: typeof currentDashboard
  exports: typeof exports
  exportDestination: typeof exportDestination
  periods: typeof periods
  personalDashboards: typeof personalDashboards
  lastEditedDashboard: typeof lastEditedDashboard
}

export const models: RootModel = {
  environment,
  clipboard,
  workspace,
  user,
  appEnvironment,
  currentDashboard,
  exports,
  exportDestination,
  periods,
  personalDashboards,
  lastEditedDashboard,
}
