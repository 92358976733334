import {createSelector} from "reselect"
import {RootState} from "redux/store"

export const getLastEditedDashboard = createSelector(
  (state: RootState) => state.lastEditedDashboard,
  (lastEditedDashboard) => {
    if (lastEditedDashboard.uid && lastEditedDashboard.environmentId) {
      return lastEditedDashboard as {
        uid: string
        environmentId: number
      }
    }
    return undefined
  },
)

