import styled from "styled-components"
import {CardContentContainer} from "themes/SharedStyledComponent"

export const DataSourceDocTitle = styled.div`
  font-size: 20px;
  padding-bottom: 4px;
`

export const CardContentContainerWithLimitedHeight = styled(CardContentContainer)`
  max-height: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`

export const TableContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
`

export const SizeReference = styled.div`
  width: 100%;
  height: 100%;
  max-height: 100%;
  min-height: 0;
`