// @ts-nocheck   no check as this file should be deleted
import React, {memo} from 'react'
import PropTypes from 'prop-types'
import {IconAnimated} from "@biron-data/react-components"
import styled from "styled-components"

interface SimpleToolTipDOMProps {
  label: string,
  value: any,
  extra: string | null,
  onCopy: () => void,
}

const SimpleTooltipDOM = memo<SimpleToolTipDOMProps>(function SimpleToolTipDOM({label, value, extra, onCopy}) {
  return <table>
    <tbody>
    <TooltipSelected>
      {(label && <TooltipLabel>{label}</TooltipLabel>) || null}
      <TooltipLabel>{value}</TooltipLabel>
      {(extra && <TooltipExtra>{extra}</TooltipExtra>) || null}
      {(onCopy && <TooltipCopy><IconAnimated onClick={onCopy}/></TooltipCopy>) || null}
    </TooltipSelected>
    </tbody>
  </table>
})

SimpleTooltipDOM.propTypes = {
  label: PropTypes.string,
  value: PropTypes.any,
  extra: PropTypes.string,
  onCopy: PropTypes.func,
}

export default SimpleTooltipDOM

const TooltipSelected = styled.tr`
  font-weight: 600;
`

const TooltipLabel = styled.td`
  padding-right: 10px;
`

const TooltipExtra = styled.td`
  padding-left: 10px;
`

const TooltipCopy = styled.td`
  .anticon:hover {
    color: black;
  }
  cursor: pointer;
`
