import {SerieType} from "components/charts/line/LineChart.types"
import {isEmpty} from "@biron-data/react-components"

export const THEME = 'biron'
export const LEGEND_ID = 'legendId'
export const ITEMS_PER_TOOLTIP_COLUMN = 15
export const LINE_CHART_LEGEND_THRESHOLD = 900 // After this width, we display legend on the side
export const HIDE_SYMBOLS_THRESHOLD = 60 // After this amount of data point in a serie we hide symbol
export const FONT_SIZE_PX = 12 // Font size in tooltips
export const OPACITY = 1 // Default opacity
export const OPACITY_EMPHASIS = 1 // Opacity when serie selected / hover
export const OPACITY_BLUR = 0.3 // Opacity when serie semi-hidden
export const DISTANCE_BETWEEN_CHART_TOP_AND_LEGEND = 25 // Distance between the top of the chart and the legend when label are shown
export const CHART_SUMMARY_HEIGHT = 27 // Height of the chart summary
export const CHART_PADDING_X = 20
export const CHART_PADDING_Y = 14
export const DIVIDER_BORDER_TOP = 8


export function computeTotalAcrossSeries(series: Pick<SerieType, "values">[]) {
  return series.reduce((acc, serie) => {
    acc += (serie.values as any[]).reduce((accValues, value) => (accValues ?? 0) + (value ?? 0), 0) ?? 0
    return acc
  }, 0)
}

export function preventInfinite(value: number | undefined, min: number, max: number) {
  if (value === -Infinity) {
    return min
  }
  if (value === Infinity) {
    return max
  }
  return value
}

export const seriesToNonInfiniteValues = (series: Pick<SerieType, "values">[]): number[] => series
  .flatMap((serie) => serie.values.filter(v => !isEmpty(v)) as number[])
  .filter((v) => v < Infinity && v > -Infinity)
