import API from './api'
import {RawExpandedWorkspace, WorkspaceDtoForm} from "../redux/models/workspace";

export const createWorkspace = (data: WorkspaceDtoForm, environmentId: number) => API.instanceData.post<void, RawExpandedWorkspace>(`/workspace`, data, {
  params: {
    environmentId,
  },
})

export const updateWorkspace = (data: WorkspaceDtoForm, workspaceId: number) => API.instanceData.put<void, RawExpandedWorkspace>(`/workspace/${workspaceId}`, data)


export const deleteWorkspace = (workspaceId: number) => API.instanceData.delete<void, number>(`/workspace/${workspaceId}`)