import {CardContentContainer, CardEffectDiv} from "themes/SharedStyledComponent"
import React, {memo, useCallback} from "react"
import {Col, Row} from "antd"
import styled from "styled-components"
import {MdView, TableLink} from "@biron-data/react-components"
import {DataSourceObjectType, Link} from "components/dataSourceDoc/DataSource.types"
import {isEmpty} from "lodash"
import Language from "language"
import {DataSourceDocTitle} from "components/dataSourceDoc/DataSource.style"

interface InformationsProps {
  id: string
  description: string
  links?: Link[]
  suffix?: string
  decimales?: number
  asRatio?: boolean
}

// eslint-disable-next-line react/display-name
const DetailsInformations = memo<InformationsProps>(({suffix, decimales, asRatio, description, links, id}) => {
  const getVueLink = useCallback((code: string) => {
    return `${location.pathname.substring(0, location.pathname.indexOf("help"))}help/${DataSourceObjectType.VIEW}/${code}`
  }, [])

  return <CardEffectDiv>
    <CardContentContainer>
      <FlexContainer>
        <Row>
          <DataSourceDocTitle>
            <span>{Language.get("dataDoc.details.informations.title")}</span>
          </DataSourceDocTitle>
        </Row>
        <Row>
          <CategoriesContainer span={5}>
            <Categories><span>{Language.get("dataDoc.details.informations.description")}&nbsp;:</span></Categories>
          </CategoriesContainer>
          <Col span={19}>
            <Content>
              <span><MdView description={description} padding={0}/></span>
            </Content>
          </Col>
        </Row>
        {!isEmpty(suffix) && <Row>
          <CategoriesContainer span={5}>
            <Categories><span>{Language.get("dataDoc.details.informations.suffix")}&nbsp;:</span></Categories>
          </CategoriesContainer>
          <Col span={19}>
            <Content>
              <span>{suffix}</span>
            </Content>
          </Col>
        </Row>}
        {!isEmpty(decimales) && <Row>
          <CategoriesContainer span={5}>
            <Categories>
              <span>{Language.get("dataDoc.details.informations.decimales")}&nbsp;:</span>
            </Categories>
          </CategoriesContainer>
          <Col span={19}>
            <Content>
              <span>{decimales}</span>
            </Content>
          </Col>
        </Row>}
        {!isEmpty(asRatio) && <Row>
          <CategoriesContainer span={5}>
            <Categories>
              <span>{Language.get("dataDoc.details.informations.asRatio")}&nbsp;:</span>
            </Categories>
          </CategoriesContainer>
          <Col span={19}>
            <Content>
              <span>{asRatio}</span>
            </Content>
          </Col>
        </Row>}
        {links && links.length > 0 ? <Row>
          <CategoriesContainer span={5}><Categories>
            <div>
              <ColorizedSpan>
                <span>{Language.get("dataDoc.details.informations.linkedViews")}</span>
              </ColorizedSpan>
              &nbsp;:
            </div>
          </Categories></CategoriesContainer>
          <Col span={19}>
            <Content>
              <SeamlessUl>
                {links?.map(link => <li key={link.code}>{
                  link.code ? <TableLink link={getVueLink(link.code)}>
                    {link.alias}
                  </TableLink> : undefined
                }</li>)}
              </SeamlessUl>
            </Content>
          </Col>
        </Row> : <></>}
        <Row>
          <CategoriesContainer span={5}>
            <Categories>
              <span>{Language.get("dataDoc.details.informations.idNexusQL")}&nbsp;:</span>
            </Categories>
          </CategoriesContainer>
          <Col span={19}>
            <Content>
              <span>{id}</span>
            </Content>
          </Col>
        </Row>
      </FlexContainer>
    </CardContentContainer>
  </CardEffectDiv>
})

export default DetailsInformations

const CategoriesContainer = styled(Col)`
  display: flex;
`

const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`

const Content = styled.div`
  padding: 0 0 0 10px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  line-height: 20px;

  * {
    display: block;
  }
`

const ColorizedSpan = styled.span`
  background-color: #a0ecc8;
  border-radius: 6px;
  padding: 2px 4px;
`
const Categories = styled.div`
  font-weight: 500;
  /* height: 100%; */
  line-height: 20px;
  display: inline-block;
  /* overflow-x: hidden; */
  text-overflow: ellipsis;
  width: calc(100%);
  white-space: nowrap;
`

const SeamlessUl = styled.ul`
  list-style-type: none;
  padding: 0;
  max-height: calc(40px * 5);
  overflow-y: scroll;
  margin: 0;


  li:not(:last-child) {
    border-bottom: 1px solid var(--border-color-base);
  }

  li:not(:last-child):not(:first-child) {
    padding: 12px 0;
  }

  li:first-child {
    padding: 0 0 12px 0;
  }

  li:last-child {
    padding: 12px 0 0 0;
  }

  li:only-child {
    padding: 0;
  }
`