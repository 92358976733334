import React, {memo} from 'react'
import {Route, Routes} from 'react-router-dom'
import NoAuthorization from "NoAuthorization"
import {useSelector} from "react-redux"
import {isInitialized} from "redux/workspace.selector"
import {Loader} from "@biron-data/react-components"
import WorkspaceBridge from "components/workspace/WorkspaceBridge"

// eslint-disable-next-line react/display-name
const Home = memo(() => {
  const workspaceInitialized = useSelector(isInitialized)

  return workspaceInitialized
    ? <Routes>
      <Route path="*" element={<WorkspaceBridge/>}/>
      <Route path=":workspace/*" element={<WorkspaceBridge/>}/>
      <Route path="nav/noAuthorization" element={<NoAuthorization/>}/>
    </Routes>
    : <Loader/>
})

export default Home
