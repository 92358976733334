import {captureError} from 'services/SentryService'
import {get as _get} from 'lodash'
import * as d3 from "./d3_fr_FR"
import * as FRLanguage from './language_fr'
import * as ENLanguage from './language_en'

let lang = {}
let langLabel: string
export type Formatter = { format: (format: any) => (value: any) => any }
let formatter: Formatter

const set = (originalLanguage: string) => {
  const language = originalLanguage.substr(0, 2)

  if (language.toLowerCase() === 'fr') {
    FRLanguage.configure()
    lang = FRLanguage.default
  } else {
    ENLanguage.configure()
    lang = ENLanguage.default
  }

  langLabel = language
  formatter = d3.formatter// eslint-disable-line
}
set('en_US')

const getTranslatedName = (obj: { nameFR: string, nameEN: string }) => langLabel === "en" ? obj.nameEN : obj.nameFR

const get = (key: string, args?: any): string => {
  const val: any = _get(lang, key)
  if (val) {
    return typeof (val) === 'function' ? val(args) : val
  } else {
    captureError('Wording - text not found', {
      key,
      lang: langLabel,
    })
    return key
  }
}

const getFormatter = () => formatter

const getLabel = () => langLabel.toLowerCase()

export default {
  set,
  get,
  getFormatter,
  getTranslatedName,
  getLabel,
}
type DiffReason = {
  type: 'missing',
  lang: string,
} | {
  type: 'position',
  pos: {
    [x: string]: string | number | boolean
  },
} | {
  type: 'type',
  pos: {
    [x: string]: string | number | boolean
  },
}

type Diff = { [key: string]: DiffReason }

type DictVal = ((() => string))
type Dict = { [key: string]: DictVal | string }

const checkSources = () => {
  const en = require('./language_en.ts').default// eslint-disable-line
  const fr = require('./language_fr.ts').default// eslint-disable-line
  const diffs: Diff = {}

  const diffLang = (rootPath: string, dict1: Dict, lang1: string, dict2: Dict, lang2: string, missingOnly = false) => {
    const keys1 = Object.keys(dict1)
    const keys2 = Object.keys(dict2)
    for (let keyIndex = 0; keyIndex < keys1.length; keyIndex++) {
      const key = keys1[keyIndex]
      const keyIndex2 = keys2.indexOf(key)
      const absoluteKeyPath = rootPath ? `${rootPath}.${key}` : key
      if (keyIndex2 === -1) {
        diffs[absoluteKeyPath] = {
          type: 'missing',
          lang: lang2,
        }
      } else {
        if (keyIndex2 === keyIndex) {
          const val1 = dict1[key]
          const val2 = dict2[key]
          const typeVal1 = typeof val1
          const typeVal2 = typeof val2
          if (typeVal1 === typeVal2) {
            if (typeof val1 === 'object' && typeof val2 === 'object') {
              diffLang(absoluteKeyPath, val1, lang1, val2, lang2)
            }
          } else if (!missingOnly) {
            diffs[absoluteKeyPath] = {
              type: 'type',
              pos: {
                [lang1]: typeVal1,
                [lang2]: typeVal2,
              },
            }
          }
        } else if (!missingOnly) {
          diffs[absoluteKeyPath] = {
            type: 'position',
            pos: {
              [lang1]: keyIndex,
              [lang2]: keyIndex2,
            },
          }
        }
      }
    }
  }

  const printDiff = (d: Diff) => {
    const keysDiffs = Object.entries(d)
    if (keysDiffs.length > 0) {
      const differ: any = (result: DiffReason) => {
        switch (result.type) {
          case "missing":
            return `is missing for language ${result.lang}`
          case "position":
            return `has 2 different positions : ${Object.entries(result.pos).map(([l, index]) => `${l}[${index}]`).join(',')}`
          case 'type':
            return `has 2 different value type : ${Object.entries(result.pos).map(([l, index]) => `${l}:instanceof(${index})`).join(',')}`
          default: {
            // eslint-disable-next-line no-case-declarations
            const exhaustiveCheck: never = result
            return exhaustiveCheck
          }
        }
      }
      // eslint-disable-next-line no-console
      console.error(`problems found in languages :\n${keysDiffs.map(([key, info]) => {
        return `text [${key}] ${differ(info)}`
      }).join('\n')}`)
    }
  }

  diffLang('', en, 'en_US', fr, 'fr_FR');
  diffLang('', fr, 'fr_FR', en, 'en_US', true);
  printDiff(diffs);
}
if (import.meta.env.NODE_ENV === 'development') {
  checkSources()
}