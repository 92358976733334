import React, {memo, useCallback} from 'react'
import Language from "language"
import styled from "styled-components"

interface TitleSelectorProps {
  onChange?: (newValue: string) => void
  value?: string
}

const TitleSelector = memo<TitleSelectorProps>(function TitleSelector({value, onChange}) {
  const handleChange: React.ChangeEventHandler<HTMLInputElement> = useCallback((event) => {
    onChange?.(event.target.value)
  }, [onChange])

  return <StyledInput value={value} placeholder={Language.get("new-chart-title")} onChange={handleChange}/>
})

export default TitleSelector

const StyledInput = styled.input`
  font-size: 20px;
`
