/* eslint-disable max-lines */
import React, {forwardRef, memo, useCallback, useMemo, useRef, useState} from "react"
import {Col, Empty, Form, notification, Result, Row} from 'antd'
import {useFields, useResizeDetector} from "@biron-data/react-hooks"
import {ConsolidatedFormProps} from "components/forms/Form.Modal"
import styled from "styled-components"
import {extractSlicerDate, getDimensionOption, ConfFilter, PeriodSelector, GenericChartTypes, SortType, MetricSelectorValue} from "@biron-data/react-bqconf"
import {CHART_PADDING_X} from "components/charts/Chart.constants"
import {useChartTypes} from "components/forms/chart/useChartTypes"
import {useFixLimits, useFixOptions, useFixOrderBys, useFixSlicers} from "components/forms/chart/useFixHooks"
import {useGroupedSortOptions} from "./useDependenciesHooks"
import {ConfigCache, GenericExtendedConfModel, LimitType, SimplifiedChartGenericFormProps} from "components/forms/chart/types"
import {useIsChartFormatDisplayed} from "components/forms/chart/hooks"
import {WidgetTypes} from "commons/dashboard/dashboard.types"
import {ChartFormatField, ChartFormatWrapper} from ".././confItems/ChartFormat"
import {ChartPreviewField} from "components/forms/confItems/ChartPreview"
import {PeriodWrapper} from ".././confItems/Period"
import {ViewField, ViewWrapper} from "components/forms/confItems/View"
import {MetricsWrapper} from ".././confItems/Metrics"
import {MetricsField} from "components/forms/confItems/Metrics"
import {SlicersField, SlicersWrapper} from "components/forms/confItems/Slicers"
import {DimensionFiltersField, DimensionFiltersWrapper} from ".././confItems/DimensionFilters"
import {MetricFiltersField, MetricFiltersWrapper} from ".././confItems/MetricFilters"
import {OrderBysField, OrderBysWrapper} from "components/forms/confItems/OrderBys"
import {LimitsField, LimitsWrapper} from "components/forms/confItems/Limits"
import {ChartTypeField, ChartTypeWrapper} from "components/forms/confItems/ChartType"
import {OptionsWrapper} from ".././confItems/Options"
import * as Sentry from "@sentry/react"
import {createCache} from "components/forms/chart/cache"
import {State} from "redux/models/clipboard"
import useDispatch from "hooks/useDispatch"
import {useLanguageResolver} from "@biron-data/react-contexts"
import {AdditionalDetailField, AdditionalDetailWrapper, ErrorBoundaryResultContainer} from "@biron-data/react-components"
import {getCopiedMetric} from "redux/clipboard.selector"
import {useSelector} from "react-redux"
import {useDataDocMetricLink} from "components/dataSourceDoc/DataSource.hooks"
import {useLoadPeriods} from "hooks/useLoadPeriods"
import {useOnValuesChange} from "components/forms/chart/FormComponentGeneric.hooks"

type Props = SimplifiedChartGenericFormProps & ConsolidatedFormProps<GenericExtendedConfModel>

// eslint-disable-next-line react/display-name
const FormComponentGeneric = memo<Props>(forwardRef<any, Props>(({
                                                               onValuesChange,
                                                               uniqueViewOptions,
                                                               metricInvertible,
                                                               data,
                                                               errors,
                                                               additionalDetails,
                                                               dashboardSelection,
                                                               metaModel,
                                                               dashboardId,
                                                               getPopupContainer,
                                                               environmentId,
                                                               viewsWithMetrics,
                                                               availableDimensions,
                                                               unavailableDimensions,
                                                               unavailableViews,
                                                                loadDictionaryEntries
                                                             }: Props, ref) => {
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const languageResolver = useLanguageResolver()
  const fields = useFields(data, errors)
  const displayType = useRef<GenericChartTypes | undefined>(data.displayType)
  const slicerHasBeenModified = useRef<boolean>(data.id !== -1)

  const dimensionOption = useMemo(() => getDimensionOption(availableDimensions, unavailableDimensions), [availableDimensions, unavailableDimensions])

  const [cache, setCache] = useState<ConfigCache>(createCache(metaModel, viewsWithMetrics, dimensionOption, data.metrics, data.slicers, data.metricFilters, data.orderBys, data.limits, displayType.current, data.displayLabels, data.ignoreMetrics0, data.asPercentage))

  const consolidatedCache = useMemo(() => {
    const newCache = createCache(metaModel, viewsWithMetrics, dimensionOption, data.metrics, data.slicers, data.metricFilters, data.orderBys, data.limits, displayType.current, data.displayLabels, data.ignoreMetrics0, data.asPercentage)

    // Slicers and metric growth can be added and reordered automatically, so we need to consolidate cache slicer list in order to have the correct order and slicer all the time
    return {
      ...cache,
      slicers: newCache.slicers.map(slicer => cache.slicers.find(s => s.id === slicer.id) ?? slicer),
      metrics: newCache.metrics.map((metric, i) => ({
        ...cache.metrics[i],
        growth: metric.growth,
        growthInvert: metric.growthInvert,
        format: metric.format,
      })),
    }
  }, [metaModel, viewsWithMetrics, dimensionOption, data.metrics, data.slicers, data.metricFilters, data.orderBys, data.limits, data.displayLabels, data.ignoreMetrics0, data.asPercentage, cache])
  const groupedOptions = useGroupedSortOptions(cache, viewsWithMetrics, dimensionOption)

  const chartTypes = useChartTypes(data.type, data.displayType, consolidatedCache)

  const currentChartConfiguration = useMemo(() => chartTypes.find(c => c.type === data.displayType || c.type === WidgetTypes.EXPORT), [chartTypes, data.displayType])
  const currentConfiguration = useMemo(() => currentChartConfiguration?.format && currentChartConfiguration.format.length > 0 ? currentChartConfiguration?.format?.find(f => f.type === data.format) ?? currentChartConfiguration?.format[0] : currentChartConfiguration, [currentChartConfiguration, data.format])
  const limitConfiguration = useMemo(() => currentConfiguration && currentChartConfiguration && (currentConfiguration.limits ?? currentChartConfiguration.limits), [currentChartConfiguration, currentConfiguration])
  const availableLimitConfiguration = useMemo(() => {
    const limitConfs = limitConfiguration ? limitConfiguration.filter(conf => Object.values(conf.disablingReasons).filter(reason => reason).length === 0) : []
    return limitConfs.length > 0 && limitConfs[0].type === LimitType.SIMPLE ? [limitConfs[0]] : limitConfs.slice(0, 2)
  }, [limitConfiguration])
  const availableSortConfiguration = useMemo(() => {
    const sortConfs = currentConfiguration && currentChartConfiguration && (currentConfiguration.orderBys ?? currentChartConfiguration.orderBys)
    if (!sortConfs) {
      return []
    }
    const availableConfiguration = sortConfs.filter(conf => Object.values(conf.disablingReasons).filter(reason => reason).length === 0)
    return availableConfiguration.length > 0 && availableConfiguration[0].type === SortType.CLASSIC ? [availableConfiguration[0]] : availableConfiguration.slice(0, 2)
  }, [currentChartConfiguration, currentConfiguration])

  useFixOrderBys(cache, onValuesChange, data.metrics, data.slicers, viewsWithMetrics, groupedOptions, data.orderBys, availableSortConfiguration, data.displayType)
  useFixLimits(cache, onValuesChange, availableLimitConfiguration, data.metrics, data.slicers, data.limits, data.displayType)
  useFixSlicers(cache, data.slicers, onValuesChange, slicerHasBeenModified.current, data.dateSlicerGranularity, currentChartConfiguration?.defaultSlicer, data.displayType)
  useFixOptions(cache, data.displayLabels, data.ignoreMetrics0, data.asPercentage, data.displayType, onValuesChange)

  const isChartFormatDisplayed = useIsChartFormatDisplayed(chartTypes)
  const isLimitInputDisplayed = useMemo(() => availableLimitConfiguration
      && availableLimitConfiguration.length > 0
      && availableLimitConfiguration.find(conf => conf.default?.enabled) && (data.metrics.length > 0 || data.slicers.length > 0)
    , [availableLimitConfiguration, data.metrics.length, data.slicers.length])

  const isSortInputDisplayed = useMemo(() => availableSortConfiguration
      && availableSortConfiguration.length > 0
      && availableSortConfiguration.find(conf => conf.editable) && (data.metrics.length > 0 || data.slicers.length > 0)
    , [data.metrics.length, data.slicers.length, availableSortConfiguration])

  const isFormatSelectorDisplayed = useMemo(() => currentChartConfiguration && currentChartConfiguration.format && currentChartConfiguration.format.length > 0
    , [currentChartConfiguration])

  const defaultFormat = useMemo(() => currentChartConfiguration?.format?.find(({disabledReasons}) => disabledReasons.length === 0)?.type, [currentChartConfiguration])


  const slicerHasBeenModifiedStateHandler = useCallback((state: boolean) => {
    slicerHasBeenModified.current = state
  }, [])

  const displayTypeHandler = useCallback((newType: GenericChartTypes | undefined) => {
    displayType.current = newType
  }, [])

  const handleOnValuesChange = useOnValuesChange(
    slicerHasBeenModifiedStateHandler,
    displayTypeHandler,
    data,
    setCache,
    onValuesChange,
    metaModel,
    viewsWithMetrics,
    dimensionOption,
    displayType.current
  )

  const previewRef = useRef<any>()
  const [previewDimension, setPreviewDimension] = useState({
    width: 0,
    height: 0,
  })

  useResizeDetector(previewRef, previewDimension, (newWidth, newHeight) => {
    setPreviewDimension({
      width: newWidth,
      height: newHeight,
    })
  })

  const onCopy = useCallback((metric: MetricSelectorValue) => {
    notification.info({
      duration: 2.5,
      key: 'copied-metric',
      message: languageResolver.get(`configuration-metric-options.copied-metric`),
      description: languageResolver.get(`configuration-metric-options.copied-metric-description`),
      placement: 'bottomRight',
    })
    dispatch.clipboard.copyMetric({metric} as State)
  }, [dispatch.clipboard, languageResolver])

  const copiedMetric = useSelector(getCopiedMetric())
  const getDatadocLink = useDataDocMetricLink()
  const loadPeriods = useLoadPeriods()

  return <Form ref={ref} form={form} fields={fields} name={'editChartGeneric'} onValuesChange={handleOnValuesChange}>
    <Row>
      <Col span={14}>
        <ViewWrapper name={'uniqueView'}>
          <ViewField {...{
            name: 'uniqueView',
            options: uniqueViewOptions,
            getPopupContainer,
          }}/>
        </ViewWrapper>
        <MetricsWrapper name={'metrics'} rules={[{required: true, message: "Required field"}]}>
          <MetricsField {...{
            environmentId,
            multiple: true,
            groupMetricsByView: !data.uniqueView,
            viewsWithMetrics,
            unavailableViews,
            invertible: metricInvertible,
            configuration: currentChartConfiguration,
            numberOfSlicer: data.slicers.length,
            getPopupContainer,
            metaModel,
            displayType: data.displayType,
            format: data.format,
            dashboardSelection,
            period: data.period,
            onCopy,
            copiedMetric,
            getMoreInformationLink: getDatadocLink,
            loadDictionaryEntries,
            loadPeriods
          }}/>
        </MetricsWrapper>
        <SlicersWrapper name={'slicers'}>
          <SlicersField {...{
            form,
            multiple: true,
            availableDimensions,
            unavailableDimensions,
            configuration: currentChartConfiguration,
            getPopupContainer,
            displayType: data.displayType,
          }}/>
        </SlicersWrapper>
        <DimensionFiltersWrapper name={'filters'} rules={[({getFieldValue}: { getFieldValue: any }) => ({
          validator() {
            if (getFieldValue("filters").filter((filter: ConfFilter) => !filter.isValid).length > 0) {
              return Promise.reject(new Error())
            }
            return Promise.resolve()
          },
        })]}>
          <DimensionFiltersField {...{
            availableDimensions,
            getPopupContainer,
            GACategory: 'charts',
            loadDictionaryEntries
          }}/>
        </DimensionFiltersWrapper>
        {currentChartConfiguration?.isMetricsFilterEnabled && <MetricFiltersWrapper
          name={'metricFilters'}
          rules={[({getFieldValue}: { getFieldValue: any }) => ({
            validator() {
              if (getFieldValue("metricFilters").filter((filter: ConfFilter) => !filter.isValid).length > 0) {
                return Promise.reject(new Error())
              }
              return Promise.resolve()
            },
          })]}>
          <MetricFiltersField {...{
              viewsWithMetrics,
              metrics: data.metrics,
              getPopupContainer,
              metaModel,
            environmentId,
              GACategory: 'charts',
            }}/>
        </MetricFiltersWrapper>}
        {isSortInputDisplayed && <OrderBysWrapper name={'orderBys'}>
          <OrderBysField {...{
            slicers: data.slicers,
            configurations: availableSortConfiguration,
            availableDimensions,
            getPopupContainer,
            groupedOptions,
          }}/>
        </OrderBysWrapper>}
        {isLimitInputDisplayed && <LimitsWrapper name={'limits'}>
          <LimitsField {...{
            displayType: data.displayType,
            configurations: availableLimitConfiguration,
            getPopupContainer,
          }}/>
        </LimitsWrapper>}
        {additionalDetails.length > 0 && data.metrics.length > 0 && <OptionsWrapper name={"options"}>
          {(additionalDetails).map((additionalDetail) =>
            <AdditionalDetailWrapper key={additionalDetail.textKey} additionalDetail={additionalDetail}>
              <AdditionalDetailField {...{
                additionalDetail,
              }}/>
            </AdditionalDetailWrapper>,
          )}
        </OptionsWrapper>
        }
      </Col>
      <RightCol span={10}>
        <FlexContainer>
          {isChartFormatDisplayed && <ChartTypeWrapper name={'displayType'}>
            <ChartTypeField {...{
              name: 'displayType',
              chartTypes,
            }}/>
          </ChartTypeWrapper>}
          <PeriodWrapper name={'period'}>
            <PeriodSelector {...{
              name: 'period',
              forTarget: false,
              form,
              withOverrideOption: !!(dashboardSelection && dashboardId),
              withDateSlicerGranularity: Boolean(extractSlicerDate(data.slicers)),
              getPopupContainer,
              environmentId,
              loadPeriods
            }}/>
          </PeriodWrapper>
          <PreviewContainer ref={previewRef} $displaytype={data.displayType}
                            $formatconfiguration={isFormatSelectorDisplayed ? 1 : 0}>
            <Sentry.ErrorBoundary fallback={<ErrorBoundaryResultContainer>
              <Result
                status={"error"}
                title={languageResolver.get("error-occurred")}/>
            </ErrorBoundaryResultContainer>}>
            {data.metrics.length > 0 && previewDimension && previewDimension.width && previewDimension.height ? <ChartPreviewField {...{
                metaModel,
                dashboardId, 
                dashboardSelection,
                dimensions: {
                  width: previewDimension.width - (CHART_PADDING_X * 3),
                  height: previewDimension.height - 53,
                },
                data,
                displayType: data.displayType,
                format: data.format,
                viewsWithMetrics,
                availableDimensions,
                isMultiView: !data.uniqueView,
              }}/>
              : <Empty description={<span>{languageResolver.get("configuration-preview-insufficient-configuration")}</span>}/>}
            </Sentry.ErrorBoundary>
          </PreviewContainer>
          {isFormatSelectorDisplayed && currentChartConfiguration?.format
            && <ChartFormatWrapper name={'format'}>
              <ChartFormatField {...{
                configuration: currentChartConfiguration.format,
                defaultFormat,
              }}/>
            </ChartFormatWrapper>}
        </FlexContainer>
      </RightCol>
    </Row>
  </Form>
}))

export default FormComponentGeneric

const RightCol = styled(Col)`
  border-left: 1px solid var(--main-background-color);
  min-height: 100%;
  & > .ant-row {
    padding-left: 24px;
  }
`

const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
  `

const PreviewContainer = styled.div<{
  $displaytype: GenericChartTypes | undefined,
  $formatconfiguration: number
}>`
  width: 100%;
  height: ${({$formatconfiguration}) => {
  if ($formatconfiguration) {
    return "400"
  } else {
    return "450"
  }
}}px;
  padding: 15px 10px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`