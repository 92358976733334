import React, {forwardRef, useImperativeHandle, useRef} from 'react'
import {WidgetTypes} from "commons/dashboard/dashboard.types"
import BoxChart from 'components/charts/box/BoxChart'
import AreaChart from 'components/charts/area/AreaChart'
import LineChart from 'components/charts/line/LineChart'
import BarChart from 'components/charts/bar/BarChart'
import PieChart from 'components/charts/pie/PieChart'
import TargetChart from 'components/charts/target/TargetChart'
import TableChart from 'components/charts/table/TableChart'
import {ChartGenericDtoDetail, ShapeDimension} from "types/charts"
import {
  ChartSelection,
  TableQueryWorkflowResult,
  TargetQueryWorkflowResult,
  WorkflowResultTypes,
} from "classes/workflows/query-workflows/QueryWorkflow"
import {ChartWithMetricDefLayoutTypes} from "components/widgetContainer/WidgetContainer"
import ScatterChart from "components/charts/scatter/ScatterChart"
import {WCBodyRef, MdView} from "@biron-data/react-components"
import {GenericChartTypes} from "@biron-data/react-bqconf"

interface Props {
  chart: ChartWithMetricDefLayoutTypes
  data: WorkflowResultTypes
  selection: ChartSelection
  dimensions: ShapeDimension
  onSelectionChange: (newSelection: ChartSelection) => void
  withSummary: boolean
}


export default forwardRef<WCBodyRef, Props>(function WCBody({
                                                              chart,
                                                              data,
                                                              selection,
                                                              dimensions,
                                                              onSelectionChange,
                                                              withSummary,
                                                            }, ref) {
  const refCmp = useRef<WCBodyRef>(null)
  useImperativeHandle(ref, () => ({
    getDynamicHeight: () => refCmp.current?.getDynamicHeight?.(),
    getDataUrl: () => refCmp.current?.getDataUrl?.(),
  }), [refCmp])
  switch (chart.type) {
    case WidgetTypes.GENERIC:
      switch (chart.extraConf.displayType) {
        case GenericChartTypes.BOXES:
          return <BoxChart {...{
            withSummary,
            chartData: data as TableQueryWorkflowResult,
            dimensions,
            metrics: (chart as ChartGenericDtoDetail).metrics,
          }}/>
        case GenericChartTypes.TABLES:
          return <TableChart {...{
            withSummary,
            chartData: data as TableQueryWorkflowResult,
            dimensions,
            selection,
            onSelectionChange,
          }}/>
        default: {
          switch (data.type) {
            case "generic": {
              if (chart.extraConf.displayType === GenericChartTypes.AREA) {
                return <AreaChart ref={refCmp} {...{
                  withSummary,
                  chartData: data,
                  dimensions,
                  selection,
                }}/>
              } else if (chart.extraConf.displayType === GenericChartTypes.LINE) {
                return <LineChart ref={refCmp} {...{
                  withSummary,
                  chartData: data,
                  dimensions,
                  selection,
                }}/>
              } else if (chart.extraConf.displayType === GenericChartTypes.BARS) {
                return <BarChart ref={refCmp} {...{
                  withSummary,
                  chartData: data,
                  dimensions,
                  selection,
                }}/>
              } else if (chart.extraConf.displayType === GenericChartTypes.SCATTER) {
                return <ScatterChart ref={refCmp} {...{
                  withSummary,
                  chartData: data,
                  dimensions,
                  selection,
                  chartConfig: chart,
                }}/>
              } else if (chart.extraConf.displayType === GenericChartTypes.PIE) {
                return <PieChart ref={refCmp} {...{
                  withSummary,
                  chartData: data,
                  dimensions,
                  selection,
                }}/>
              }
              return null
            }
            default:
              return null
          }
        }
      }
    case WidgetTypes.TARGET:
      return <TargetChart {...{
        withSummary,
        chartData: data as TargetQueryWorkflowResult,
        dimensions,
      }}/>
    case WidgetTypes.DIVIDER:
      return <MdView ref={refCmp} {...{
        description: chart.extraConf.description,
      }}/>
    default:
      return null
  }
})
