import React, {FunctionComponent, useCallback, useState} from "react"
import {Input, Modal} from "antd";
import {IconContainer, MdView} from "@biron-data/react-components";
import Language from "../../language";
import {TrashIcon} from "@heroicons/react/outline";
import styled from "styled-components";

interface WorkspaceManagerDeleteProps {
    onDelete: () => void
    workspaceName: string
}
const WorkspaceManagerDelete: FunctionComponent<WorkspaceManagerDeleteProps> = ({
                                                                        onDelete,
                                                                        workspaceName
                                                                    }) => {
    const [deletionConfirmation, setDeletionConfirmation] = useState<string>()
    const [status, setStatus] = useState<'error' | 'warning'>()
    const [open, setOpen] = useState<boolean>()

    const confirm = useCallback(() => {
        if (deletionConfirmation?.trim() === workspaceName.trim()) {
            onDelete()
            setDeletionConfirmation(undefined)
            setOpen(false)
        } else {
            setStatus('error')
        }
    }, [deletionConfirmation, onDelete, workspaceName])

    return <>
      <Modal {...{
        open,
        width: 600,
        maskClosable: false,
        closable: false,
        okButtonProps: {
          danger: true
        },
        onOk: confirm,
        okText: Language.get('button-yes'),
        cancelText: Language.get('button-no'),
        onCancel: () => setOpen(false),
        title: <Title>{Language.get("workspaces.delete.title")}</Title>
      }}>
        <FlexContent>
          <MdView description={Language.get("workspaces.delete.decription", workspaceName)} padding={0}/>
          <div>
            <div style={{fontWeight: 500}}>{Language.get("workspaces.delete.input-title")}</div>
            <Input placeholder={Language.get("workspaces.delete.input-placeholder")} status={status}
                   onChange={(val) => setDeletionConfirmation(val.target.value)}/>
          </div>
        </FlexContent>
      </Modal>
      <IconContainer clickable={true} onClick={() => setOpen(true)}><TrashIcon/></IconContainer>
    </>
}

export default WorkspaceManagerDelete

const Title = styled.div`
    padding: 20px;
`

const FlexContent = styled.div`
  display: flex;
    flex-direction: column;
    gap: 10px;
  `