import {getExportDestinationsByClient} from "services/ExportDestinationService"
import {deleteEntity, setEntities} from "redux/models/utils.model"
import {returnOrExecBatch} from 'commons/batch'
import {createModel} from "@rematch/core"
import RootModel from "redux/models/index"

interface ExportDestination {
  "id": number,
  "name": string,
  "code": string
}

const initialState: { entities: { [key: number]: ExportDestination } } = {
  entities: {},
}

export default createModel<RootModel>()({
  state: initialState,
  reducers: {
    setEntities,
    deleteEntity,
    'exportDestination/cleanStates': () => {
      return initialState
    },
  },

  effects: (dispatch) => {
    return {
      async loadExportDestination({forBatch = false, environment}: {
        forBatch?: boolean,
        environment: {
          id: number
        }
      }): Promise<{ type: string, payload: ExportDestination[] }> {
        const normalized = await getExportDestinationsByClient(environment.id)
        return returnOrExecBatch(forBatch, () => dispatch.exportDestination.setEntities(normalized))
      },
    }
  },
})

