import React, {FunctionComponent, useMemo, useRef, useState} from "react"
import {Row, TableColumnProps} from 'antd'
import Language from "language"
import {useLocation} from "react-router-dom"
import styled from "styled-components"
import {ChartSource, ObjectType, UsageTypes} from 'components/dataSourceDoc/DataSource.types'
import {Search} from "components/search/Search"
import {TableLink, ColorizedTag} from "@biron-data/react-components"
import DatasourceTable from "components/dataSourceDoc/DataSource.Table"
import {
  useGetSourceColor,
  usePercentageValueInPixels,
  useSearchOnValues,
  useStats,
  useTextWithHighLight,
} from "components/dataSourceDoc/DataSource.hooks"
import {getDashboardLink, getUsageLink} from "components/dataSourceDoc/DataSource.utils"
import {SizeReference, TableContainer} from "components/dataSourceDoc/DataSource.style"
import {useResizeDetector} from "@biron-data/react-hooks"

interface DetailsProps {
  usages: UsageTypes[]
  displaySourceColumn?: boolean
  sources: ChartSource[]
}

export const DetailsUsages: FunctionComponent<DetailsProps> = ({
                                                           usages,
                                                          displaySourceColumn = false,
                                                          sources,
                                                         }) => {
  const location = useLocation()
  const getSourceColor = useGetSourceColor()
  const [size, setSize] = useState({
    width: 0,
  })
  const [search, setSearch] = useState("")
  const [consolidatedData, setConsolidatedData] = useState(usages)

  useSearchOnValues(search, [['dashboard', 'title'], ['object', 'title']], usages, setConsolidatedData)

  const getTextWithHighLight = useTextWithHighLight(search)

  const getPercentageValueInPixels = usePercentageValueInPixels(600, size.width)

  const columnLayout = useMemo(() => {
    return displaySourceColumn ? [12,
      21,
      21,
      31,
      15] : [12,
      23,
      23,
      42]
  }, [displaySourceColumn])

  const usageColumns: TableColumnProps<UsageTypes>[] = useMemo(() => {

    return size.width ? [
      {
        title: Language.get(`dataDoc.columns.object`),
        dataIndex: ['object', 'type'],
        filters: Object.entries(ObjectType).map(([, type]) => ({
          text: Language.get("dataDoc.details.object", type),
          value: type,
        })),
        onFilter: (value, record) => {
          return record.object.type === value
        },
        width: getPercentageValueInPixels(columnLayout[0]),
        render: (text: ObjectType) => Language.get("dataDoc.details.object", text),
      },
      {
        title: Language.get(`dataDoc.columns.menuName`),
        dataIndex: ['menu', 'title'],
        render: (text?: string) => getTextWithHighLight(text ?? "-"),
        width: getPercentageValueInPixels(columnLayout[1]),
      },
      {
        title: Language.get(`dataDoc.columns.dashboardName`),
        dataIndex: ['dashboard', 'title'],
        render: function render(text: string | undefined, record) {
          return <TableLink link={getDashboardLink(record, location)} isTargetBlank={true}>{getTextWithHighLight(text ?? "-")}</TableLink>
        },
        width: getPercentageValueInPixels(columnLayout[2]),
      },
      {
        title: Language.get(`dataDoc.columns.objectName`),
        dataIndex: ['object', 'title'],
        render: function render(text: string, record) {
          return <TableLink link={getUsageLink(record, location)} isTargetBlank={true}>{getTextWithHighLight(text)}</TableLink>
        },
        defaultSortOrder: 'ascend',
        width: getPercentageValueInPixels(columnLayout[3]),
      },
      ...(displaySourceColumn ? [{
        title: Language.get(`dataDoc.columns.usage`),
        dataIndex: 'sources',
        className: 'col-with-centered-content',
        render: (_: string, record) => <ColorizedTag
          text={Language.get("dataDoc.details.sources", record.source)}
          color={getSourceColor(record.source)}
        />,
        filters: Object.entries(ChartSource).map(([, source]) => ({
          text: Language.get("dataDoc.details.sources", source),
          value: source,
        })),
        onFilter: (value, record) => {
          return record.source === value.toString()
        },
        width: getPercentageValueInPixels(columnLayout[4]),
      } as TableColumnProps<UsageTypes>] : []),
    ] : []
  }, [columnLayout, displaySourceColumn, getPercentageValueInPixels, getSourceColor, getTextWithHighLight, location, size.width])

  const stats = useStats(usages, sources)

  const container = useRef<any>()
  useResizeDetector(container, size, (newWidth) => {
    setSize({
      width: newWidth,
    })
  })

  const onSearch = (val: string) => {
    setSearch(val)
  }

  return <DetailsContainer>
    <StatsContainer>
      {stats}
    </StatsContainer>
    <StyledRow>
      <SizeReference ref={container}>
        {size.width && <TableContainer><Search
          width={45}
          onChange={onSearch}/>
          <DatasourceTable
            columns={usageColumns}
            dataSource={consolidatedData}
            rowHeight={34}
          />
        </TableContainer>
        }
      </SizeReference>
    </StyledRow>
  </DetailsContainer>
}

const DetailsContainer = styled.div`
  height: 100%;
  min-height: 0;
  display: flex;
  flex-direction: column;
`

const StatsContainer = styled.div`
  display: flex;
  gap: 80px;
`

const StyledRow = styled(Row)`
  min-height: 0;
  height: 100%;
`