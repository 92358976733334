import React from 'react'
import PropTypes from "prop-types"
import SimpleTooltipDOM from 'components/charts/tooltip/SimpleTooltip.DOM'
import {formatValue, formatValueForRatio} from "commons/format/formatter"
import copy from 'copy-to-clipboard'

class SimpleTooltip extends React.PureComponent<any, any> {
  render() {
    const {label, values, total, format, copyEnabled} = this.props;

    const value = values[0]
    const formattedValue = formatValue(value, format)
    const ratio = formatValueForRatio(value, format, total)
    const extra = (ratio && `(${ratio})`) || null

    const handleCopy = copyEnabled && (() => {
      copy(formatValue(value, format))
    })

    return <SimpleTooltipDOM {...{
      label,
      value: formattedValue,
      extra,
      onCopy: handleCopy,
    }}/>
  }
}

// @ts-ignore
SimpleTooltip.propTypes = {
  label: PropTypes.string,
  values: PropTypes.arrayOf(PropTypes.any),
  total: PropTypes.number,
  copyEnabled: PropTypes.bool,
}

export default SimpleTooltip
