import React, {FunctionComponent} from 'react'
import {Col, Row} from "antd"
import {PencilIcon, XIcon} from "@heroicons/react/outline"
import styled from "styled-components"
import {GAInput, IconContainer} from "@biron-data/react-components"

export interface FormHeaderProps {
  value?: string,
  onChange: (newValue: string) => void
  onCancel?: () => void,
  editable?: boolean,
  placeholder: string
}

const FormHeader: FunctionComponent<FormHeaderProps> = ({value, onChange, onCancel, editable = true, placeholder}) => {
  return (
    <FormHeaderContentRow>
      <Col span={22}>
        <InputContainer $editable={editable ? 1 : 0}>
          {editable && <Col span={1}>
            <IconContainer><PencilIcon/></IconContainer>
          </Col>}
          <StyledInputContainer span={21}>
            {editable ? <GAInput {...{
              className: "biron-react-input",
              variant: "borderless",
              placeholder,
              value,
              type: undefined,
              onChange: (event) => onChange(event.target.value),
              gaevent: {
                category: 'charts',
                action: `config_chart_title_update`,
              },
            }}/> : <div>{value}</div>}
          </StyledInputContainer>
        </InputContainer>
      </Col>
      <ExitContainer span={1} onClick={() => onCancel?.()}>
        <IconContainer clickable={true}><XIcon/></IconContainer>
      </ExitContainer>
    </FormHeaderContentRow>
  )
}

export default FormHeader

const ExitContainer = styled(Col)`
  display: flex;
  justify-content: end;
`

const FormHeaderContentRow = styled(Row)`
  min-height: 80px;
  padding: 0 32px 0 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const StyledInputContainer = styled(Col)`
  &&& .ant-input {
    font-size: 20px;
  }
`

const InputContainer = styled(Row)<{ $editable: number }>`
  display: flex;
    ${({$editable}) => $editable ? `
  &:hover {
    color: var(--primary);
    input, input::placeholder {
      color: var(--primary);
    }
  }` : ''}
`