import React from 'react'
import {formatValue} from 'commons/format/formatter'
import styled from "styled-components"
import {computeGrowthColor} from "components/charts/table/TableChart.DataCell"
import {Format, MetricGrowthType} from "@biron-data/react-bqconf"

type ColorType = 'default' | 'positive' | 'negative'

interface Props {
  format: Format
  value: number | string
}

export default class BoxChartData extends React.PureComponent<Props, any> {
  render() {
    const {value, format} = this.props
    const {growth, growthInvert, type} = format
    let color: ColorType = 'default'
    if (growth && value !== 0 && type !== MetricGrowthType.VALUE && typeof value === "number") {
      color = computeGrowthColor(Boolean(growthInvert), value)
    }
    const parsedValue = formatValue(value, format)
    return <BoxDataCell $color={color}>{parsedValue}</BoxDataCell>
  }
}


const BoxDataCell = styled.div<{ $color: ColorType }>`
  font-size: 24px;
  line-height: 40px;
    color: ${({$color}) => {
        switch ($color) {
    case "default":
      return `var(--primary)`
    case "negative":
      return `var(--negative)`
    case "positive":
      return `var(--positive)`
    default: {
        const exhaustiveCheck: never = $color
      return exhaustiveCheck
    }
  }
}
};
  @media (max-width: 767px) {
    text-align: center;
  }
`