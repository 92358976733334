import React, {useCallback, useMemo, useState} from "react"
import {Breadcrumb, Button, notification} from "antd";
import styled from "styled-components";
import AdministrationLayout from "../layout/AdministrationLayout";
import {IconContainer, MdView} from "@biron-data/react-components";
import {useSelector} from "react-redux";
import {getAvailables} from "../../redux/workspace.selector";
import EditableTitle from "../editableTitle/EditableTitle";
import {CodeIcon, DuplicateIcon, PlusIcon} from "@heroicons/react/outline";
import {ExternalLinkIcon} from "@heroicons/react/solid";
import {getCurrentEnvironmentId} from "../../redux/environment.selector";
import Language from "../../language";
import {FormDuplicateWorkspaceProps, FormKeys, FormWorkspaceProps} from "../forms/Form.types";
import FormGeneric from "../forms/Form.Generic";
import HideableForm from "../forms/Form.Hideable";
import {
    ExpandedWorkspace,
    RawExpandedWorkspace,
    SummarizedWorkspace,
    WorkspaceDtoForm
} from "../../redux/models/workspace";
import {buildWorkspaceUri} from "../workspace/Workspace.utils";
import WorkspaceManagerDelete from "./WorkspaceManager.delete";
import useDispatch from "../../hooks/useDispatch";
import {updateWorkspace} from "../../services/WorkspaceService";
import {useNavigate} from "react-router-dom";
import {getWorkspace} from "../../services/NavigationService";
import {NormalizedWorkspaceDetail} from "../../schemas/workspace";
import {omit} from "lodash";
import {CardEffectDiv} from "../../themes/SharedStyledComponent";

const WorkspaceManager = () => {
    const workspaces = useSelector(getAvailables)
    const environmentId = useSelector(getCurrentEnvironmentId)
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const baseWorkspace = useMemo<Partial<WorkspaceDtoForm>>(() => ({
        name: undefined,
    }), [])

    const baseAdvancedWorkspace = useMemo<Partial<WorkspaceDtoForm> & {configuration?: string}>(() => ({
        name: undefined,
        configuration: undefined
    }), [])

    const filteredWorkspaces = useMemo(() => workspaces.filter(workspace => workspace.environment.id === environmentId), [environmentId, workspaces])

    const onEditTitle = useCallback(async (newTitle: string, workspaceId: number) => {
        return updateWorkspace({
            name: newTitle
        }, workspaceId)
    }, [])

    const triggerGAEvent = useCallback(() => {

    }, [])

    const [isWorkspace, setIsWorkspace] = useState(false)
    const [isAdvancedWorkspace, setIsAdvancedWorkspace] = useState(false)
    const [duplicateWorkspace, setDuplicateWorkspace] = useState<NormalizedWorkspaceDetail>()

    const handleWorkspaceConfCancel = useCallback(() => {
        setIsWorkspace(false)
    }, [])

    const handleAdvancedWorkspaceConfCancel = useCallback(() => {
        setIsAdvancedWorkspace(false)
    }, [])

    const handleDuplicateWorkspaceConfCancel = useCallback(() => {
        setDuplicateWorkspace(undefined)
    }, [])

    const triggerNewWorkspaceNotification = useCallback((createdWorkspace: RawExpandedWorkspace) => {
        notification.info({
            duration: 2.5,
            key: `workspaceCreated`,
            message: Language.get(`workspaces.popup.created`, createdWorkspace.name),
            description: <MdView description={Language.get(`workspaces.popup.created-description`, `/app/${createdWorkspace.uri}/admin/user`)}/>,
            placement: 'bottomRight',
        })
    }, [])

    const handleWorkspaceConfSubmit = useCallback((data: Partial<WorkspaceDtoForm>) => {
        return dispatch.workspace.createWorkspace({
            dto: {
                ...baseWorkspace,
                name: data.name ? data.name : Language.get("workspaces.form.workspace.name.placeholder")
            },
            environmentId
        }).then((createdWorkspace) => {
            triggerNewWorkspaceNotification(createdWorkspace)
            handleWorkspaceConfCancel()
        })
    }, [baseWorkspace, dispatch.workspace, environmentId, handleWorkspaceConfCancel, triggerNewWorkspaceNotification])

    const handleAdvancedWorkspaceConfSubmit = useCallback((data: Partial<WorkspaceDtoForm> & {configuration?: string}) => {
        return dispatch.workspace.createWorkspace({
            dto: {
                ...baseWorkspace,
                name: Language.get("workspaces.form.workspace.name.placeholder"),
                ...omit(JSON.parse(data.configuration ?? "{}"), 'id'),
                uri: undefined
            },
            environmentId
        }).then((createdWorkspace) => {
            triggerNewWorkspaceNotification(createdWorkspace)
            handleAdvancedWorkspaceConfCancel()
        })
    }, [baseWorkspace, dispatch.workspace, environmentId, handleAdvancedWorkspaceConfCancel, triggerNewWorkspaceNotification])


    const handleDuplicateWorkspaceConfSubmit = useCallback(async (data: Partial<WorkspaceDtoForm>) => {
       if (duplicateWorkspace?.id) {
           const fullWorkspace = await getWorkspace(duplicateWorkspace.id)
           dispatch.workspace.createWorkspace({
               dto: {
                   ...omit(fullWorkspace, 'id'),
                   name: data.name ?? Language.get("workspaces.form.workspace.name.placeholder"),
                   uri: undefined,
               },
               environmentId
           }).then((createdWorkspace) => {
               triggerNewWorkspaceNotification(createdWorkspace)
               handleDuplicateWorkspaceConfCancel()
           })
       }
    }, [dispatch.workspace, duplicateWorkspace, environmentId, handleDuplicateWorkspaceConfCancel, triggerNewWorkspaceNotification])


    const onRedirect = useCallback((workspace: SummarizedWorkspace) => {
        navigate(buildWorkspaceUri(workspace))
    }, [navigate])

    const onDelete = useCallback((id: number) => {
        dispatch.workspace.deleteWorkspace({id, environmentId, onRedirect})
    }, [dispatch.workspace, environmentId, onRedirect])

    return <AdministrationLayout breadcrumb={<StyledBreadcrumb items={[
        {
            title: <Title>{Language.get("workspaces.head-title-prefix")}</Title>,
        }, {
            title: Language.get("workspaces.head-title"),
        }
    ]} >

    </StyledBreadcrumb>}>
        <HideableForm<Partial<WorkspaceDtoForm>, FormWorkspaceProps>
            renderFormComponent={(props) => <FormGeneric {...props}/>} {...{
            visible: isWorkspace,
            title: Language.get("workspaces.form.workspace.title"),
            formType: {
                type: FormKeys.WORKSPACE_CONF
            },
            data: baseWorkspace,
            onConfirm: handleWorkspaceConfSubmit,
            onCancel: handleWorkspaceConfCancel,
        }}/>
        <HideableForm<Partial<WorkspaceDtoForm>, FormWorkspaceProps>
            renderFormComponent={(props) => <FormGeneric {...props}/>} {...{
            visible: isAdvancedWorkspace,
            title: Language.get("workspaces.form.advancedWorkspace.title"),
            formType: {
                type: FormKeys.ADVANCED_WORKSPACE_CONF
            },
            data: baseAdvancedWorkspace,
            onConfirm: handleAdvancedWorkspaceConfSubmit,
            onCancel: handleAdvancedWorkspaceConfCancel,
        }}/>
        {duplicateWorkspace && <HideableForm<WorkspaceDtoForm, FormDuplicateWorkspaceProps>
            renderFormComponent={(props) => <FormGeneric {...props}/>} {...{
            visible: Boolean(duplicateWorkspace),
            title: Language.get("workspaces.form.duplicateWorkspace.title", duplicateWorkspace.name),
            formType: {
                type: FormKeys.DUPLICATE_WORKSPACE_CONF
            },
            data: duplicateWorkspace,
            onConfirm: handleDuplicateWorkspaceConfSubmit,
            onCancel: handleDuplicateWorkspaceConfCancel,
        }}/>}
        <WhiteCard>
            <Header>
                <SubTitle>{Language.get("workspaces.title")}</SubTitle>
                <Action>
                    <FlexButton type={"primary"} onClick={() => {
                        setIsWorkspace(true)
                    }}><IconContainer><PlusIcon/></IconContainer>{Language.get("workspaces.action.workspace")}</FlexButton>
                    <FlexButton type={"primary"} onClick={() => {
                        setIsAdvancedWorkspace(true)
                    }}><IconContainer><PlusIcon/></IconContainer>{Language.get("workspaces.action.advanced")}</FlexButton>
                </Action>
            </Header>
            <GreyCard>
                <FlexList>
                    {filteredWorkspaces.map(workspace => {
                        return <FlexListLine key={workspace.id}>
                            <EditableTitle fontSize={14} title={workspace.name} editable={true} onConfirm={(newTitle) => onEditTitle(newTitle, workspace.id)} triggerGAEvent={triggerGAEvent}/>
                            <Action>
                                <IconContainer clickable={true} onClick={async () => {
                                    const fullWorkspace = await getWorkspace(workspace.id)
                                    await navigator.clipboard.writeText(JSON.stringify(fullWorkspace))
                                    notification.info({
                                        duration: 2.5,
                                        key: `copyWorkspaceConf`,
                                        message: Language.get(`workspaces.popup.copied`),
                                        placement: 'bottomRight',
                                    })
                                }}><CodeIcon/></IconContainer>
                                <IconContainer clickable={true} onClick={() => {
                                    window.open(buildWorkspaceUri(workspace), '_blank')
                                }}><ExternalLinkIcon/></IconContainer>
                                <IconContainer clickable={true} onClick={() => {
                                    setDuplicateWorkspace(workspace)
                                }}><DuplicateIcon/></IconContainer>
                                <WorkspaceManagerDelete onDelete={() => onDelete(workspace.id)} workspaceName={workspace.name} />
                            </Action>
                        </FlexListLine>
                    })}
                </FlexList>
            </GreyCard>
        </WhiteCard>
    </AdministrationLayout>
}

export default WorkspaceManager

const FlexListLine = styled.span`
    display: flex;
    padding: 10px;
`
const FlexButton = styled(Button)`
    display: flex;
    gap: 10px;
`
const Action = styled.div`
    display: flex;
    gap: 15px;`
const SubTitle = styled.div`
    font-size: 20px;
`

const Header = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 10px 20px 10px;
`

const WhiteCard = styled(CardEffectDiv)`
    margin-left: 15px;
    padding: 20px;
    width: calc(100% - 30px);
    height: calc(100% - 15px);
`

const GreyCard = styled(CardEffectDiv)`
    background-color: var(--light-background);
    height: calc(100% - 60px);
    padding: 0px;
    `
const FlexList = styled.div`
    display: flex;
    flex-direction: column;
    overflow: auto;
    width: 100%;
    height: 100%;

    span + span {
        border-top: solid #DCE0E4 1px;
    }
`

const Title = styled.div`
    color: var(--primary);
`

const StyledBreadcrumb = styled(Breadcrumb)`
    font-size: 30px;
    margin-bottom: 15px;
    font-weight: 500;
    color: var(--dark-text);

    a {
        color: var(--dark-text);
    }

    @media (max-width: 1600px) {
        font-size: 14px;
    }

    .ant-breadcrumb-separator {
        color: var(--light-grey-2);
    }
`