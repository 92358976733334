import API from './api'
import {RawExport} from "components/forms/Form.types"

type Id = string | number

export const getExportsByClient = (environmentId: Id): Promise<RawExport[]> => API.instanceData.get(`/export/byEnvironment/${environmentId}`)

export const create = (data: RawExport): Promise<RawExport> => API.instanceData.post(`/export`, data, {
  params: {
    exportDestinationId: data.destination.id,
  },
})
export const update = (data: RawExport): Promise<RawExport> => API.instanceData.put(`/export/${data.id}`, data)

export const deleteExport = (id: number) => API.instanceData.delete(`/export/${id}`)