import React, {FunctionComponent, useCallback, useMemo} from 'react'
import {Col, Row, Tabs, Tag} from 'antd'
import Language from "language"
import styled from "styled-components"
import {useSelector} from "react-redux"
import {getPeriods} from "redux/period.selector"
import {getFromPeriod, intervalToString, PeriodTypes, SimpleTimeIntervalWithSource, usePeriodCategories} from "@biron-data/period-resolver"

interface PresetSelectorProps {
  value?: PeriodTypes
  onMouseEnter: (period: PeriodTypes | null) => void
  onSelect: (period: PeriodTypes) => void
}

const PresetSelector: FunctionComponent<PresetSelectorProps> = ({value, onMouseEnter, onSelect}) => {
  const periods = useSelector(getPeriods)

  const presetCategories = usePeriodCategories(periods)

  const mapPresets = useCallback(
    (localPeriods: PeriodTypes[]) => {
      return localPeriods ? localPeriods.map((period) => {
        const resolvedPeriod = getFromPeriod(period)
        return <PresetItem key={period.code} {...{
          value,
          resolvedPeriod,
          period,
          onMouseEnter,
          onSelect,
        }}/>
      }) : []
    },
    [value, onMouseEnter, onSelect],
  )
  const defaultActiveKey = useMemo(
    () => presetCategories.find(({presets}) => presets.find(key => key === value))?.title,
    [presetCategories, value],
  )
  return <StyledTabs {...{defaultActiveKey}} items={presetCategories.map(({title, presets}) => ({
    key: title,
    label: Language.get(title),
    children: <Row>
      <Col span={24}>{mapPresets(presets)}</Col>
    </Row>,
  }))}>
  </StyledTabs>
}

export default PresetSelector

const StyledTag = styled(Tag)`
  width: 540px;

  &:hover {
    background-color: var(--option-list-hover-bg);
  }
`
const IntervalContainer = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  text-align: right;
  color: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  flex-direction: column;
  font-style: italic;
`

const PresetContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`
const StyledTabs = styled(Tabs).attrs({tabPosition: 'left', size: 'small'})`
  width: 540px;
  height: 307px;

  .ant-tabs-nav-list {
    width: 90px;
    min-height: 200px;

    .ant-tabs-tab-active {
      background: var(--option-background);
    }
  }

  .ant-tabs-content-holder {
    max-height: 307px;
    overflow-y: scroll;
    overflow-x: hidden;
  }

  .ant-tag {
    line-height: 18px;
    padding: 4px 18px 4px 14px;
    border-radius: 0px;
    border: none;
    -webkit-transition: all .1s cubic-bezier(.78, .14, .15, .86);
    transition: all .1s cubic-bezier(.78, .14, .15, .86);
    cursor: pointer;
    color: var(--main-text-color);

    &:hover {
      opacity: 0.7;
      color: var(--primary);
    }
  }
  
  .ant-tag-blue {
    color: var(--primary);
  }
`

interface PresentItemProps {
  value: PeriodTypes | undefined
  resolvedPeriod: SimpleTimeIntervalWithSource | undefined
  period: PeriodTypes
  onMouseEnter: (periodTypes: PeriodTypes | null) => void
  onSelect: (periodTypes: PeriodTypes) => void
}

const PresetItem: FunctionComponent<PresentItemProps> = ({value, resolvedPeriod, period, onMouseEnter, onSelect}) => {
  const handlePresetMouseEnter = useCallback(
    () => onMouseEnter(period),
    [onMouseEnter, period],
  )
  const handlePresetClick = useCallback(
    () => {
      onSelect(period)
    },
    [onSelect, period],
  )
  return <StyledTag key={period.code} {...{
    color: period === value ? "blue" : undefined,
    onMouseEnter: handlePresetMouseEnter,
    onClick: handlePresetClick,
  }}>
    <PresetContainer>
      <div>{period ? Language.getTranslatedName(period) : ''}</div>
      <IntervalContainer>
        {resolvedPeriod ? intervalToString({
          start: resolvedPeriod.start(),
          end: resolvedPeriod.end(),
        }, 'DD MMM YYYY') : ""}
      </IntervalContainer>
    </PresetContainer>
  </StyledTag>
}