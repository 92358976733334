import React, {memo, useMemo} from 'react'
import Language from "language"
import styled from "styled-components"
import {PopoverHelp} from "@biron-data/react-components"


const UserManagerAceHelp = memo<any>(function UserAceHelp({...props}) {
  const content = useMemo(() => <StyledUl>
      {['WORKSPACE_USER', 'WORKSPACE_MANAGER', 'WORKSPACE_EXPLORER', 'ENVIRONMENT_ADMIN']
        .map(role => <li key={role}>
          <label>{Language.get(`enum.role.${role}`)}</label> : {Language.get(`enum.roleDescription.${role}`)})
        </li>)
      }
    </StyledUl>
    , [])
  return <PopoverHelp content={content} {...props}/>
})

export default UserManagerAceHelp

const StyledUl = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
  > li {
    margin-bottom: 10px;
    > label {
      font-weight: bold;
    }
  }
`
