import {ColorName, Colors} from "@biron-data/react-bqconf"

export type HexColor = `#${string}`;


export const textColor = new Map<ColorName, string>([
  [ColorName["blue-border"], "white"],
  [ColorName["blue-pastel"], "black"],
  [ColorName["orange-border"], "white"],
  [ColorName["orange-pastel"], "black"],
  [ColorName["red-border"], "white"],
  [ColorName["red-pastel"], "white"],
  [ColorName["green-border"], "white"],
  [ColorName["green-pastel"], "white"],
  [ColorName["bluegreen-border"], "white"],
  [ColorName["bluegreen-pastel"], "black"],
])

export const EChartTheme = {
  color: [
    Colors.blue_border,
    Colors.red_border,
    Colors.orange_border,
    Colors.green_border,
    Colors.purple_border,
    Colors.blue_pastel,
    Colors.red_pastel,
    Colors.orange_pastel,
    Colors.green_pastel,
    Colors.purple_pastel,
  ],
}
export const echartColorOther: HexColor = '#94949E'
export const echartColorNa: HexColor = '#BEC5CC'

// Call this for each data point to conserve its color even if array was rearranged.
export function extractColor(idx?: number, colors?: string []): string | undefined {
  colors = colors ?? EChartTheme.color
  return idx === undefined ? undefined : colors[idx % colors.length]
}
