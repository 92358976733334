type Id = number | string

export default class InvalidConfError extends Error {
  type?: string
  ids?: Id[]

  constructor(type: string, ids?: Id[]) {
    if (ids) {
      super(`${type} [${ids}] could not be found`)
      this.type = type
      this.ids = ids
    } else {
      super(type)
    }
  }
}
