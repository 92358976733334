/* eslint-disable max-lines */
import React, {memo, useCallback, useMemo, useState} from 'react'
import Language from 'language'
import {Button, Popover} from 'antd'
import {captureEvent} from 'services/GoogleAnalyticsService'
import {extendedWidgetTypeToChartConf} from "commons/dashboard/dashboard"
import {ChartDtoDetail, ChartGenericDtoDetail} from "types/charts"
import styled from "styled-components"
import HideableForm from "components/forms/Form.Hideable"
import {ChartFormProps, FormDividerProps, FormKeys, FormType} from "components/forms/Form.types"
import {useSelector} from "react-redux"
import {getCurrentWorkspaceExpanded} from "redux/workspace.selector"
import {ChartFormat, ColorName, DataSelection, GenericChartTypes, MetaModel} from "@biron-data/react-bqconf"
import {NormalizedDashboardTypes} from "schemas/dashboard"
import {
  ChartDetailWithoutLayout, ChartDividerWithoutLayout,
  ChartGenericWithoutLayout,
  ChartTargetWithoutLayout,
  ChartWithMetricDefLayoutTypes,
} from "components/widgetContainer/WidgetContainer"
import {IconContainer, MdView, PasteButton, TooltipPopover} from "@biron-data/react-components"
import {PlusIcon} from "@heroicons/react/outline"
import FormChart from "components/forms/chart/FormChart"
import FormGeneric from "components/forms/Form.Generic"
import {DividerConf} from "components/workspace/WorkspaceBridge.SiderContainer"
import {ExpandedWorkspace} from "redux/models/workspace"
import {PeriodTypes, predefinedPeriod} from "@biron-data/period-resolver"
import {Styles} from 'components/forms/confItems/StylesSelector'
import {EllipsisOutlined} from "@ant-design/icons"
import {UseHandleChartAddType} from "hooks/useHandleAddChart"
import {ExtendedWidgetTypes, WidgetTypes} from "commons/dashboard/dashboard.types"
import WidgetIcon from "components/widgetContainer/WidgetIcon"

export interface DashboardAddWidgetButtonProps {
  environmentId?: number,
  metaModel: MetaModel,
  dashboard: Pick<NormalizedDashboardTypes, "id" | "title">
  dashboardSelection: DataSelection
  copiedChart: ChartDtoDetail
  handleChartAdd: ReturnType<UseHandleChartAddType>
  handleChartAddFromClipboard: () => Promise<void> | null
  style?: string
  GASource: string
  onVisibleChange?: (state: boolean) => void
}

function defaultChartProperties(type?: ExtendedWidgetTypes): ChartTargetWithoutLayout |
  Omit<ChartDividerWithoutLayout, "id" | "title" | "type" | 'extraConf'> |
  Omit<ChartGenericWithoutLayout, "id" | "title" | "type" | 'extraConf'> | undefined {
  if (!type) {
    return undefined
  }
  if (type === WidgetTypes.TARGET) {
    return {
      period: predefinedPeriod.MONTH_TO_DATE,
    } as ChartTargetWithoutLayout
  } else if (type === WidgetTypes.DIVIDER) {
    return {
      cssClass: ColorName["blue-border"], style: Styles.border, description: ''
    } as Omit<ChartDividerWithoutLayout, "id" | "title" | "type" | 'extraConf'>
  } else if (Object.values(GenericChartTypes).includes(type as GenericChartTypes)) {
    return {
    } as Omit<ChartGenericWithoutLayout, "id" | "title" | "type" | 'extraConf'>
  } else {
    return undefined
  }
}

export default memo<DashboardAddWidgetButtonProps>(function DashboardAddWidgetButton({
                                                               environmentId,
                                                               metaModel,
                                                               copiedChart,
                                                               handleChartAddFromClipboard,
                                                               dashboard,
                                                               dashboardSelection,
                                                               handleChartAdd,
                                                               style,
                                                               onVisibleChange,
                                                               GASource,
                                                             }) {
  const [chartConfEdit, setChartConfEdit] = useState(false)
  const [genericConfEdit, setGenericConfEdit] = useState(false)

  const workspace = useSelector(getCurrentWorkspaceExpanded) as ExpandedWorkspace
  const [selectedType, setSelectedType] = useState<WidgetTypes | GenericChartTypes>(WidgetTypes.UNKNOWN)
  const [isPopupOpen, setIsPopupOpen] = useState(false)
  const formType: FormType = useMemo(
    () => ({
      type: FormKeys.CHART_CONF,
      chartType: selectedType,
    }),
    [selectedType],
  )
  const formValue: ChartWithMetricDefLayoutTypes | undefined = useMemo(
    () => {
      if (selectedType === WidgetTypes.TARGET) {
        return {
          id: -1,
          period: predefinedPeriod.MONTH_TO_DATE,
          type: WidgetTypes.TARGET, extraConf: {},
          viewCode: "",
          title: "",
        } as ChartTargetWithoutLayout
      } else if (selectedType === WidgetTypes.DIVIDER) {
        return {
          id: -1,
          period: predefinedPeriod.MONTH_TO_DATE,
          description: '',
          cssClass: ColorName["bluegreen-pastel"],
          type: WidgetTypes.DIVIDER, extraConf: {},
          title: "",
        } as ChartDividerWithoutLayout
      } else if (Object.values(GenericChartTypes).includes(selectedType as GenericChartTypes)) {
        return {
          id: -1,
          viewCode: "",
          period: undefined, filters: [], slicers: [], metrics: [], format: null, orderBys: [], ignoreMetrics0: false,
          type: WidgetTypes.GENERIC, extraConf: {displayType: selectedType as GenericChartTypes, limits: []},
          title: "",
        } as ChartGenericWithoutLayout
      } else {
       return undefined
      }
    },
    [selectedType],
  )

  const handleChartConfSubmit = useCallback((newConf: Omit<ChartWithMetricDefLayoutTypes, "viewCode">) => {
    setChartConfEdit(false)
    return handleChartAdd(newConf)
  }, [handleChartAdd])

  const handleDividerConfSubmit = useCallback((newConf: DividerConf) => {
    setGenericConfEdit(false)
    return handleChartAdd(newConf as any)
  }, [handleChartAdd])

  const handleConfCancel = useCallback(
    () => {
      setChartConfEdit(false)
      setGenericConfEdit(false)
    },
    [],
  )

  const onAddClick = useCallback((newType: WidgetTypes | GenericChartTypes) => {
    captureEvent({
      category: 'charts',
      action: `add_chart_from_${GASource}`,
      // @ts-ignore
      widgetType: newType,
    })
    setSelectedType(newType)
    setIsPopupOpen(false)
    onVisibleChange?.(false)

    if (newType === WidgetTypes.DIVIDER) {
      setGenericConfEdit(true)
    } else {
      setChartConfEdit(true)
    }
  }, [GASource, onVisibleChange])

  return <>
    {environmentId && formValue && formValue.type !== WidgetTypes.DIVIDER && <HideableForm<ChartWithMetricDefLayoutTypes, ChartFormProps> {...{
      renderFormComponent: (props) => <FormChart {...props}/>,
      visible: chartConfEdit,
      formType,
      data: formValue,
      metaModel,
      workspace,
      dashboardId: dashboard.id,
      dashboardSelection,
      environmentId,
      onConfirm: handleChartConfSubmit,
      onCancel: handleConfCancel,
    }}/>}
    <HideableForm<DividerConf, FormDividerProps>
      renderFormComponent={(props) => <FormGeneric {...props}/>} {...{
      visible: genericConfEdit,
      formType,
      data: formValue as DividerConf,
      metaModel,
      workspace,
      dashboardId: dashboard.id,
      dashboardSelection,
      environmentId,
      onConfirm: handleDividerConfSubmit,
      onCancel: handleConfCancel,
    }}/>

    <AddChartDividerContent $additionnalstyle={style}>
      <FlexButton type={"primary"} onClick={() => onAddClick(GenericChartTypes.BOXES)}>
        <IconContainer><PlusIcon/></IconContainer>{Language.get("chart")}
      </FlexButton>
      <Popover
        trigger="click"
        placement={"rightTop"}
        open={isPopupOpen}
        overlayClassName={"chart-type-overlay"}
        onOpenChange={(state) => {
          setIsPopupOpen(state)
          onVisibleChange?.(state)
        }}
        content={<DashboardAddWidgetTypesList>
          <TooltipPopover
              size={'small'}
              key={WidgetTypes.DIVIDER}
              placement="bottomRight"
              autoAdjustOverflow={false}
              mouseEnterDelay={1}
              content={<MdView description={Language.get(`dashboard-add-divider`, true)}/>}>
              <DashboardAddWidgetType
                onClick={() => {
                  onAddClick(WidgetTypes.DIVIDER)
                }}>
                <TypeIcon><WidgetIcon type={WidgetTypes.DIVIDER} size={24}/></TypeIcon>
                <DashboardAddWidgetTypeLabel>{Language.get(`dashboard-add-divider-label`)}</DashboardAddWidgetTypeLabel>
              </DashboardAddWidgetType>
          </TooltipPopover>
          <TooltipPopover
            size={'small'}
            key={WidgetTypes.TARGET}
            placement="bottomRight"
            autoAdjustOverflow={false}
            mouseEnterDelay={1}
            content={<MdView description={Language.get(`dashboard-add-target`, true)}/>}>
            <DashboardAddWidgetType
              onClick={() => {
                onAddClick(WidgetTypes.TARGET)
              }}>
              <TypeIcon><WidgetIcon type={WidgetTypes.TARGET} size={24}/></TypeIcon>
              <DashboardAddWidgetTypeLabel>{Language.get(`dashboard-add-target-label`)}</DashboardAddWidgetTypeLabel>
            </DashboardAddWidgetType>
          </TooltipPopover>
          <Separator/>
          <TooltipPopover
            size={'small'}
            key={"evolution"}
            placement="bottomRight"
            autoAdjustOverflow={false}
            mouseEnterDelay={1}
            content={<MdView description={Language.get(`dashboard-add-evolution`, true)}/>}>
            <DashboardAddWidgetType
              onClick={() => {
                onAddClick(GenericChartTypes.LINE)
              }}>
              <TypeIcon><WidgetIcon type={GenericChartTypes.LINE} size={24}/></TypeIcon>
              <DashboardAddWidgetTypeLabel>{Language.get(`dashboard-add-evolution-label`)}</DashboardAddWidgetTypeLabel>
            </DashboardAddWidgetType>
          </TooltipPopover>
          <TooltipPopover
            size={'small'}
            key={GenericChartTypes.BARS}
            placement="bottomRight"
            autoAdjustOverflow={false}
            mouseEnterDelay={1}
            content={<MdView description={Language.get(`dashboard-add-bars`, true)}/>}>
            <DashboardAddWidgetType
              onClick={() => {
                onAddClick(GenericChartTypes.BARS)
              }}>
              <TypeIcon><WidgetIcon type={GenericChartTypes.BARS} size={24}/></TypeIcon>
              <DashboardAddWidgetTypeLabel>{Language.get(`dashboard-add-bars-label`)}</DashboardAddWidgetTypeLabel>
            </DashboardAddWidgetType>
          </TooltipPopover>
        </DashboardAddWidgetTypesList>}>
        <Button type={"primary"}>
          <IconContainer>
            <EllipsisOutlined/>
          </IconContainer>
        </Button>
      </Popover>
      <PasteButton handleClick={() => {
        captureEvent({
          category: 'charts',
          action: `add_chart_paste_from_${GASource}`,
        })
        handleChartAddFromClipboard()
        setIsPopupOpen(false)
      }} disabled={!copiedChart}/>
    </AddChartDividerContent>
  </>
})

const FlexButton = styled(Button)`
  display: flex;
  gap: 8px;
`

const Separator = styled.div`
  height: 1px;
  background-color: var(--light-grey);
  margin-top: 7px;
  margin-bottom: 7px;
`

const DashboardAddWidgetTypesList = styled.div`
  display: flex;
  flex-direction: column;
`

const AddChartDividerContent = styled.div<{ $additionnalstyle?: string }>`
  justify-content: center;
  gap: 10px;
  display: flex;

  .ant-btn[disabled], .ant-btn[disabled]:hover, .ant-btn[disabled]:focus, .ant-btn[disabled]:active {
    color: var(--light-grey);
    border-color: var(--light-grey);
    text-shadow: none;
    box-shadow: none;
    background-color: var(--main-background-color);
  }

    ${({$additionnalstyle}) => $additionnalstyle}
`

const DashboardAddWidgetType = styled.div`
  padding: 9px 16px;
  text-align: center;
  flex-basis: 100%;
  display: flex;
  gap: 11px;
  flex-direction: row;
  
  &:hover {
    cursor: pointer;
    color: var(--primary);
  }
`

const DashboardAddWidgetTypeLabel = styled.div`
  text-align: center;
  line-height: 20px;
`

const TypeIcon = styled.div`
  &&& {
    font-size: 24px;
  }
`