import React, {forwardRef, LegacyRef, memo, ReactElement, useCallback, useEffect, useMemo, useState} from "react"
import {Button, Col, Modal, Row} from 'antd'
import {batch} from 'commons/batch'
import Language from "language"
import useIsMounted from "ismounted"
import {FormContent, FormProps} from "components/forms/chart/types"
import FormHeader from "components/forms/FormHeader"
import {useDebouncedCallback} from "use-debounce"
import styled from "styled-components"

export interface FormError {
  field: string,
  message: string
}

export interface ConsolidatedFormProps<T extends FormContent> {
  ref: any
  data: T
  errors: FormError[]
  onValuesChange: (changedValues: Partial<T>) => void
}

export interface FormModalProps<T extends FormContent, P extends FormProps> {
  visible?: boolean
  data: T
  setData?: (newData: Partial<T>) => void
  renderFormComponent: (props: P & ConsolidatedFormProps<T>) => ReactElement<P>
  extraButtons?: JSX.Element[]
  onConfirm: (newData: any) => void
  onCancel?: () => void
  formProps: P
  defaultTitle: string
  isTitleEditable?: boolean
}

// eslint-disable-next-line react/display-name
const FormModal = <T extends FormContent, P extends FormProps>({
                                                                 defaultTitle,
                                                                 isTitleEditable = false,
                                           visible = true,
                                           data,
                                                                 setData,
                                           renderFormComponent,
                                           extraButtons = [],
                                           onConfirm,
                                           onCancel,
                                           formProps,
                                           ...props
                                                               }: FormModalProps<T, P>, ref: LegacyRef<HTMLDivElement> | undefined) => {
  const isMounted = useIsMounted()
  const [editLoading, setEditLoading] = useState(false)
  const formRef = React.useRef<any>()
  const [formData, setFormData] = useState<T>(data)
  const [formTitle, setFormTitle] = useState<string | undefined>((formData as any).title)
  const [formErrors, setFormErrors] = useState<FormError[]>([])

  useEffect(() => {
    if (formData === undefined) {
      setFormTitle(defaultTitle)
    }
  }, [formData, defaultTitle])

  // when data change, reset errors
  useEffect(() => {
    setFormData(data)
    setFormErrors(old => old.length === 0 ? old : [])
  }, [data])

  const onSubmit = useCallback(async () => {
      return formRef.current.validateFields()
        .catch(() => {
          })
          .then((newData: T) => {
            if (newData) {
              setEditLoading(true)
              setFormData(old => ({
                ...old,
                ...newData,
                title: formTitle,
              }))
              Promise.resolve(onConfirm({
                  ...formData,
                  ...newData,
                  title: isTitleEditable ? formTitle : undefined,
                }))
                .then(() => [])
                .catch(error => {
                    if (error.status === 400 && error.type === 'https://zalando.github.io/problem/constraint-violation') {
                      setFormErrors(error.violations)
                      setEditLoading(false)
                      return error.violations
                    } else {
                      setEditLoading(false)
                      return Promise.reject(error)
                    }
                  },
                )
                .then((errors: FormError[]) => batch(() => {
                  if (isMounted.current) {
                    setEditLoading(false)
                    setFormErrors(errors)
                  }
                }))
            }
            },
          )
    }
    , [onConfirm, formData, isTitleEditable, formTitle, isMounted])

    const onSubmitDebounced = useDebouncedCallback(onSubmit, 1000)

    const footer = useMemo(() => {
        const stdButtonGroup = <FlexWithGap>
          <Button onClick={onCancel}>{Language.get('common.formModal.actionCancel')}</Button>
          <Button type="primary" loading={editLoading}
                  onClick={onSubmitDebounced}>{Language.get('common.formModal.actionSave')}</Button>
        </FlexWithGap>
        if (extraButtons.length > 0) {
          return <Row>
            <Col span={14} style={{textAlign: 'left', display: 'flex', gap: 8}}>{extraButtons}</Col>
            <Col span={10}>{stdButtonGroup}</Col>
          </Row>
        } else {
          return stdButtonGroup
        }
      },
      [onCancel, editLoading, onSubmitDebounced, extraButtons])

  const onValuesChange = useCallback((changedValues: Partial<T>) => {
      if (setData) {
        setData(changedValues)
      } else {
        setFormData(d => ({...d, ...changedValues}))
        setFormErrors(old => old.length === 0 ? old : [])
      }
    },
    [setData],
  )

    return <Modal {...{
      open: visible,
      width: 600,
      maskClosable: false,
      closable: false,
      footer,
      onCancel,
      ...props,
      title: <FormHeader
        value={formTitle ?? defaultTitle}
        placeholder={defaultTitle}
        onCancel={onCancel}
        editable={isTitleEditable}
        onChange={(newValue) => {
          setFormTitle(newValue)
        }}/>,
    }}>
      <div ref={ref}>
        {renderFormComponent({
          ...formProps,
          ref: formRef,
          data: formData,
          errors: formErrors,
          onValuesChange,
        } as P & ConsolidatedFormProps<T>)}
      </div>
    </Modal>
}

export default memo<any>(forwardRef<HTMLDivElement, any>(FormModal)) as typeof FormModal

const FlexWithGap = styled.div`
    display: flex;
    gap: 8px;
    justify-content: end;
`