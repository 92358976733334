import {create, deleteExport, getExportsByClient, update} from "services/ExportService"
import {returnOrExecBatch} from 'commons/batch'
import {getCurrentEnvironmentId} from "redux/environment.selector"
import {getExports} from "redux/export.selector"
import {getPeriods} from "redux/period.selector"
import {createModel} from "@rematch/core"
import RootModel from "redux/models/index"
import {Export, ExportDtoForm, RawExport} from "components/forms/Form.types"
import {getDefaultPeriod, PeriodTypes} from "@biron-data/period-resolver"
import {WidgetTypes} from "commons/dashboard/dashboard.types"
import {omit} from "lodash"
import {deserializeExtraConf, handleExtraConf} from "services/api"

const initialState: Export[] = []

const deserializeRawExport = (rawExport: RawExport, periods: PeriodTypes[]) => {
  const defaultPeriod = getDefaultPeriod()

  return deserializeExtraConf({
    ...omit(rawExport, ["bqConf"]),
    type: WidgetTypes.EXPORT,
    period: periods.find((period) => period.code === rawExport.bqConf.period?.code) ?? defaultPeriod,
    slicers: rawExport.bqConf.slicers,
    filters: rawExport.bqConf.filters,
    metrics: rawExport.bqConf.metrics.map(metric => ({
      ...metric,
      overridePeriod: metric.overridePeriod ? periods.find((period) => period.code === metric.overridePeriod?.code) : undefined,
    })),
    orderBys: rawExport.bqConf.orderBys,
    ignoreMetrics0: rawExport.bqConf.ignoreMetrics0,
  } as Export)
}

const serializeExport = (source: Export) => {
  return handleExtraConf({
    ...omit(source, ["period", "slicers", "filters", "metrics", "orderBys", "extraConf.sorts", "extraConf.ignoreMetrics0"]),
    bqConf: {
      period: {code: source.period.code},
      slicers: source.slicers,
      filters: source.filters,
      metrics: source.metrics.map(metric => ({
        ...metric,
        overridePeriod: metric.overridePeriod ? {code: metric.overridePeriod.code} : null,
      })),
      orderBys: source.orderBys,
      ignoreMetrics0: source.ignoreMetrics0,
    },
  } as RawExport, JSON.stringify, "")
}

export default createModel<RootModel>()({
  state: initialState,
  reducers: {
    set(state, exports: Export[]) {
      return exports
    },
    'export/cleanStates': () => {
      return initialState
    },
  },

  effects: (dispatch) => {
    return {
      async loadExports({forBatch = false, environment}: {
        forBatch?: boolean,
        environment: {
          id: number
        }
      }, state): Promise<{ type: string, payload: Export[] }> {
        const normalized = await getExportsByClient(environment.id)
        const periods = await getPeriods(state)
        return returnOrExecBatch(forBatch, () => dispatch.exports.set(normalized.map(exportObject => deserializeRawExport(exportObject, periods))))
      },
      async createExport({data}: {
        data: ExportDtoForm
      }, state) {
        return create(serializeExport(data))
          .then(async newExport => {
            const environmentId = getCurrentEnvironmentId(state)
            const periods = await getPeriods(state)
            dispatch.exports.loadExports({environment: {id: environmentId}})
            return deserializeRawExport(newExport, periods)
          })
      },
      async updateExport({data}: {
        data: ExportDtoForm
      }, state) {
        return update(serializeExport(data))
          .then(async newExport => {
            const environmentId = getCurrentEnvironmentId(state)
            const periods = await getPeriods(state)
            dispatch.exports.loadExports({environment: {id: environmentId}})
            return deserializeRawExport(newExport, periods)
          })
      },
      async deleteExport(id: number, state) {
        await deleteExport(id)
        const exports = getExports(state as never) as Export[]
        dispatch.exports.set(Object.assign([], Object.values(exports).filter(e => e.id !== id)))
      },
    }
  },
})

