import {createModel} from "@rematch/core"
import RootModel from "redux/models/index"
import {DashboardFormats, DashboardTypes, Folder, PersonalDashboardDtoForm, RawPersonalDashboardDto} from "types/dashboards"
import {predefinedPeriod} from "@biron-data/period-resolver"
import {getPersonalDashboards} from "redux/personalDashboard.selector"
import {
  clonePersonalDashboard,
  createPersonalDashboards,
  getPersonalDashboardByUid,
  getPersonalDashboards as getPersonalDashboardsService,
  updatePersonalDashboard,
} from "services/PersonalDashboard"
import {consolidateRawDashboardPeriod, setEntities} from "redux/models/utils.model"
import * as DashboardSchemas from "schemas/dashboard"
import {normalizeDashboardDetails, NormalizedDashboardTypes, NormalizedPersonalDashboardTypes} from "schemas/dashboard"
import {ChartDtoDetailTypes} from "types/charts"
import {getPeriods} from "redux/period.selector"
import {getCurrentEnvironmentId} from "redux/environment.selector"
import {getPermission} from "redux/appEnvironment.selector"
import {DashboardDto} from "components/workspace/WorkspaceBridge.SiderContainer"
import {cloneWorkspaceDashboard} from "services/NavigationService"
import {omit} from "lodash"
import {PermissionsEnum} from "redux/models/appEnvironment"

const initialState: {
  entities: {
    dashboards: Record<string, NormalizedPersonalDashboardTypes>,
    charts: Record<string, ChartDtoDetailTypes>,
  }
} = {
  entities: {
    dashboards: {},
    charts: {},
  },
}

export default createModel<RootModel>()({
  state: initialState,
  reducers: {
    setEntities,
    'personalDashboard/cleanStates': () => {
      return initialState
    },
  },

  effects: (dispatch) => {
    return {
      async loadPersonalDashboards({environmentId}: {
        environmentId: number
      }, state) {
        const withDashboardPPermission = getPermission(state)(PermissionsEnum.withDashboardP)
        if (withDashboardPPermission) {
          const periods = getPeriods(state)
          const dashboardList = await getPersonalDashboardsService(environmentId)
          const normalizedDashboard = normalizeDashboardDetails(dashboardList.detailed?.map(d => consolidateRawDashboardPeriod({
            ...d,
            type: DashboardTypes.personal,
            format: DashboardFormats.detailed,
          }, periods)) ?? [])

          dispatch.personalDashboards.setEntities({
            dashboards: {
              ...normalizedDashboard.entities.dashboards,
              ...Object.fromEntries(dashboardList.summarized?.map(d => [d.id, {
                ...d,
                type: DashboardTypes.personal,
                format: DashboardFormats.summarized,
              }]) ?? []),
            },
            charts: normalizedDashboard.entities.charts,
          })
        }
      },
      addPersonalDashboard: async (dto: PersonalDashboardDtoForm | undefined, state): Promise<RawPersonalDashboardDto> => {
        const dashboards = getPersonalDashboards(state)
        const dashboard: PersonalDashboardDtoForm = dto ?? {
          title: `Draft ${dashboards.length + 1}`,
          charts: [],
          filters: [],
          period: predefinedPeriod.LAST_7_DAYS,
        }
        const newDashboard = await createPersonalDashboards(dashboard, getCurrentEnvironmentId(state))
        dispatch.personalDashboards.setEntities({
          dashboards: DashboardSchemas.normalizeDashboardDetail(newDashboard).entities.dashboards,
          },
        )
        return newDashboard
      },
      updatePersonalDashboardFolder: async ({dashboard, newFolderState, redirect}: {
        dashboard: Pick<NormalizedPersonalDashboardTypes, "uid" | "id">,
        newFolderState: Folder,
        redirect?: (removedUid: string) => void
      }, state) => {
        const newDashboardState = await updatePersonalDashboard(dashboard.id, {
          ...omit((await getPersonalDashboardByUid(dashboard.uid, getCurrentEnvironmentId(state))), ['charts']),
          folder: newFolderState,
        })
        redirect?.(dashboard.uid)
        dispatch.currentDashboard.setDashboard(
          DashboardSchemas.normalizeDashboardDetail(newDashboardState).entities.dashboards[newDashboardState.id],
        )

        dispatch.personalDashboards.setEntities({
          dashboards: {
            ...state.personalDashboards.entities,
            [dashboard.id]: newDashboardState,
          },
          },
        )
      },
      async cloneDashboard({dashboardId}: { dashboardId: number }) {
        const newDashboard = await clonePersonalDashboard(dashboardId)

        dispatch.personalDashboards.setEntities({
            dashboards: DashboardSchemas.normalizeDashboardDetail(newDashboard).entities.dashboards,
          },
        )
        return newDashboard
      },
    }
  },
})

