import {hasMultipleViews, MetaModel, MetaModelView, DimensionOption, ConfMetricFilterTypes, parseFormat, getMetricDef, ConfOrderBy, ConfSlicer, GenericChartTypes, MetricDtoDetail, getMetricLabel} from "@biron-data/react-bqconf"
import {ConfLimit} from "types/widgets"
import {CacheElementType, ConfigCache, DimensionCacheElement, MetricCacheElement} from "components/forms/chart/types"
import {convertOrderByToCache, getDimensionLabel, isMetricRatio, isSlicerSimple} from "components/forms/chart/utils"
import _ from "lodash"

export const createCache = (
  metaModel: MetaModel,
  viewsWithMetrics: MetaModelView[],
  dimensionOptions: DimensionOption[],
  metrics: MetricDtoDetail[],
  slicers: ConfSlicer[],
  metricFilters: ConfMetricFilterTypes[],
  orderBys?: ConfOrderBy[],
  limits?: ConfLimit[],
  displayType?: GenericChartTypes,
  displayLabels?: boolean,
  ignoreMetrics0?: boolean,
  asPercentage?: boolean,): ConfigCache => {
  const isMultiView = hasMultipleViews(metrics)
  const newCache = {
    metrics: metrics.map((m, index): MetricCacheElement => {
      const metricLimitApplied = (slicers.length === 0 || displayType === GenericChartTypes.TABLES) && limits && limits.length > 0 ? limits[0] : undefined
      const label = getMetricLabel(viewsWithMetrics, m, isMultiView)
      const labelOccurence = _.countBy(metrics.slice(0, index), metric => getMetricLabel(viewsWithMetrics, metric, isMultiView))[label] ?? 0
      const id = `${label}-${labelOccurence}`

      const metricDef = getMetricDef(metaModel, m)

      return {
        type: CacheElementType.METRIC,
        id,
        code: m.metricCode,
        viewAlias: m.viewAlias,
        viewCode: m.viewCode,
        metricAlias: m.metricAlias,
        label,
        isAxis: false,
        growth: m.growth,
        growthInvert: m.extraConf?.growthInvert,
        limit: metricLimitApplied?.limitSeries,
        hideOthers: metricLimitApplied?.hideOthers,
        limitDefault: metricLimitApplied?.isDefault,
        format: metricDef ? parseFormat({
          ...m,
          metricDef,
        }) : undefined,
        isRatio: isMetricRatio(m, metaModel),
        additionalFilters: m.additionalFilters,
        ...convertOrderByToCache(id, slicers.length + index, orderBys),
      }
    }),
    slicers: [] as DimensionCacheElement[],
    metricFilters,
    options: {
      displayLabels,
      ignoreMetrics0,
      asPercentage,
    }
  }

  const indexOfDateSlicer = slicers.findIndex(s => s.type === "date")
  newCache.slicers = slicers.map((s, index) => {
    const label = getDimensionLabel(dimensionOptions, s)
    const labelOccurence = label ? _.countBy(slicers.slice(0, index), slicer => getDimensionLabel(dimensionOptions, slicer))[label] ?? 0 : 0
    const id = `${label}-${labelOccurence}`
    const slicerLimitApplied = displayType !== GenericChartTypes.TABLES && limits && limits.length > index ? limits[index] : undefined

    const newSort = convertOrderByToCache(id, index, orderBys)
    const cacheSlicer: DimensionCacheElement = {
      type: CacheElementType.SLICER,
      id,
      code: s.type === "dimension" ? s.dimensionCode : "date",
      label,
      hasRefDate: s.type === "date",
      ...newSort,
      limit: slicerLimitApplied?.limitSeries,
      limitDefault: slicerLimitApplied?.isDefault,
      hideOthers: slicerLimitApplied?.hideOthers,
      isDefault: s.isDefault,
      isSimple: isSlicerSimple(s, metaModel),
      isAxis: false,
    }
    switch (displayType) {
      case GenericChartTypes.LINE:
      case GenericChartTypes.AREA:
      case GenericChartTypes.BARS: {
        return {
          ...cacheSlicer,
          isAxis: index === 0,
        }
      }
      default:
        return {
          ...cacheSlicer,
          isAxis: indexOfDateSlicer === -1 ? false : index === indexOfDateSlicer,
        }
    }
  })
  return newCache
}