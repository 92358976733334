import React, {FunctionComponent} from 'react'
import {FormItemKeys} from 'commons/keywords/form'
import {WrapperItem, WrapperRoot, BironSelect} from "@biron-data/react-components"
import {CollectionIcon} from "@heroicons/react/outline"
import {Position} from "classes/workflows/main-workflows/FormWorkflow"

interface Props {
  name: string
  options: Position[]
  value?: string
  onChange?: (key: FormItemKeys, newValue: string) => void
  showSearch?: boolean
  span?: number
}

const PositionDashboard: FunctionComponent<Props> = ({onChange, ...props}) => <WrapperRoot
  icon={CollectionIcon}
  label={'form-label-position'}>
  <WrapperItem name={props.name}>
    <BironSelect
      {...props}
      showSearch={true}
      onChange={value => {
        onChange?.(FormItemKeys.POSITION_DASHBOARD, value)
      }}/>
  </WrapperItem>
</WrapperRoot>

export default PositionDashboard

