import React, {FunctionComponent} from 'react'
import {FormItemKeys} from 'commons/keywords/form'
import {WrapperItem, WrapperRoot, BironSelect} from "@biron-data/react-components"
import {FolderIcon} from "@heroicons/react/outline"

interface Props {
  name: string
  value?: any
  options: any[]
  onChange?: (key: FormItemKeys, newValue: string) => void
  span?: number
}

const ParentMenu: FunctionComponent<Props> = ({
                                                name,
                                                options,
                                                onChange,
                                                value,
                                                span,
                                              }) => <WrapperRoot
  icon={FolderIcon}
  label={'form-label-parent-menu'}>
  <WrapperItem name={name}>
    <BironSelect
      name={name}
      value={value}
      options={options}
      showSearch={true}
      span={span}
      onChange={newValue => {
        onChange?.(FormItemKeys.PARENT_MENU, newValue)
      }}/>
  </WrapperItem>
</WrapperRoot>

export default ParentMenu
