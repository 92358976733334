import React, {forwardRef, FunctionComponent, useEffect} from 'react'
import {MetricSelector, Props as MetricSelectorProps} from "@biron-data/react-bqconf"
import {WrapperItem, WrapperRoot} from "@biron-data/react-components"
import {RuleObject} from "antd/lib/form"
import {ChartBarIcon} from "@heroicons/react/outline"
import {RuleRender} from "rc-field-form/lib/interface"
import {useDataDocMetricLink} from "components/dataSourceDoc/DataSource.hooks"

interface Props {
  children: React.ReactNode
  name: string
  rules?: (RuleObject | RuleRender)[]
}

export const MetricsWrapper: FunctionComponent<Props> = ({children, name, rules}) => (
  <WrapperRoot icon={ChartBarIcon}
               label="configuration-label-metrics-single"
               description="configuration-description-metrics">
    <WrapperItem name={name} rules={rules}>
      {children}
    </WrapperItem>
  </WrapperRoot>
)

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const MetricsField = forwardRef<any, MetricSelectorProps>(function Field({...props}, ref) {
  const getDatadocLink = useDataDocMetricLink()
  return <MetricSelector {...props} getMoreInformationLink={getDatadocLink}/>
})
