import React, {useCallback} from "react"
import {NavigateFunction} from "react-router-dom"
import {DashboardTypes, RawPersonalDashboardDto} from "types/dashboards"
import {NormalizedPersonalDashboard, NormalizedWorkspaceDashboard} from "schemas/dashboard"
import {Button, notification} from "antd"
import Language from "language"

export const useDuplicateToPersonalButton = (
    currentEnvironmentId: number,
    navigate: NavigateFunction,
    cloneDashboard: (payload: {dashboardId: number}) => Promise<RawPersonalDashboardDto>,) => {
    return useCallback((
      dashboard: Pick<NormalizedWorkspaceDashboard, "type" | "id" | "title"> | Pick<NormalizedPersonalDashboard, "type" | "id" | "title">,
    ) => <Button type={"primary"} style={{backgroundColor: "#7CE0DA"}} onClick={async () => {
      if (dashboard && dashboard!.type === DashboardTypes.workspace) {
        const newDashboard = await cloneDashboard({dashboardId: dashboard.id})
        notification.info({
          duration: 2.5,
          key: `clone`,
          message: Language.get(`personal-dashboard.popup.clone`, dashboard!.title),
          placement: 'bottomRight',
        })
        navigate(`/app/personal/${currentEnvironmentId}/${newDashboard.uid}`)
      }
    }}>
      {Language.get(`personal-dashboard.header.button.clone`)}
    </Button>, [cloneDashboard, currentEnvironmentId, navigate])
  }
