import React, {useMemo, useRef} from 'react'
import Close from "components/widgets/topper/WidgetTopper.Close"
import Download from "components/widgets/topper/WidgetTopper.Download"
import HelpPopover from "components/widgets/topper/help-popover/HelpPopover"
import Language from 'language'
import Switch from "components/switch/Switch"
import WidgetTopperLink from "components/widgets/topper/WidgetTopper.Link"
import {WidgetTypes} from "commons/dashboard/dashboard.types"
import {
  ChartWithMetricDefLayoutTypes,
  OnSelectionChange,
  OnSnapshot,
} from "components/widgetContainer/WidgetContainer"
import styled from "styled-components"
import {CalendarIcon, ChatAltIcon, CogIcon, SortDescendingIcon} from "@heroicons/react/outline"
import {IconContainer, CopyDuplicate, TooltipPopover} from "@biron-data/react-components"
import {ChartSelection} from "classes/workflows/query-workflows/QueryWorkflow"
import {useGetPopupContainer} from "@biron-data/react-hooks"
import {UseGetChartLayoutForAddType} from "hooks/useGetLayoutForAddChart"
import {EffectiveConfTypes} from "types/charts"


const availableConfSwitches = [
  {
    key: 'displayLabels',
    icon: ChatAltIcon,
  },
  {
    key: 'asPercentage',
    icon: "%",
  },
  {
    key: 'sortSeries',
    icon: SortDescendingIcon,
  },
  {
    key: 'withChartOverriddenPeriod',
    icon: () => <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.66667 5.83333V2.5M13.3333 5.83333V2.5M4.16667 17.5H15.8333C16.7538 17.5 17.5 16.7538 17.5 15.8333V5.83333C17.5 4.91286 16.7538 4.16667 15.8333 4.16667H4.16667C3.24619 4.16667 2.5 4.91286 2.5 5.83333V15.8333C2.5 16.7538 3.24619 17.5 4.16667 17.5Z"
        stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M7 11.2L9.88235 14L14 8" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round"/>
    </svg>
    ,
  },
  {
    key: 'withDateSlicer',
    icon: CalendarIcon,
  },
]

interface Props {
  widgetType: WidgetTypes
  selection: ChartSelection,
  getBqlRequest: () => void
  onSelectionChange: OnSelectionChange
  onSnapshot?: OnSnapshot
  onDownload?: () => any
  onDownloadImage?: () => any
  onConfEdit?: () => any
  onLinkCopy: () => any
  onClose?: () => Promise<void>
  effectiveConf?: EffectiveConfTypes
  chart: ChartWithMetricDefLayoutTypes
  iconColor?: string
  isLinkedTo?: boolean
  getChartLayoutForAdd: ReturnType<UseGetChartLayoutForAddType>
  scrollToChart: (chartId: number) => void
  handleChartDuplicate: () => void,
  onConfCopy: () => void,
  editable?: boolean
}

const WidgetTopper: (props: Props) => JSX.Element = ({
                                                       chart,
                                                       widgetType,
                                                       selection,
                                                       getBqlRequest,
                                                       onSelectionChange,
                                                       effectiveConf,
                                                       onDownload,
                                                       onSnapshot,
                                                       onLinkCopy,
                                                       onConfEdit,
                                                       onClose,
                                                       iconColor = "var(--light-grey)",
                                                       isLinkedTo,
                                                       handleChartDuplicate,
                                                       onConfCopy,
                                                       editable,
                                                     }) => {
  const confSwitches = useMemo(
    () => availableConfSwitches
      // eslint-disable-next-line no-undefined
      .filter(({key}) => selection[key as keyof ChartSelection] !== undefined)
      .map(conf => ({
        ...conf,
        checked: `chartConfSwitches.${conf.key}.off`,
        unchecked: `chartConfSwitches.${conf.key}.on`,
        onChange: () => onSelectionChange({
          ...selection,
          [conf.key]: Boolean(!selection[conf.key as keyof ChartSelection] as any),
        } as ChartSelection),
      }))
      .reverse()
    ,
    [selection, onSelectionChange],
  )

  const contentRef = useRef<HTMLDivElement>(null)
  const getPopupContainer = useGetPopupContainer(contentRef.current)

  return <Content className="widget-topper disable-grab" ref={contentRef} $iconcolor={iconColor} $islinkedto={isLinkedTo ? 1 : 0}>
    {confSwitches.map(confSwitch =>
      <Switch key={`confSwitch-${confSwitch.key}`} switchItem={confSwitch}
              switchState={Boolean(selection[confSwitch.key as keyof ChartSelection])}
              change={confSwitch.onChange}/>,
    )}
    {(
      <WidgetTopperLink key="link" onClick={onLinkCopy}/>
    )}
    {effectiveConf && (
      <HelpPopover key="help" effectiveConf={effectiveConf} chart={chart} onConfEdit={onConfEdit} getBqlRequest={getBqlRequest}/>
    )}
    {onDownload && (
      <Download key="download" onDownload={onDownload} onSnapshot={onSnapshot} getPopupContainer={getPopupContainer}/>
    )}
    {editable && (
      <TooltipPopover size={'small'} content={Language.get('chart-copy-button')}>
        <div>
          <CopyDuplicate handleDuplicate={handleChartDuplicate} onCopy={onConfCopy}/>
        </div>
      </TooltipPopover>
    )}
    {onConfEdit && (
      <TooltipPopover size={'small'} content={Language.get('configuration')}>
        <div>
          <IconContainer clickable={true} hoverColor={"var(--primary-light)"} containerSize={28}><CogIcon className="disable-grab"
                                                                                                          onClick={onConfEdit}/></IconContainer>

        </div>
      </TooltipPopover>
    )}
    {onClose && (
      <Close key="close" onClose={onClose} type={widgetType}/>
    )}
  </Content>
}

export default WidgetTopper

const Content = styled.div<{ $iconcolor: string, $islinkedto?: number }>`
  -webkit-flex-grow: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  flex-shrink: 0;
  -webkit-flex-basis: auto;
  flex-basis: auto;
  -webkit-align-self: auto;
  align-self: auto;
  font-size: 19px;
  line-height: 24px;
  display: flex;
  align-items: center;
    color: ${({$iconcolor}) => $iconcolor};
  cursor: auto;

  @keyframes highlightText {
    from {
        color: ${({$iconcolor}) => $iconcolor};
    }
    to {
      color: var(--dark-text);
    }
  }


    ${props => props.$islinkedto && "animation: 3s linear 2 alternate highlightText;"};
`
