import {createSelector} from "reselect"
import {RootState} from "redux/store"
import {WidgetTypes} from "commons/dashboard/dashboard.types"
import {Export} from "components/forms/Form.types"

export const getExports = createSelector(
  (state: RootState) => state.exports,
  (entities) => entities.map(e => ({
    ...e,
    type: WidgetTypes.EXPORT,
  }) as Export),
)

