import {RootState} from "redux/store"
import {createSelector} from "reselect"
import {ChartDtoDetailTypes} from "types/charts"
import {RematchRootState} from "@rematch/core"
import RootModel from "redux/models"
import {NormalizedPersonalDashboard, NormalizedWorkspaceDashboard} from "schemas/dashboard"
import {DashboardTypes} from "types/dashboards"
import {NormalizedMenu} from "schemas/workspace"
import {CurrentDashboardTypes, CurrentPersonalDashboard} from "redux/models/currentDashboard.types"

export const getMenuId = (state: RootState) => state.currentDashboard?.type === DashboardTypes.workspace ? state.currentDashboard.menuId : undefined
export const getMenu = (state: RootState): NormalizedMenu | undefined => state.workspace.entities.menus[getMenuId(state) as string]
export const getRedirect = (state: RootState) => state.currentDashboard?.type === DashboardTypes.workspace ? state.currentDashboard.redirect : undefined
export const getCurrentDashboard = (state: RootState): CurrentDashboardTypes => state.currentDashboard
export const getSelection = (state: RootState) => state.currentDashboard?.selection
export const getCurrentPersonalDashboard = (state: RootState): CurrentPersonalDashboard | undefined => state.currentDashboard?.type === DashboardTypes.personal ? state.currentDashboard : undefined
export const getDashboardId = (state: RootState) => state.currentDashboard?.dashboardId
export const getWorkspaceDashboard = (state: RootState): NormalizedWorkspaceDashboard | undefined => {
  const dashboardId = getDashboardId(state)
  return (dashboardId !== undefined && dashboardId !== null) ? state.workspace.entities.dashboards[dashboardId] : undefined
}
export const getPersonalDashboard = (state: RootState): NormalizedPersonalDashboard | undefined => {
  return state.currentDashboard?.dashboard?.type === DashboardTypes.personal ? state.currentDashboard?.dashboard : undefined
}

export const getCharts = createSelector(
  (state: RematchRootState<RootModel, Record<string, never>>): {
    [k: string]: ChartDtoDetailTypes
  } => state.currentDashboard.charts ?? {},
  (charts: {
    [k: string]: ChartDtoDetailTypes
  }) => Object.values(charts),
)