import API from './api'

export interface AssistantInteractionDtoDetail {
  id: number,
  humanEvaluation?: HumanEvaluationDto
  systemResponse: string,
}

export enum AnswerQuality {
  MEDIOCRE = "MEDIOCRE",
  BAD = "BAD",
  GOOD = "GOOD"
}

interface HumanEvaluationDto {
  answerQuality: AnswerQuality,
  betterAnswer?: string,
  comment: string,
  evaluatedAt: string,
  evaluatorEmail: string,
  evaluatorId: string,
}

export interface Evaluation {
  answerQuality: AnswerQuality,
  betterAnswer?: string,
  comment?: string,
}

export const generateChartTitle = (chartId: number) =>
  API.instanceData.put<void, AssistantInteractionDtoDetail>(`/chart/${chartId}/generateTitle`)

export const evaluateResponse = (chartId: number, evaluation: Evaluation, interactionId: number) =>
  API.instanceData.put<void, AssistantInteractionDtoDetail>(`/chart/${chartId}/evaluateTitleGeneration`, evaluation, {
    params: {
      interactionId,
    },
  })
