import React, {FunctionComponent} from 'react'
import {FolderIcon} from "@heroicons/react/outline"
import {BironSelect, WrapperRoot, WrapperItem} from "@biron-data/react-components"
import Language from "language"
import {Category as CategoryEnum} from "@biron-data/period-resolver"

interface Props {
  name: string
  value?: CategoryEnum
  onChange?: (newValue: string) => void
  span?: number
}

const Category: FunctionComponent<Props> = ({
                                              name,
                                              onChange,
                                              value,
                                              span,
                                            }) => <WrapperRoot
  icon={FolderIcon}
  label={'form-label-parent-menu'}
  itemColumnWidth={8}>
  <WrapperItem name={name}>
    <BironSelect
      name={"periodCategory"}
      value={value}
      options={Object.values(CategoryEnum).map(v => ({
        label: Language.get(`period.category.${v}`),
        value: v,
      }))}
      showSearch={true}
      span={span}
      onChange={newValue => {
        onChange?.(newValue)
      }}/>
  </WrapperItem>
</WrapperRoot>

export default Category
