// @ts-nocheck   no check as this file should be deleted

/**
 * Axis Common functions
 * all the needed function to build a d3 axis
 */

import {formatForAxis} from 'commons/format/formatter.tsx'
import Language from 'language'
import * as d3 from 'd3'

/**
 * makes d3 X axis for enum scale
 * @param {d3.scale} scale: scale used to generate axis
 * @return {d3.scale} d3 axis
 */
export const xEnumAxis = scale => d3.axisBottom().scale(scale)

/**
 * makes d3 y axis for number scale
 * @param {d3.scale} scale: scale used to generate axis
 * @param {object} format: format object for ticks
 * @param {number} ticks: number of ticks
 * @return {d3.scale} d3 axis
 */
export const yValueAxis = (scale, format, ticks = 5) => d3.axisLeft()
  .scale(scale)
  .ticks(ticks)
  .tickFormat(formatForAxis(format, Language.getFormatter()))

/** animation time */
export const transitionTime = 500