import React, {memo} from "react"
import {Checkbox} from 'antd'
import styled from "styled-components"

interface CheckboxSelectorProps {
  value?: boolean
  onChange?: (newValue: boolean) => void
  message?: {
    checked: string
    notchecked: string
  }
}

const CheckboxSelector = ({
                            value,
                            onChange,
                            message,
                          }: CheckboxSelectorProps) => <FlexRow>
  <Checkbox checked={value} onChange={() => onChange?.(!value)}/>
  <Message>
    {
      value ? message?.checked : message?.notchecked
    }
  </Message>
</FlexRow>

export default memo(CheckboxSelector)

const FlexRow = styled.div`
  display: flex;
  align-items: center;
  `

const Message = styled.div`
  margin-left: 10px;
  font-size: 12px;
  line-height: 16px;
  color: var(--medium-grey);
  `