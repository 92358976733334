import React, {FunctionComponent, PropsWithChildren} from 'react'
import {ClockIcon} from "@heroicons/react/outline"
import {WrapperItem, WrapperRoot} from "@biron-data/react-components"
import {RuleObject} from "antd/lib/form"
import {RuleRender} from "rc-field-form/lib/interface"

interface WrapperProps extends PropsWithChildren {
  name: string
  withDescription?: boolean
  rules?: (RuleObject | RuleRender)[]
  itemColumnWidth?: number
}

export const PeriodWrapper: FunctionComponent<WrapperProps> = ({itemColumnWidth, children, name, withDescription = false, rules}) => (
  <WrapperRoot {...{
    itemColumnWidth,
    ...(withDescription ? {
      icon: ClockIcon,
      label: "configuration-label-period",
    } : {}),
  }}>
    <WrapperItem name={name} rules={rules}>
      {children}
    </WrapperItem>
  </WrapperRoot>
)


