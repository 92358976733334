import {GenericEffectiveConf, Param, SerieType} from "components/charts/line/LineChart.types"
import {standardTooltipOptions} from "components/charts/Chart.options"
import {formatAxisDate} from "commons/format/formatter"
import {SerieInfo, tableOfContent, tooltipMetricHeader} from "components/charts/Chart.utils"
import {tooltipLineFormat, tooltipLineWithStyle} from "components/charts/Chart.tooltipContent"
import {Hoverdata} from "components/charts/bar/BarChart"
import Tooltip = echarts.EChartOption.Tooltip
import {Format} from "@biron-data/react-bqconf"

export const HTML_DIVIDER = '<hr style="color:var(--border-color-input);background-color:var(--border-color-input);height: 1px;border-width:0;line-height: 0.5;margin-left: 10px;margin-right: 10px"/>'

export const getCategoryAxisIndex = (params: Param[]): number => {
  if (params.length === 0) {
    return -1
  }
  return typeof params[0].data[0] === "string" ? 0 : 1
}

const getAxisValue = (
  effectiveConf: Pick<GenericEffectiveConf, "slicers" | "metrics">,
  params: Param[],
) => {
  const categoryAxisIndex = getCategoryAxisIndex(params)
  const referenceData: string = params.length > 0 ? String(params[0].data[categoryAxisIndex]) : ""
  return effectiveConf.slicers[0]?.type === "date" ? formatAxisDate(String(referenceData)) : referenceData
}
export const tooltipFormatter = (
  yAxisLength: number,
  seriesMappedToAxis: Pick<SerieInfo<SerieType>, "format" | "axisIndex">[],
  effectiveConf: Pick<GenericEffectiveConf, "slicers" | "metrics">,
  formats: Format[],
  alternativeFormats: Format[],
  params: Param[],
  total?: number,
  hoverData?: Hoverdata,
) => {
  const tooltipString: string[] = [
    tooltipMetricHeader(effectiveConf),
  ]
  const axis = getAxisValue(effectiveConf, params)

  if (params.length === 1) {
    tooltipString.push(tooltipLineFormat(formats[0], alternativeFormats[0], params[0].marker, axis, params[0].data[3], total, undefined, params[0].data[4]))
    return tooltipString.join('')
  }
  tooltipString.push(tooltipLineWithStyle(axis))
  tooltipString.push(HTML_DIVIDER)

  tooltipString.push(tableOfContent(yAxisLength, seriesMappedToAxis, effectiveConf.metrics.length, alternativeFormats, params, hoverData?.seriesIndex, total))

  return tooltipString.join('')
}

export const tooltipBaseParameters: () => Tooltip = () => ({
  trigger: "axis",
  ...standardTooltipOptions(),
  order: "valueDesc",
  position(pos, params, el, elRect: any, size: any) {
    const mouseX = Number(pos[0])
    const mouseY = Number(pos[1]) - (size.viewSize[1] / 6)
    const offset = 60
    const mouseOnLeftSide = mouseX < size.viewSize[0] / 2
    const resultX = mouseOnLeftSide ? mouseX + offset : mouseX - offset - el.getBoundingClientRect().width
    return [resultX, mouseY]
  },
})

