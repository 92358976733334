import React from 'react'
import {Navigate, Route, Routes} from "react-router-dom"
import UserManager from "components/admin/user/UserManager"
import styled from "styled-components"
import ExportManager from "components/admin/exports/ExportManager"
import PeriodManager from "components/admin/periods/PeriodManager"

const StyledDiv = styled.div`
  background: white;
  padding: 15px;
  min-height: 100%;
  overflow-y: auto;
`

const AdminHome = () => {
  return <StyledDiv>
    <Routes>
      <Route path="user" element={<UserManager/>}/>
      <Route path="exports" element={<ExportManager/>}/>
      <Route path="periods" element={<PeriodManager/>}/>
      <Route
        path="*"
        element={<Navigate to="user" replace/>}
      />
    </Routes>
  </StyledDiv>
}

export default AdminHome;
