import Language from "language"
import React, {memo} from "react"
import styled from "styled-components"
import {Breadcrumb} from "antd"
import {DataSourceObjectType} from "components/dataSourceDoc/DataSource.types"
import {TableLink, ColorizedTag} from "@biron-data/react-components"
import {useTypeRepresentationColor} from "components/dataSourceDoc/DataSource.hooks"

interface DatadocBeardcrumbProps {
  alias?: string
  type?: DataSourceObjectType
  code?: string
}

// eslint-disable-next-line react/display-name
const DatadocBreadcrumb = memo<DatadocBeardcrumbProps>(({
                                                          alias, type, code,
                                                        }) => {
  const getTypeRepresentationColor = useTypeRepresentationColor()

  return <>
    <StyledBreadcrumb items={[
      {
        title: <TableLink
          link={`${location.pathname.substring(0, location.pathname.indexOf("help"))}help`}>{Language.get('dataDoc.nav.name')}</TableLink>,
      },
      ...(code ? [{
        title: <AliasContainer>{alias}</AliasContainer>,
      }] : []),
      ...(type ? [{
        title: <TypeContainer>
          <div><ColorizedTag text={Language.get("dataDoc.nav.type", type)} color={getTypeRepresentationColor(type)}/></div>
        </TypeContainer>,
      }] : []),
    ]}>
    </StyledBreadcrumb>
  </>
})

export default DatadocBreadcrumb

const AliasContainer = styled.div`
    color: var(--primary);
`

const TypeContainer = styled.div`
    font-size: 14px;
    line-height: 20px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    min-width: 100px;
`

const StyledBreadcrumb = styled(Breadcrumb)`
    font-size: 30px;
    margin-bottom: 15px;
    font-weight: 500;
    color: var(--dark-text);

    a {
        color: var(--dark-text);
    }

    @media (max-width: 1600px) {
        font-size: 14px;
    }

    .ant-breadcrumb-separator {
        color: var(--light-grey-2);
    }
`