/*
 * Superclass pattern that manage promises management and setState to a
 * component while managing its mounted/unmounted "state"
 * WARNING: state here refers to a generic term, and not a React.Component.state
 * this class is used as a base workflow in order to avoid momory leak by calling setState
 * to unmounted components.
 * Basically the superclass has 3 purposes :
 *  1- group all the promises of the workflow in a single place
 *  2- group all the setState calls of the workflow in a single place
 *  3- unsubscribe the component to its workflow (most likely because it has been unmounted),
 *     and hence blocking the setState calls and cancelling the promises
 * However ths class is not a workflow "as is", and must be extended by a real workflow,
 * which calls the functions setState() and promises in order to pass the checks defined here
 */
import {FormType} from "components/forms/Form.types"

export default class Workflow {
  private readonly name
  protected formType
  private readonly setStateFct
  private isMounted

  /**
   * Build the base workflow
   * @param {string} name: name of the 'real workflow' component,
   *        in order to identify it, in case, for example, of errors
   * @param {function} setStateFct: component.setState function pointer
   * @param {string} formType: formType name,
   */
  constructor(name: string, setStateFct: (newState: any) => void, formType?: FormType) {
    this.name = name
    this.formType = formType
    this.setStateFct = setStateFct
    this.isMounted = true
  }

  setState(state: any) {
    if (this.isMounted) {
      // @ts-ignore
      this.setStateFct(state, this)
    } else {
      // eslint-disable-next-line no-console
      console.error(`Blocked setState on unmounted ${this.name} component`)
    }
  }

  unsubscribe() {
    this.isMounted = false;
  }

}
