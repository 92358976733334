import React, {memo} from 'react'
import {Loader, WrapperItem, WrapperRoot} from "@biron-data/react-components"
import {FormBodyComponentProps} from "components/forms/confBodies/FormBody"
import {Input} from "antd";
import {PencilIcon} from "@heroicons/react/outline";
import Language from "../../../language";

const WorkspaceConf = <T, >({
                                   loading,
                                 }: FormBodyComponentProps<T>) => {
  return <Loader loading={loading}>
      <WrapperRoot
          icon={PencilIcon}
          label={'workspaces.form.workspace.name.title'}>
          <WrapperItem name={"name"}>
              <Input placeholder={Language.get('workspaces.form.workspace.name.placeholder')}/>
          </WrapperItem>
      </WrapperRoot>
  </Loader>
}

export default memo(WorkspaceConf) as typeof WorkspaceConf
