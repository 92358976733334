import {WidgetTypes} from "commons/dashboard/dashboard.types"
import {ChartSelection} from "classes/workflows/query-workflows/QueryWorkflow"
import {ChartDtoDetailTypes} from "types/charts"
import {serializeChart} from "services/api"
import {consolidateFilters, consolidateSlicers, getMetricTimeInterval} from "commons/parsers/utils"
import {consolidateTimeInterval, extractSlicerDate, FilterDtoDetailTypes,
  DataSelection,
  GenericChartTypes,
  MetricDtoDetail,
  SlicerDtoDetailTypes} from "@biron-data/react-bqconf"
import {isEmpty} from "@biron-data/react-components"
import {PeriodTypes, TimeInterval, getDayDifference, dayjs} from "@biron-data/period-resolver"

export interface ChartQPTimeIntervalWithSource {
  source?: string
  start: string
  end: string
}

// As they are query param they should always be optional, but it is not
export type ChartQueryParams = {
  pageSize?: number
  pageOffset?: number
  fulltextFilter?: string
  orderBys: { asc?: boolean; column?: number }[]
  filters: FilterDtoDetailTypes[]
  slicers: SlicerDtoDetailTypes[]
  metrics: (Omit<MetricDtoDetail, "overridePeriod"> & { overridePeriod: TimeInterval | undefined }) []
  timeInterval?: ChartQPTimeIntervalWithSource,
  withTotals?: boolean,
  withTotalRowCount?: boolean,
}

const adjustTimeIntervalEdgeOrder = (start: string, end: string) => dayjs(start).isBefore(dayjs(end)) ? {
  start,
  end,
} : {
  start: end,
  end: start,
}

export function getChartQueryParams(dashboardSelection: DataSelection, chartSelection: Partial<ChartSelection> = {}, chart: ChartDtoDetailTypes): ChartQueryParams {
  const {pagination, search, sorters, withDateSlicer, withChartOverriddenPeriod} = chartSelection
  let metrics: ChartQueryParams["metrics"] = []
  let slicers: ChartQueryParams["slicers"] = []
  let filters: ChartQueryParams["filters"] = []
  const period: PeriodTypes | undefined = chart.type === WidgetTypes.DIVIDER ? undefined : chart.period

  const consolidatedTimeIntervalWithSource = consolidateTimeInterval({
    dashboardSelection,
    chartPeriod: withChartOverriddenPeriod ? period : undefined,
  }, chartSelection.withChartOverriddenPeriod)
  const timeInterval = adjustTimeIntervalEdgeOrder(consolidatedTimeIntervalWithSource.start, consolidatedTimeIntervalWithSource.end)

  switch (chart.type) {
    case WidgetTypes.GENERIC: {
      metrics = chart.metrics.map(({
                                     viewCode,
                                     viewAlias,
                                     metricCode,
                                     metricAlias,
                                     growth,
                                     having,
                                     extraConf,
                                     overridePeriod,
                                     additionalFilters,
                                     titlePartOverrides,
                                   }) => {
        const consolidatedMetricTimeInterval = getMetricTimeInterval(overridePeriod)
        const metricTimeInterval = consolidatedMetricTimeInterval ? adjustTimeIntervalEdgeOrder(consolidatedMetricTimeInterval.start, consolidatedMetricTimeInterval.end) : undefined

        return {
          viewCode,
          viewAlias,
          metricCode,
          metricAlias,
          growth: growth ? {
            ...growth,
            period: {
              quantity: growth.period.quantity ?? getDayDifference(consolidateTimeInterval({
                dashboardSelection,
                chartPeriod: chart.period,
              }, chartSelection.withChartOverriddenPeriod)),
              unit: growth.period.unit,
            },
          } : undefined,
          extraConf,
          having,
          overridePeriod: metricTimeInterval,
          additionalFilters,
          titlePartOverrides,
        }
      })
      slicers = chart.slicers
      filters = chart.filters
      break
    }
    default:
  }

  const chartQP: ChartQueryParams = serializeChart({
    filters: consolidateFilters(dashboardSelection, filters),
    slicers: consolidateSlicers(slicers, dashboardSelection, withDateSlicer === undefined ? Boolean(extractSlicerDate(slicers)) : Boolean(withDateSlicer), chartSelection.withChartOverriddenPeriod),
    metrics,
    orderBys: sorters && chart.extraConf.displayType && [GenericChartTypes.BOXES, GenericChartTypes.TABLES].includes(chart.extraConf.displayType)
      ? sorters : [{
        column: slicers.length,
        asc: false,
      }],
    fulltextFilter: search,
    ignoreMetrics0: chart.type === WidgetTypes.GENERIC && chart.ignoreMetrics0,
    timeInterval,
  })

  if (pagination && pagination.current) {
    chartQP.pageOffset = (pagination?.current - 1) * (isEmpty(pagination.pageSize) ? 10 : pagination.pageSize)
    chartQP.pageSize = Math.max(500, pagination?.pageSize)
  }

  return chartQP
}