/* eslint-disable max-lines */

import {DimensionOption, GenericChartTypes, MetaModel, MetaModelView} from "@biron-data/react-bqconf"
import {useCallback} from "react"
import {ConfigCache, GenericExtendedConfModel} from "components/forms/chart/types"
import {createCache} from "components/forms/chart/cache"

export const useOnValuesChange = (
  slicerHasBeenModifiedStateHandler: (newState: boolean) => void,
  displayTypeHandler: (value: GenericChartTypes | undefined) => void,
  data: GenericExtendedConfModel,
  setCache: React.Dispatch<React.SetStateAction<ConfigCache>>,
  onValuesChange: (changedValues: Partial<GenericExtendedConfModel>) => void,
  metaModel: MetaModel,
  viewsWithMetrics: MetaModelView[],
  dimensionOption: DimensionOption[],
  displayType: GenericChartTypes | undefined
) => useCallback((value: any) => {
    const newValues = {
      ...data,
      ...value,
    }
    if (Object.keys(value)[0] === "displayType") {
      displayTypeHandler(value.displayType)
      slicerHasBeenModifiedStateHandler(false)
    } else if (Object.keys(value)[0] !== "format") {
      if (Object.keys(value)[0] === "slicers" && [GenericChartTypes.LINE, GenericChartTypes.AREA].includes(data.displayType)) {
        slicerHasBeenModifiedStateHandler(true)
      }

      setCache(createCache(
        metaModel,
        viewsWithMetrics,
        dimensionOption,
        newValues.metrics,
        newValues.slicers,
        newValues.metricFilters,
        newValues.orderBys,
        newValues.limits,
        displayType,
        newValues.displayLabels,
        newValues.ignoreMetrics0,
        newValues.asPercentage,))
    }
    onValuesChange(value)
  }, [data, dimensionOption, displayType, displayTypeHandler, metaModel, onValuesChange, setCache, slicerHasBeenModifiedStateHandler, viewsWithMetrics])
