import {unstable_batchedUpdates} from 'react-dom' // eslint-disable-line camelcase
import {isArray} from 'lodash'

export const batch = (actionOrActions: any) => {
  const actions = isArray(actionOrActions) ? actionOrActions : [actionOrActions]
  const results: any = unstable_batchedUpdates(() => actions.filter(Boolean).map(action => action()))
  return isArray(actionOrActions) ? results : results[0]
}

export const buildAndBatch = (actionBuilders: any) => Promise.all(actionBuilders)
  .then(actions => batch(actions))

export const returnOrExecBatch = (simplyReturn: any, ...fns: any) => {
  const action = fns.length === 1 ? fns[0] : () => fns.forEach((fn: any) => fn())
  // eslint-disable-next-line no-nested-ternary
  return simplyReturn ? action : (fns.length === 1 ? action() : unstable_batchedUpdates(action))
}
