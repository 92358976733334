import React, {memo} from 'react'
import Language from 'language'
import {Pagination} from 'antd'
import styled from "styled-components"

interface Props {
  current: number
  pageSize: number
  onChange: (page: number, pageSize: number) => void
  lineCount: number
}

const TableFooter = memo<Props>(function TableFooter({current, pageSize, onChange, lineCount}) {
  return <FooterContainer>
    <LineCount>
      {Language.get('table-footer-lines-count').replace('#', `${lineCount}`)}
    </LineCount>
    <PaginationWrapper>
      <Pagination
        size={"small"}
        current={current}
        pageSize={pageSize}
        showSizeChanger={false}
        total={lineCount}
        onChange={onChange}/>
    </PaginationWrapper>
  </FooterContainer>
})

export default TableFooter

const PaginationWrapper = styled.div`
  flex: 1 0;
  display: flex;
  justify-content: end;
  align-items: center;    
  
  ul {
    float: right;
  }

  a:hover {
    text-decoration: none !important;
  }
  
  // override antd pagination line height in order to reduce height when on two lines
  &&& .ant-pagination {
    line-height: 20px;
  }
`

const FooterContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  overflow: hidden;
    margin-top: 15px;
    line-height: var(--line-height);
`

const LineCount = styled.div`
  display: flex;
  flex: 1 1;
  color: var(--medium-grey);
`