import * as _ from "lodash"
import {omit} from "lodash"
import axios, {AxiosError} from 'axios'
import {notification} from 'antd'
import {captureError} from "services/SentryService"
import {WidgetTypes} from "commons/dashboard/dashboard.types"

let on401: () => any

const baseURL = import.meta.env.VITE_API_URL ?? ""

export class BusinessEx {
  title: string
  type: string
  detail?: string
  cause?: Error
  status: number
  // eslint-disable-next-line camelcase
  detail_horatioCause?: string
  violations?: { field: string, message: string }[]

  constructor(title: string, type: string, status: number, detail?: string,
              cause?: Error,
              // eslint-disable-next-line camelcase
              detail_horatioCause?: string, violations?: { field: string, message: string }[]) {
    this.title = title
    this.type = type
    this.status = status
    this.detail = detail
    this.cause = cause
    // eslint-disable-next-line camelcase
    this.detail_horatioCause = detail_horatioCause
    this.violations = violations
  }
}

const interceptError = (statusCode: number, data: BusinessEx) => {
  if (statusCode === 500 || statusCode === 403) {
    notification.error({
      message: data.title,
      description: data.detail,
      placement: 'bottomRight',
      duration: 0,
    })
    captureError(statusCode === 500 ? "unexpected server error" : "forbidden operation", data)
  }
  if (statusCode === 400 && window.location.pathname.endsWith("/workspaces")) {
    notification.error({
      message: data.title,
      description: data.detail,
      placement: 'bottomRight',
      duration: 0,
    })
    captureError("bad request", data)
  }
}

const onRejected = (error: AxiosError<BusinessEx>) => {
  const {response} = error
  const {status: statusCode, data} = response || {}
  if (statusCode === 401) {
    return on401()
  } else if (data && statusCode) {
    // @ts-ignore
    if (!error.config.failSilently) {
      interceptError(statusCode, data)
    }
    return Promise.reject(new BusinessEx(
      data.title,
      data.type,
      data.status,
      data.detail,
      data.cause,
      // eslint-disable-next-line camelcase
      data.detail_horatioCause,
      data.violations))
  } else {
    captureError("unknown error", error)
    return Promise.reject(error)
  }
}

const instanceData = axios.create({baseURL})
instanceData.interceptors.response.use(response => response.data, onRejected)

export const buildUrl = (conf: {
  path: string
}) => {
  let builtUrl = baseURL
  if (builtUrl && builtUrl[builtUrl.length - 1] === '/') {
    builtUrl = builtUrl.slice(0, -1)
  }
  const localVar = conf.path.trim()
  if (localVar.indexOf('/') === 0) {
    builtUrl += localVar
  } else {
    builtUrl += `/${localVar}`
  }
  return builtUrl
}

const setToken = (accessToken: string) => {
  instanceData.defaults.headers.common.Authorization = accessToken
}

const onAuthRequired = (callback: () => any): any => {
  on401 = callback
}

// Recursively parse or stringify 'extraConf' keys that contain front-defined persisting data
export const deserializeExtraConf = <T>(obj: T): T => handleExtraConf(obj, JSON.parse, {})
export const serializeChart = (obj: any) => {
  switch (obj.type) {
    case WidgetTypes.GENERIC:
      return handleExtraConf({
        ...omit(obj, ["period", "slicers", "filters", "metrics", "orderBys", "extraConf.sorts", "extraConf.ignoreMetrics0"]),
        bqConf: {
          period: obj.period,
          slicers: obj.slicers,
          filters: obj.filters,
          metrics: obj.metrics,
          orderBys: obj.orderBys,
          ignoreMetrics0: obj.ignoreMetrics0,
        },
      }, JSON.stringify, "")
    default:
      return handleExtraConf(obj, JSON.stringify, "")
  }
}

// Could be improved by not having to clone original object
export function handleExtraConf<T>(obj: T, fn: (param: any) => any, defaultValue: "" | Record<never, never>): T {
  if (_.isArray(obj)) {
    return obj.map((value) => value ? handleExtraConf(value, fn, defaultValue) : value) as T
  }

  if (obj && typeof obj === 'object') {
    const clone = _.cloneDeep(obj)
    Object.keys(clone).forEach((key) => {
      if (key === "extraConf") {
        try {
          // @ts-ignore
          if (clone[key]) {
            // @ts-ignore
            clone[key] = fn(clone[key])
          } else {
            // @ts-ignore
            clone[key] = defaultValue
          }
        } catch (e) {
          if (e instanceof Error) {
            captureError("JSON parse extraConf", [], e)
          }
        }
      } else {
        // @ts-ignore
        if (typeof clone[key] === "object" && clone[key]) {
          // @ts-ignore
          clone[key] = handleExtraConf(clone[key], fn, defaultValue)
        }
      }
    })
    return clone
  }
  return obj
}

export default {
  instanceData,
  setToken,
  onAuthRequired,
}
