import React, {FunctionComponent, useCallback, useMemo} from 'react'
import FormChartGenericCmp from "components/forms/chart/FormChartGenericCmp"
import {loadDictionaryEntries as loadDictionaryEntriesService} from "services/MetaModelService"
import {WidgetTypes} from "commons/dashboard/dashboard.types"
import FormChartTargetCmp from "components/forms/chart/FormChartTargetCmp"
import {ChartFormProps} from "components/forms/Form.types"
import {OptionListPagination} from '@biron-data/react-bqconf'

const FormChart: FunctionComponent<ChartFormProps> = ({
                                                        data,
                                                        environmentId,
                                                        metaModel,
                                                        onConfirm,
                                                        dashboardSelection,
                                                        ...props
                                                      }) => {
  const loadDictionaryEntries = useCallback(
    (dictionaryCode: string, search?: string, pagination?: OptionListPagination) => loadDictionaryEntriesService(environmentId, dictionaryCode, search, pagination),
    [environmentId],
  )

  const formProps = useMemo(() => ({
    ...props,
    environmentId,
    metaModel,
    loadDictionaryEntries,
    onConfirm,
  }), [environmentId, loadDictionaryEntries, metaModel, onConfirm, props])

  switch(data.type) {
    case WidgetTypes.TARGET:
      return <FormChartTargetCmp {...{
        ...formProps,
        data,
        dashboardSelection,
      }}/>
    case WidgetTypes.GENERIC: {
      if (formProps.dashboardId) {
        return <FormChartGenericCmp {...{
          ...formProps,
          dashboardId: formProps.dashboardId,
          data,
          dashboardSelection,
        }}/>
      }
      return null
    }
    case WidgetTypes.DIVIDER:
      return null
    default:
      return null
  }
}

export default FormChart
