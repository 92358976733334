import React from 'react'
import SimpleTooltipChart from 'components/charts/tooltip/SimpleTooltip.Chart'
import TargetChartBars from 'components/charts/target/TargetChart.Bars'
import TargetChartWorkflow from 'classes/workflows/chart-workflows/TargetChartWorkflow'
import Language from "language"
import {Alert} from 'antd'
import ChartSummary from "components/charts/Chart.Summary"
import {DimensionGlobal} from "classes/workflows/chart-workflows/ChartWorkflow"
import {ShapeDimension} from "types/charts"
import {TargetQueryWorkflowResult} from "classes/workflows/query-workflows/QueryWorkflow"
import {State} from "classes/workflows/main-workflows/FormWorkflow"
import {xEnumAxis, yValueAxis} from "components/charts/target/TargetChart.utils"

interface Props {
  withSummary: boolean,
  chartData: TargetQueryWorkflowResult,
  dimensions: ShapeDimension,
}

const defaultDimension = {
  height: 150,
  width: 300,
}

class Target extends React.Component<Props, any> {
  wrapper?: HTMLDivElement | null
  // @ts-ignore
  workflow: TargetChartWorkflow

  constructor(props: Props) {
    super(props)
    this.state = {}
  }

  /**
   * move tooltip event listener
   * @param {object} tooltipPosition : {x,y} object corresponding to the mouse position
   * @return {null} update the tooltipPosition
   */
  moveTooltip = (tooltipPosition: { x: number, y: number }) => {

    /*
     * tooltipExceed is to determine if the tooltip would "go out of the chart" if displayed,
     * if it is the case, we print to the left of the mouse
     */
    const tooltipExceed = tooltipPosition.x + 150 > (this.wrapper?.getBoundingClientRect().x ?? 0) + (this.wrapper?.getBoundingClientRect().width ?? 0)
    tooltipPosition.x = tooltipExceed ? tooltipPosition.x - 10 - window.innerWidth : tooltipPosition.x + 10
    tooltipPosition.y -= 10
    this.setState({tooltipPosition})
  }

  /**
   * process and compute the scales of the barchart
   * @param {boolean} tooltipView : boolean for displaying or not the tooltip
   * @return {null} update the tooltipView
   */
  viewTooltip = (tooltipView: boolean) => this.setState({tooltipView})

  /**
   * update tooltip's data
   * @param {object} tooltipData : data to print in the tooltip
   * @return {null} set the state
   */
  focusTooltip = (tooltipData: string | number) => this.setState({tooltipData})

  heightUpdate = (text: string, dims: DimensionGlobal) => {
    if (this.workflow) {
      this.workflow.updateDimensions(text, dims)
    } else {
      setTimeout(() => {
        this.heightUpdate(text, dims)
      }, 100)
    }
  }

  /**
   * process and compute the dimensions of the svgWrapper
   * @return {object} next dimensions of the svgWrapper (dimensions from props minus enforced legend height)
   */
  svgDimensions = () => {
    const dimension = (
      (this.state.dimensions || {}).chartDimensions || {
        width: this.state.dimensions ? this.state.dimensions.global.width : (this.props.dimensions.width ?? defaultDimension.width),
        height: this.state.dimensions
          ? this.state.dimensions.global.height
          : (this.props.dimensions.height ?? defaultDimension.height),
      })
    return {
      ...dimension,
      height: dimension.height + 30,
    }
  }

  /**
   * process and compute the (x,y) axis of the barchart
   * @param {string} key : x or y
   * @param {object} scale : {x,y} scale object of state
   * @param {object} offset : {x,y} offset object of state
   * @return {object} the {scale,offset,axis} object used to determine the x axis by the svg wrapper
   */
  axisObject = (key: any, scale: any, offset: any) => {
    const {meta = {}} = this.props.chartData
    return {
      scale: scale[key],
      offset: offset[key],
      axis: key === 'x' ? xEnumAxis(scale[key]) : yValueAxis(scale[key], (meta as any).format),
    }
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    this.workflow.update(nextProps.chartData, 'global', nextProps.dimensions)
  }

  componentDidMount() {
    this.workflow = new TargetChartWorkflow(
      '', {
        height: (this.props.dimensions.height ?? defaultDimension.height),
        width: (this.props.dimensions.width ?? defaultDimension.width) - 2,
      },
      this.props.chartData,
      (state: State) => {
        this.setState(state)
      });
  }

  componentWillUnmount() {
    if (this.workflow) {
      this.workflow.unsubscribe()
    }
  }

  render() {
    const {tooltipView, tooltipPosition, tooltipData, scale, chartData = {}} = this.state
    const {series, meta, noTargetConfigured} = chartData
    return series
      ? <div ref={ref => {
        this.wrapper = ref
      }} style={this.props.dimensions ?? defaultDimension}>
        {this.props.withSummary && <ChartSummary effectiveConf={meta.effectiveConf}/>}
        {noTargetConfigured
          ? <Alert type="info" message={Language.get('no-target-configured')} banner/>
          : <React.Fragment>
            <svg style={this.svgDimensions()}>
              <TargetChartBars ref="bars" scale={scale} data={series} format={meta.format}
                               moveTooltip={this.moveTooltip} viewTooltip={this.viewTooltip} focusTooltip={this.focusTooltip}
                               height={this.svgDimensions().height}/>
            </svg>
            {tooltipView && <SimpleTooltipChart position={tooltipPosition} data={tooltipData} total={0} format={meta.format}/>}
          </React.Fragment>
        }
      </div>
      : null
  }
}

export default Target;
