import React, {forwardRef, FunctionComponent, PropsWithChildren} from 'react'
import {SlicerSelector} from "@biron-data/react-bqconf"
import {WrapperItem, WrapperRoot} from "@biron-data/react-components"
import {ScissorsIcon} from "@heroicons/react/outline"
import {useDataDocDimensionLink} from "components/dataSourceDoc/DataSource.hooks"

interface WrapperProps extends PropsWithChildren {
  name: string
}

export const SlicersWrapper: FunctionComponent<WrapperProps> = ({children, name}) => (
  <WrapperRoot {...{
    icon: ScissorsIcon,
    label: "configuration-label-slicers",
    description: "configuration-description-slicer",
  }}><WrapperItem name={name}>
    {children}
  </WrapperItem>
  </WrapperRoot>
)

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const SlicersField = forwardRef<any, any>(function Field({...props}, ref) {
  const getDatadocLink = useDataDocDimensionLink()

  return props.value ? <SlicerSelector {...props} getMoreInformationLink={getDatadocLink} /> : <></>
})
