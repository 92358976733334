import {WidgetTypes} from "commons/dashboard/dashboard.types"
import {ChartDtoDetail, ChartDtoDetailTypes} from "types/charts"
import {Layout} from "react-grid-layout"
import {WidgetRef} from "components/widgets/Widget"
import {GenericChartTypes} from "@biron-data/react-bqconf"

export const rowHeight = 56
export const gridCols = {
  lg: 6,
  md: 6,
  sm: 2,
  xs: 2,
  xxs: 2,
}

export type GetWidgetRef = (chartIndex: number) => WidgetRef | undefined

export function generateLayout(sortedCharts: ChartDtoDetailTypes[], getWidgetRef: GetWidgetRef): {
  sm: Layout[],
  lg: Layout[]
} {
  const lg = sortedCharts.map((chart, index) => {
    const h = widgetHeight(index, chart, getWidgetRef, false)
    return {
      i: String(chart.id),
      x: chart.x || 0,
      y: chart.y || 0,
      w: chart.w || 2,
      minW: 2,
      maxW: 6,
      h,
      minH: Math.min(h, widgetMinHeight(chart)),
      maxH: widgetMaxHeight(chart),
    };
  });
  // we have the 'regular layout', now we compute the responsive one -> note that charts are already sorted by position
  const {acc: sm} = [...lg]
    .reduce(({acc, y}: { acc: Layout[], y: number }, li, chartIndex) => {
      const h = widgetHeight(chartIndex, sortedCharts[chartIndex], getWidgetRef, true)
      return {
        acc: [...acc, {
          i: li.i,
          x: 0,
          y,
          w: 2,
          h,
          static: true,
        }],
        y: y + h,
      }
    }, {
      acc: [],
      y: 0,
    });
  return {
    lg,
    sm,
  }
}

export const widgetHeight = (index: number, chart: ChartDtoDetailTypes, getWidgetRef: GetWidgetRef, isSm: boolean) => {
  const defaultValue = chart.h || 3
  switch (chart.type) {
    case WidgetTypes.TARGET:
      return 3
    case WidgetTypes.GENERIC:
      switch (chart.extraConf.displayType) {
        case GenericChartTypes.TABLES:
          if (chart.metrics.length > 0) {
            return Math.max(chart.h || 0, isSm ? 9 : 6)
          }
          return defaultValue
        default:
          return defaultValue
      }
    case WidgetTypes.DIVIDER: {
      const dynamicHeight = getWidgetRef(index)?.getDynamicHeight()
      if (dynamicHeight && chart.extraConf.description && chart.extraConf.description.trim().length !== 0 && chart.h === 1) {
        const computedHeight = Math.ceil(dynamicHeight / rowHeight)
        if (computedHeight > 3) {
          return 6
        } else {
          return 3
        }
      }
      return chart.extraConf.description ? defaultValue : 1
    }
    default:
      return defaultValue
  }
}

const widgetMinHeight = (chart: ChartDtoDetail) => {
  if (chart.type === WidgetTypes.DIVIDER) {
    return 2
  } else if (chart.extraConf.displayType === GenericChartTypes.TABLES) {
    return 6
  } else {
    return 3
  }
}

const widgetMaxHeight = (chart: ChartDtoDetail) => {
  if (chart.type === WidgetTypes.DIVIDER) {
    return 15
  } else if (chart.extraConf.displayType === GenericChartTypes.BOXES) {
    return 9
  } else if (chart.type === WidgetTypes.TARGET) {
    return 3
  } else if (chart.w === 6) {
    return Infinity
  } else if (chart.extraConf.displayType === GenericChartTypes.TABLES) {
    return 15
  } else {
    return 9
  }
}

// Expects to receive a string under the format "#<id>-<name>"
export function hashLinkToChartId(hashLink?: string, separator = '-') {
  if (!hashLink || hashLink.length < 1) {
    return -1
  }
  try {
    const [idxSep, decoded] = getIndexOfSeparatorAndProcessedUrl(hashLink, separator)
    if (idxSep < 1) {
      return -1
    }
    // We return chart id that is before "-"
    return Number(decoded.substr(0, idxSep))
  } catch (e) {
    return -1
  }
}

const getIndexOfSeparatorAndProcessedUrl = (link: string, separator: string): [separatorIndex: number, processedUrl: string] => {
  if (encodeURI(separator) === separator) {
    const processed = link.substr(1)
    return [processed.indexOf(separator), processed]
  } else {
    const decoded = decodeURI(link.substr(1))
    return [decoded.indexOf(separator), decoded]
  }
}
