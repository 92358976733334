import React, {forwardRef} from "react"
import {ELineChartProps} from "components/charts/line/LineChart.types"
import EChartContainer from "components/charts/Chart.Container"
import {ELineChart, GenericEChartProps} from "components/charts/line/LineChart"

export interface EAreaChartProps extends ELineChartProps {
  asArea: true
}

const AreaChart = forwardRef<any, GenericEChartProps>(function AreaChart(props, ref) {
  return <EChartContainer ref={ref}
                          {...props}
                          chart={ELineChart}
                          rawChartData={props.chartData}
                          asArea/>
})

export default AreaChart
