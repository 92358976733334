import React, {FunctionComponent, useCallback, useMemo} from 'react'
import IntervalSelector from './intervalSelector/IntervalSelector'
import GranularitySelector from './GranularitySelector'
import styled from "styled-components"
import {captureWarn} from "services/SentryService"
import {DateInterval, Dayjs, Granularity, SimpleTimeIntervalWithSourceResolved} from "@biron-data/period-resolver"

interface Props {
  value: DateInterval
  onChange: (date: Omit<DateInterval, 'name'>) => void
}

const DashboardDateSelector: FunctionComponent<Props> = ({value, onChange}) => {
  const granularity = useMemo(
    () => value.granularity || computeDefaultGranularity(value),
    [value],
  )

  const handleIntervalChange = useCallback(
    (newValue: Omit<SimpleTimeIntervalWithSourceResolved, 'name'>) => onChange({
      ...newValue,
      granularity: computeDefaultGranularity(newValue),
    }),
    [onChange],
  )
  const handleGranularityChange = useCallback(
    (newGranularity: Granularity | undefined) => onChange({...value, granularity: newGranularity}),
    [onChange, value],
  )
  return <StyledContainer>
    <IntervalSelector value={value} onChange={handleIntervalChange}/>
    <GranularitySelector value={granularity} onChange={handleGranularityChange}/>
  </StyledContainer>
}

export default DashboardDateSelector

const StyledContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  white-space: nowrap; // useful for small resolution
  color: var(--main-text-color) !important;

  .antcustom-picker-range-separator {
    padding: 0px;
  }
  
 
  .antcustom-picker{
    padding: 4px 0px 4px;
  }
  
  .biron-calendar-interval-selector {
    margin-right: 20px;
    cursor: pointer;
    max-height: 40px;
    min-height: 40px;
  }
  
  .biron-calendar-interval-selector input {
    cursor: pointer;
  }
  
  .ant-radio-button-wrapper {
    box-shadow: none !important;
  }
`

export function computeDefaultGranularity({start, end}: { start: Dayjs, end: Dayjs }) {
  if (!start || !end) {
    captureWarn("Start or end of dashboardSelection weren't defined, default to quarter")
    return Granularity.QUARTER
  }
  const diffDays = end.diff(start, "day")
  if (diffDays <= 31) {
    return Granularity.DAY
  } else if (diffDays < 180) {
    return Granularity.WEEK
  } else if (diffDays < 729) {
    return Granularity.MONTH
  } else {
    return Granularity.QUARTER
  }
}