import {useCallback} from "react"
import {ChartDtoDetailToAdd} from "types/charts"
import {UseGetChartLayoutForAddType} from "hooks/useGetLayoutForAddChart"

export type ButtonPosition =
  {
    x: number,
    y: number,
    w?: number
  }
  | undefined

export type UseHandleChartAddType = (
  chartAdd: (params: any) => Promise<any>,
  getChartLayoutForAdd: ReturnType<UseGetChartLayoutForAddType>,
  callback: (id: number) => void) => (data: ChartDtoDetailToAdd, buttonPosition?: ButtonPosition) => Promise<void>

export const useHandleChartAdd = (
  chartAdd: (params: any) => Promise<any>,
  getChartLayoutForAdd: ReturnType<UseGetChartLayoutForAddType>,
  callback: (id: number) => void) => {

  return useCallback((data: ChartDtoDetailToAdd, buttonPosition?: ButtonPosition) => {
    return chartAdd({
      ...getChartLayoutForAdd(data.type, buttonPosition),
      ...data,
    })
      .then(newChart => {
        callback(newChart.id)
      })
  }, [callback, chartAdd, getChartLayoutForAdd])
}