import {isEmpty, omit} from 'lodash'
import {NormalizedModel, RawHomepage} from "redux/models/workspace"
import {getDefaultPeriod, PeriodTypes} from "@biron-data/period-resolver"
import {WidgetTypes} from "commons/dashboard/dashboard.types"
import {ChartDtoDetail, ChartDtoDetailTypes, RawChartGenericDtoDetail, RawChartTypes} from "types/charts"
import {DashboardFormats, RawDashboardDtoTypes} from "types/dashboards"
import * as DashboardSchemas from "schemas/dashboard"
import {getPersonalDashboardByUid} from "services/PersonalDashboard"

export function setEntities<S extends NormalizedModel>(state: S, entitiesByType: Record<string, any>) {
  return {
    ...state,
    entities: Object.entries(entitiesByType).reduce((acc, [type, records]) => ({
      ...acc,
      [type]: {
        ...acc[type],
        ...records,
      },
    }), state.entities),
  }
}

export const deleteEntity: (state: any, data: { type: any, id: any }) => any = (state, {type: entityType, id: entityId}) => ({
  ...state,
  entities: {
    ...state.entities,
    [entityType]: omit(state.entities[entityType] || {}, [String(entityId)]),
  },
})

export const consolidateRawDashboardPeriod = <T extends (RawDashboardDtoTypes | RawHomepage)>(dashboard: T, periods: PeriodTypes[]) => {
  const defaultPeriod = getDefaultPeriod()
  return {
    ...dashboard,
    period: periods.find((period: any) => period.code === dashboard.period.code) ?? defaultPeriod,
    charts: dashboard.charts.map(chart => {
      switch (chart.type) {
        case WidgetTypes.GENERIC:
          return {
            ...chart,
            bqConf: {
              ...chart.bqConf,
              period: chart.bqConf.period ? periods.find((period) => period.code === chart.bqConf.period?.code) : undefined,
              metrics: chart.bqConf.metrics.map(metric => ({
                ...metric,
                overridePeriod: metric.overridePeriod ? periods.find((period) => period.code === metric.overridePeriod?.code) : undefined,
              })),
            },
          } as RawChartGenericDtoDetail
        case WidgetTypes.TARGET:
          return {
            ...chart,
            period: periods.find((period) => period.code === chart.period.code) ?? defaultPeriod,
          }
        default:
          return chart
      }
    }),
  }
}

export const consolidatedChartPeriod = (chart: RawChartTypes, periods: PeriodTypes[]): RawChartTypes => {
  const defaultPeriod = getDefaultPeriod()
  switch (chart.type) {
    case WidgetTypes.GENERIC: {
      chart.bqConf.period = periods.find(period => period.code === chart.bqConf.period?.code)
      chart.bqConf.metrics = chart.bqConf.metrics.map(metric => ({
        ...metric,
        overridePeriod: periods.find(period => period.code === metric.overridePeriod?.code),
      }))
      break
    }
    case WidgetTypes.TARGET: {
      chart.period = periods.find(period => period.code === chart.period?.code) ?? defaultPeriod
      break
    }
    default:
  }

  return chart
}

export const getDashboardFromList = (uid: string, personalDashboards: DashboardSchemas.NormalizedPersonalDashboardTypes[], chartHashLink?: string) => {
  const dashboard = personalDashboards.find(personalDashboard => personalDashboard.uid === uid)
  if (dashboard) {
    return {
      dashboard: {
        ...dashboard,
        chartHashLink
      },
      ownedByUser: true,
      isFromServer: false,
    }
  }
  return undefined
}

export const getDashboardAndChartsFromUri = async (
  uid: string,
  environmentId: number,
  dashboardModel: {
    normalizedDashboard: DashboardSchemas.NormalizedPersonalDashboardTypes,
    charts: Record<string, ChartDtoDetailTypes>,
  } | undefined,
  periods: PeriodTypes[],
  chartHashLink?: string): Promise<{
  dashboard: DashboardSchemas.NormalizedDashboardTypes
  charts: Record<string, ChartDtoDetailTypes>
  ownedByUser: boolean
  isFromServer: boolean
}> => {
  if (dashboardModel && dashboardModel.normalizedDashboard.format === DashboardFormats.detailed) {
    return {
      dashboard: {
        ...dashboardModel.normalizedDashboard,
        chartHashLink
      },
      charts: dashboardModel.charts,
      ownedByUser: true,
      isFromServer: false,
    }
  } else {
    const detailed = consolidateRawDashboardPeriod(await getPersonalDashboardByUid(uid, environmentId), periods)
    const newNormalizedDashboard = DashboardSchemas.normalizeDashboardDetail(consolidateRawDashboardPeriod({
      ...detailed,
      format: DashboardFormats.detailed,
      chartHashLink,
    }, periods))
    return {
      dashboard: newNormalizedDashboard.entities.dashboards[detailed.id],
      charts: Object.fromEntries(newNormalizedDashboard.entities.dashboards[detailed.id].charts.map(chartId => [chartId, newNormalizedDashboard.entities.charts[chartId]])),
      ownedByUser: !isEmpty(dashboardModel),
      isFromServer: !isEmpty(dashboardModel),
    }
  }
}
