import {isString} from 'lodash'
import {ExpandedWorkspace, SummarizedWorkspace} from "redux/models/workspace"
import {NormalizedDashboardTypes} from "schemas/dashboard"
import {DashboardTypes} from "types/dashboards"

// Note on types: If pathOrDashboard is undefined, a SummerizedWorkspace is acceptable
export const buildWorkspaceUri = (workspace: ExpandedWorkspace | SummarizedWorkspace, pathOrDashboard?: string | NormalizedDashboardTypes, chartHashLink?: string) => {
  if (pathOrDashboard && isString(pathOrDashboard)) {
    return `/app/${workspace.uri}/${pathOrDashboard}${chartHashLink ?? ""}`
  } else if (pathOrDashboard) {
    const dashboard = pathOrDashboard as NormalizedDashboardTypes
    return `/app/${workspace.uri}/${dashboard.type === DashboardTypes.workspace ? dashboard.uri : ''}${chartHashLink ?? ""}`
  }
  return `/app/${workspace.uri}/${((workspace as ExpandedWorkspace).homepage ?? {uri: '1-Home'}).uri}${chartHashLink ?? ""}`
}
