import {useCallback} from "react"
import {ChartDtoDetailTypes} from "types/charts"
import {GetWidgetRef} from "components/dashboard/grid/dashboardGridLayoutUtils"

export const runAfterFramePaint = (callback: () => any) => {
  // Queue a "before Render Steps" callback via requestAnimationFrame.
  requestAnimationFrame(() => {
    const messageChannel = new MessageChannel()

    // Setup the callback to run in a Task
    messageChannel.port1.onmessage = () => {
      messageChannel.port1.close()
      messageChannel.port2.close()
      callback()
    }

    // Queue the Task on the Task Queue
    messageChannel.port2.postMessage(undefined)
  })
}

export const useGetLayoutVisibleBounds = (grid: Pick<HTMLDivElement, "scrollTop" | "offsetHeight"> | null) => useCallback(
  () => {
    if (grid) {
      const {scrollTop, offsetHeight} = grid
      return {
        top: scrollTop,
        bottom: scrollTop + offsetHeight,
        height: offsetHeight,
      }
    }
    return undefined
  },
  [grid],
)

export const useGetChartLayoutPosition = (getWidgetRef: GetWidgetRef, sortedCharts: Pick<ChartDtoDetailTypes, "id">[], isChartsMounted: boolean) => useCallback(
  (chartId: number) => {
    const draggableDomNode = getWidgetRef(sortedCharts.findIndex(chart => chart.id === chartId))?.getDraggableDomNode()
    if (!draggableDomNode || !isChartsMounted) { // occurs when user switch between different design modes (desktop/mobile)
      return null
    }
    const top = draggableDomNode.offsetTop + 5
    const bottom = top + draggableDomNode.offsetHeight
    return {
      top,
      bottom,
    }
  },
  [getWidgetRef, isChartsMounted, sortedCharts],
)
