import {sortBy} from "lodash"
import * as d3 from "d3"

const colorSet = ['#1f77b4', '#ff7f0e', '#2ca02c', '#d62728', '#9467bd', '#8c564b', '#e377c2', '#bcbd22', '#17becf'].map(v => d3.rgb(v))
const colorOther = d3.rgb('#525252')
const colorNa = d3.rgb('#717171')

export const colorScale = (series: any) => {
  const colorsPerLabel = sortBy(series.filter((aSeries: any) => !(aSeries.isOther || aSeries.label === 'na' || !aSeries.label)), aSeries => aSeries.label)
    .reduce((acc, aSeries, index) => ({
      ...acc,
      [aSeries.label]: colorSet[index % colorSet.length],
    }), {})

  const colorRange = series.map((aSeries: any) => {
    if (aSeries.isOther) {
      return colorOther
    } else if (!aSeries.label || aSeries.label === 'na') {
      return colorNa
    } else {
      return colorsPerLabel[aSeries.label]
    }
  })

  return d3.scaleOrdinal().domain(colorRange.keys()).range(colorRange)
}

/**
 * @param {array} values: int array of int values
 * @param {array} range: int array of axis length
 * @return {d3.scale} d3 scale
 */
export const numberScale = (values: number[], range: number[]) => {
  const nonInfMaxValue = values.reduce((prev, v) => isFinite(v) && v > prev ? v : prev, Number.MIN_VALUE)
  const nonInfMinValue = values.reduce((prev, v) => isFinite(v) && v < prev ? v : prev, Number.MAX_VALUE)
  const nonInfValues = values.map(v => Math.max(Math.min(v, nonInfMaxValue), nonInfMinValue))

  let calcData = nonInfValues
  // edge case : the data array is full of only one value
  if (calcData.filter((value, index, self) => self.indexOf(value) === index).length < 2) {
    // edge case #1 : full of 0 because of empty data => [0, 1] domain
    if (calcData[0] === 0) {
      calcData = [0, 1]
    } else {

      /*
       *  edge case #2 : one value (usually because there is only one time in the set)
       * => we set the domain to [0, x*2] to make sure the only data is in center of the chart.
       */
      calcData = [0, nonInfValues[0] * 2]
    }
  }

  return d3.scaleLinear()
    .domain(d3.extent(calcData) as any)
    // .nice()// make axis end in round number
    .range(range)
    .clamp(true)
}
