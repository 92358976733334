/* eslint-disable max-lines */

import {useEffect, useMemo} from "react"
import {MetaModelView, ConfMetricFilterTypes,
  ConfOrderBy,
  ConfSlicer,
  GenericChartTypes, GroupedOptions,
  MetricDtoDetail,
  SlicerDimensionDtoDetail,} from "@biron-data/react-bqconf"
import {ConfLimit} from "types/widgets"
import {
  GenericAdditionalDetails,
  ChartTypeWithDisabledReason,
  ConfigCache,
  ConfigurationLimitWithDisablingReasons,
  ConfigurationOrderByWithDisablingReasons,
  GenericExtendedConfModel,
  SlicerConfiguration,
} from "components/forms/chart/types"
import {Granularity} from "@biron-data/period-resolver"
import {useLimits, useMetricFilters, useOptions, useOrderBys, useSlicers} from "components/forms/chart/useDependenciesHooks"

export const useFixLimits = (
  cache: ConfigCache,
  onChange: (changedValues: Partial<GenericExtendedConfModel>) => void,
  availableLimitConfiguration: ConfigurationLimitWithDisablingReasons[],
  metrics: MetricDtoDetail[],
  slicers: ConfSlicer[],
  limits?: ConfLimit[],
  displayType?: GenericChartTypes) => {
  const confLimits = useLimits(cache, metrics, slicers, availableLimitConfiguration, displayType)

  useEffect(() => {
      if ((!limits) || (limits.length !== confLimits.length || !confLimits.every((limit, index) => limits[index].limitSeries === limit.limitSeries && limits[index].hideOthers === limit.hideOthers))) {
        onChange({limits: confLimits})
      }
    },
    [confLimits, limits, onChange],
  )
}

export const useFixSlicers = (
  cache: ConfigCache,
  slicers: ConfSlicer[],
  onChange: (changedValues: Partial<GenericExtendedConfModel>) => void,
  slicerHasBeenModified: boolean,
  granularity: Granularity | null,
  slicerConfiguration?: SlicerConfiguration,
  displayType?: GenericChartTypes) => {
  const confSlicers = useSlicers(cache, granularity, slicerConfiguration, displayType)
  useEffect(() => {
      if (!slicerHasBeenModified
        && slicers
        && (slicers.length !== confSlicers.length || !confSlicers.every((slicer, index) => {
          if (slicers[index].type === "dimension" && slicers[index].type === "dimension") {
            return (slicers[index] as SlicerDimensionDtoDetail).dimensionCode === (slicer as SlicerDimensionDtoDetail).dimensionCode
          }
          return slicers[index].type === slicer.type
        }))) {
        onChange({slicers: confSlicers})
      }
    },
    [slicers, confSlicers, onChange, slicerHasBeenModified],
  )
}

export const useFixOrderBys = (
  cache: ConfigCache,
  onChange: (changedValues: Partial<GenericExtendedConfModel>) => void,
  metrics: MetricDtoDetail[],
  slicers: ConfSlicer[],
  viewsWithMetrics: MetaModelView[],
  groupedOptions: GroupedOptions,
  orderBys?: ConfOrderBy[],
  availableSortsConfiguration?: ConfigurationOrderByWithDisablingReasons[],
  displayType?: GenericChartTypes) => {
  const confOrderBys = useOrderBys(cache, viewsWithMetrics, groupedOptions, availableSortsConfiguration, displayType)
  const validatedSorts = useMemo(() => confOrderBys.filter(sort => sort.column < metrics.length + slicers.length), [confOrderBys, metrics.length, slicers.length])

  useEffect(() => {
      if (orderBys && ((orderBys.length !== validatedSorts.length) || !validatedSorts.every((sort, index) => orderBys[index].column === sort?.column && orderBys[index].value === sort?.value && orderBys[index].asc === sort?.asc))) {
        onChange?.({orderBys: validatedSorts})
      }
    },
    [validatedSorts, onChange, orderBys],
  )
}

export const useFixMetricFilters = (
  cache: Pick<ConfigCache, "metricFilters">,
  onChange: (changedValues: Partial<GenericExtendedConfModel>) => void,
  metricFilters: ConfMetricFilterTypes[],
  configuration?: Pick<ChartTypeWithDisabledReason, "isMetricsFilterEnabled">,
  displayType?: GenericChartTypes,
) => {
  const confFilters = useMetricFilters(cache, configuration, displayType)

  useEffect(() => {
    if (metricFilters.length !== confFilters.length || !metricFilters.every((filter, i) => confFilters[i])) {
      onChange({metricFilters: confFilters})
    }
  }, [confFilters, metricFilters, onChange])
}

export const useFixOptions = (cache: Pick<ConfigCache, 'options'>, displayLabels: boolean | undefined, ignoreMetrics0: boolean | undefined, asPercentage: boolean | undefined, displayType: GenericChartTypes | undefined, onChange: (changedValues: Partial<GenericExtendedConfModel>) => void) => {
  const options = useOptions(cache, displayType)

  useEffect(() => {
    const actualValues: {[key in GenericAdditionalDetails]: boolean | undefined} = {
      [GenericAdditionalDetails.displayLabels]: displayLabels,
      [GenericAdditionalDetails.ignoreMetrics0]: ignoreMetrics0,
      [GenericAdditionalDetails.asPercentage]: asPercentage
    }
    if(options.find(([key, value]) => actualValues[key] !== value)) {
      onChange(Object.fromEntries(options))
    }
  }, [asPercentage, displayLabels, ignoreMetrics0, onChange, options])
}