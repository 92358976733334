import {denormalize, normalize, NormalizedSchema, schema} from 'normalizr'
import {ExpandedWorkspace, Menu, RawExpandedWorkspace} from "redux/models/workspace"
import {ChartDtoDetailTypes} from "types/charts"
import {dashboardDetail, NormalizedWorkspaceDashboard} from "schemas/dashboard"

export interface NormalizedMenu extends Omit<Menu, "dashboards"> {
  dashboards: number[]
}

export interface NormalizedWorkspaceDetail extends Omit<ExpandedWorkspace, "homepage" | "menus"> {
  menus: number[]
  homepage: number
}


const workspaceResume = new schema.Entity(
  'workspaces',
)
const workspaceResumes = new schema.Array(workspaceResume)
export const normalizeWorkspaceResumes = (data: any) => normalize(data, workspaceResumes)


const menuDetail = new schema.Entity(
  'menus',
  {
    dashboards: [dashboardDetail],
  },
)
const workspaceDetail = new schema.Entity(
  'workspaces',
  {
    homepage: dashboardDetail,
    menus: [menuDetail],
  },
)

// As this PR is not merged, we must specify normalizr types by hand https://github.com/paularmstrong/normalizr/pull/474

export interface NormalizedWorkspaceModels {
  workspaces: Record<string, NormalizedWorkspaceDetail>
  menus: Record<string, NormalizedMenu>
  dashboards: Record<string, NormalizedWorkspaceDashboard>
  charts: Record<string, ChartDtoDetailTypes>
}

export const normalizeWorkspaceDetail: (data: RawExpandedWorkspace) => NormalizedSchema<NormalizedWorkspaceModels, number> = (data) => normalize(data, workspaceDetail)
export const denormalizeWorkspaceDetail = (input: number, entities: any) => denormalize(input, workspaceDetail, entities)
