/* eslint-disable max-lines */
import {getCharts, getPersonalDashboard, getWorkspaceDashboard} from "redux/currentDashboard.selector"
import {findWorkspaceDashboardInfosWith} from "redux/workspace.selector"
import {omit} from "lodash"
import {NormalizedMenu} from "schemas/workspace"
import {NormalizedDashboardTypes, NormalizedPersonalDashboard, NormalizedWorkspaceDashboard} from "schemas/dashboard"
import {RematchDispatch, RematchRootState} from "@rematch/core"
import RootModel from "redux/models/index"
import {RootState} from "redux/store"
import {buildWorkspaceUri} from "components/workspace/Workspace.utils"
import {ChartDtoDetailTypes} from "types/charts"
import {DashboardTypes, RawDashboardDtoTypes} from "types/dashboards"
import {CurrentDashboardTypes} from "redux/models/currentDashboard.types"
import * as DashboardSchemas from "schemas/dashboard"
import {DateInterval, getResolvedFromPeriod, Granularity} from "@biron-data/period-resolver"
import {getSessionTime} from "services/TimeService"

export const setDashboard = (
  currentDashboard: Omit<CurrentDashboardTypes, "selection" | "dashboard" | "charts">,
  dashboard: DashboardSchemas.NormalizedDashboardTypes | undefined,
  charts: Record<string, ChartDtoDetailTypes> | undefined,
  timestamp: number,
  ownedByUser: boolean,
  dispatch: RematchDispatch<RootModel>) => {
  dispatch.currentDashboard.set({
    ...currentDashboard,
    ownedByUser,
    dashboard,
    charts,
    selection: dashboard
      ? {
        initTimestamp: timestamp || Date.now(),
        date: {
          granularity: Granularity.DAY,
          ...(getSessionTime() || getResolvedFromPeriod(dashboard.period)) as DateInterval,
        },
        filters: Object.assign([], dashboard.filters),
      }
      : undefined,
  } as CurrentDashboardTypes)
}

export const getChart = (chartId: number, state: RootState) => {
  const charts = getCharts(state)
  return charts.find(chart => chart.id === chartId)
}

export const getDashboard = (state: RootState) => {
  switch (state.currentDashboard?.dashboard?.type) {
    case DashboardTypes.workspace:
      return getWorkspaceDashboard(state)
    case DashboardTypes.personal:
      return getPersonalDashboard(state)
    default:
      return undefined
  }
}

export const makeRightRequest = async (
  dashboard: NormalizedDashboardTypes,
  data: Omit<NormalizedWorkspaceDashboard, 'charts'> | Omit<NormalizedPersonalDashboard, 'charts'>,
  workspaceDashboardCallback: (data: Omit<NormalizedDashboardTypes, 'slicers' | 'charts'>) => Promise<RawDashboardDtoTypes>,
  personalDashboardCallback: (data: Omit<NormalizedPersonalDashboard, 'charts'>) => Promise<RawDashboardDtoTypes>,
): Promise<RawDashboardDtoTypes | undefined> => {
  switch (data.type) {
    case DashboardTypes.workspace: {
      const consolidatedData = omit({...dashboard, ...data}, ['slicers', 'charts']) as Omit<NormalizedDashboardTypes, 'slicers' | 'charts'>
      return workspaceDashboardCallback(consolidatedData)
    }
    case DashboardTypes.personal:
      return personalDashboardCallback(data)
    default:
      return undefined
  }
}

export const applyModification = (
  state: RematchRootState<RootModel, Record<string, never>>,
  workspaceDashboardCallback: () => void,
  personalDashboardCallback: () => void,
) => {
  switch (state.currentDashboard.type) {
    case DashboardTypes.workspace:
      workspaceDashboardCallback()
      break
    case DashboardTypes.personal:
      if (state.currentDashboard.ownedByUser) {
        personalDashboardCallback()
      }
      break
    default:
  }
}

export const findFromUri: (state: RootState, uri: string, chartHashLink?: string) =>
  {
    redirect?: string,
    dashboard?: NormalizedDashboardTypes,
    menu?: NormalizedMenu,
    charts?: Record<string, ChartDtoDetailTypes> | undefined
  }
  = (state: RootState, uri: string, chartHashLink) => {
  if (uri) {
    const relativeIdMatch = uri.match(/^(\d+)(:?-|$)/)
    const relativeId = relativeIdMatch && Number(relativeIdMatch[1])
    const lookupResult = findWorkspaceDashboardInfosWith(state, 'relativeId', relativeId)
    if (lookupResult) {
      lookupResult.dashboard.chartHashLink = chartHashLink
      return {...lookupResult}
    }
  }
  if (!state.workspace.currentWorkspace) {
    return {redirect: "/"}
  }
  // redirect to the home of this dashboard
  // We rewrite absolute path here to prevent infinite loop
  return {redirect: buildWorkspaceUri(state.workspace.currentWorkspace, state.workspace.entities.dashboards[state.workspace.currentWorkspace.homepage], chartHashLink)}
}

