import React, {memo, useMemo, useRef} from 'react'
import {WidgetTypes} from "commons/dashboard/dashboard.types"
import DividerConfBody from './DividerConfBody'
import NavAddDashboardFormBody from './NavAddDashboardFormBody'
import NavEditMenuFormBody from './NavEditMenuFormBody'
import NavCloneDashboardFormBody from './NavCloneDashboardFormBody'
import NavAddMenuFormBody from './NavAddMenuFormBody'
import NavEditDashboardFormBody from './NavEditDashboardFormBody'
import {Form} from 'antd'
import {FormItemKeys} from "commons/keywords/form"
import {Position} from "classes/workflows/main-workflows/FormWorkflow"
import {FormKeys, FormType} from "components/forms/Form.types"
import {useGetPopupContainer, useFields} from '@biron-data/react-hooks'
import WorkspaceConf from "./WorkspaceConf";
import AdvancedWorkspaceConf from "./AdvancedWorkspaceConf";
import DuplicateWorkspaceConf from "./DuplicateWorkspaceConf";

interface FormBodyProps<T> {
  loading: boolean,
  data: T,
  update: (key: string, newValue: string) => void,
  formType: FormType,
  availables: {
    menus: any[]
    dashboardsPositions: Position[]
    menusPositions: Position[]
  }
}

export interface FormBodyComponentProps<T> extends FormBodyProps<T> {
  getPopupContainer?: (triggerNode: HTMLElement) => HTMLElement;
  value?: string
  onChange?: (key: FormItemKeys, newValue: string) => void
}

const FormBody = <T, >(props: FormBodyProps<T>) => {
  const popupContainerRef = useRef<any>()
  const getPopupContainer = useGetPopupContainer(popupContainerRef.current)
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [form] = Form.useForm()
  const fields = useFields(props.data, [])

  const body = useMemo(() => <Form form={form} fields={fields} onValuesChange={(changedValue) => {
      props.update(Object.keys(changedValue)[0], Object.values(changedValue)[0] as string)
    }
    }>
      {renderSpecificConfBody({
        ...props,
        getPopupContainer,
      })}
    </Form>,
    [form, fields, props, getPopupContainer])

  return <div ref={popupContainerRef}>{body}</div>
}

export default memo(FormBody) as typeof FormBody

const renderSpecificConfBody = <T, >(props: FormBodyComponentProps<T>) => {
  const {formType} = props
  switch (formType.type) {
    case FormKeys.ADD_DASHBOARD:
      return <NavAddDashboardFormBody {...props}/>
    case FormKeys.ADD_MENU:
      return <NavAddMenuFormBody {...props}/>
    case FormKeys.EDIT_MENU:
      return <NavEditMenuFormBody {...props}/>
    case FormKeys.EDIT_DASHBOARD:
      return <NavEditDashboardFormBody {...props}/>
    case FormKeys.CLONE_DASHBOARD:
      return <NavCloneDashboardFormBody {...props}/>;
    case FormKeys.WORKSPACE_CONF:
      return <WorkspaceConf {...props}/>
    case FormKeys.ADVANCED_WORKSPACE_CONF:
      return <AdvancedWorkspaceConf {...props}/>
    case FormKeys.DUPLICATE_WORKSPACE_CONF:
      return <DuplicateWorkspaceConf {...props}/>
    case FormKeys.CHART_CONF:
      switch (formType.chartType) {
        case WidgetTypes.DIVIDER:
          return <DividerConfBody {...props}/>;
        default:
          // eslint-disable-next-line no-console
          console.error('chartType', formType.chartType)
      }
      break;
    default:
      // eslint-disable-next-line no-console
      console.error('formType', formType.type)
  }
  return null;
}
