import React from 'react'
import Language from 'language'
import {TooltipPopover, IconContainer} from "@biron-data/react-components"
import {LinkIcon} from "@heroicons/react/outline"

interface Props {
  onClick: () => any
}

const WidgetTopperLink: (props: Props) => JSX.Element = ({onClick}) =>
  <TooltipPopover
    size={'small'} content={Language.get('chart-link-button')}>
    <div>
      <IconContainer clickable={true} hoverColor={"var(--primary-light)"} containerSize={28}><LinkIcon className="disable-grab"
                                                                                                       onClick={onClick}/></IconContainer>
    </div>
  </TooltipPopover>

export default WidgetTopperLink
