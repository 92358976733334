import {create, deletePeriod, getPeriodsByClient, update, usage} from "services/PeriodService"
import {returnOrExecBatch} from 'commons/batch'
import {getCurrentEnvironmentId} from "redux/environment.selector"
import {getPeriods} from "redux/period.selector"
import {createModel} from "@rematch/core"
import RootModel from "redux/models/index"
import {PeriodTypes} from "@biron-data/period-resolver"

const initialState: PeriodTypes[] = []

export default createModel<RootModel>()({
  state: initialState,
  reducers: {
    set(state, periods: PeriodTypes[]) {
      return periods
    },
    'period/cleanStates': () => {
      return initialState
    },
  },

  effects: (dispatch) => {
    return {
      async loadPeriods({forBatch = false, environment}: { forBatch?: boolean, environment: { id: number } }) {
        const normalized = await getPeriodsByClient(environment.id)
        return returnOrExecBatch(forBatch, () => dispatch.periods.set(normalized))
      },
      async getPeriodExportUsage({periodId}: { periodId: number }) {
        return usage(periodId)
          .then(newPeriod => {
            return newPeriod
          })
      },
      async createPeriod({data}: { data: PeriodTypes }, state) {
        const environmentId = getCurrentEnvironmentId(state)
        return create(data, environmentId)
          .then(newPeriod => {
            dispatch.periods.loadPeriods({environment: {id: environmentId}})
            return newPeriod
          })
      },
      async updatePeriod({data}: { data: PeriodTypes }, state) {
        const environmentId = getCurrentEnvironmentId(state)
        return update(data)
          .then(newPeriod => {
            dispatch.periods.loadPeriods({environment: {id: environmentId}})
            return newPeriod
          })
      },
      async deletePeriod(id: number, state) {
        await deletePeriod(id)
        const periods = getPeriods(state as never) as PeriodTypes[]
        dispatch.periods.set(Object.assign([], Object.values(periods).filter(e => e.id !== id)))
      },
    }
  },
})

