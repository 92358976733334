import * as d3 from "d3-format"

export const formatter = {format: (format: any) => (value: any) => d3.format(format)(value).replace(/,/g, ' ').replace(/\./g, ',')}

/*
 * export const formatter = d3.formatLocale({
 *  decimal: ",",
 *  thousands: " ",
 *  grouping: [3],
 *  currency: ["$", ""], // unused
 *  dateTime: "%a %b %e %X %Y", // unused
 *  date: "%m/%d/%Y", // unused
 *  time: "%H:%M:%S", // unused
 *  periods: ["AM", "PM"], // unused
 *  days: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"], // unused
 *  shortDays: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"], // unused
 *  months: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"], // unused
 *  shortMonths: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"] // unused
 * });
 */
