import {useMemo} from "react"
import {WidgetTypes} from "commons/dashboard/dashboard.types"
import {ChartTypeWithDisabledReason} from "components/forms/chart/types"
import {GenericChartTypes} from "@biron-data/react-bqconf"

export const useConsolidatedChartType = (chartTypes: ChartTypeWithDisabledReason[], originalValue: GenericChartTypes | undefined) => useMemo(() => {
    if ((chartTypes.find(({type}) => type === originalValue)?.disabledReasons?.length ?? 0) > 0) {
      const priorityType = chartTypes.find(t => t.type === originalValue)?.nextCompatibleTypes.find(nextCompatibleType => {
        return chartTypes.find(({type, disabledReasons}) => type === nextCompatibleType && disabledReasons.length === 0)?.type
      })
      const destinationType = priorityType ?? chartTypes.find(({disabledReasons}) => disabledReasons.length === 0)?.type
      if (destinationType !== WidgetTypes.EXPORT && destinationType !== WidgetTypes.TARGET) {
        return destinationType as GenericChartTypes
      } else {
        return undefined
      }
    } else {
      return originalValue
    }
  },
  [chartTypes, originalValue],
)