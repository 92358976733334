import styled from "styled-components"
import {CHART_PADDING_X, CHART_PADDING_Y} from "components/charts/Chart.constants"

export const CardEffectDiv = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden; // used to color widget header after link animation
  background-color: white;
  border-radius: var(--big-border-radius);
  box-shadow: 0px 8px 12px rgba(27, 27, 29, 0.02);

  -webkit-touch-callout: none; /* iOS Safari */
  /* Non-prefixed version, currently supported by Chrome and Opera */
`

export const CardContentContainer = styled.div`
  padding: ${CHART_PADDING_Y}px ${CHART_PADDING_X}px ${CHART_PADDING_Y}px ${CHART_PADDING_X}px;
`