// @ts-nocheck   no check as this file should be deleted

import React from 'react'
import PropTypes from 'prop-types'
import Language from 'language'
import {formatValue, percentageFormat} from 'commons/format/formatter'
import {transitionTime} from './TargetChart.utils'
import * as d3 from 'd3'

const targetsCssClass = ['target-bar', 'target-to-date-bar', 'prevision-bar', 'achieved-bar']

const criterias = {
  'actual-modele': {
    group: (s, i) => i === 1 || i === 3,
    empty: (s, i) => i === 0 || i === 3,
  },
}
const criteriaName = 'actual-modele'
const criteria = criterias[criteriaName]
const achievedY = 50
const GAP = 30

class TargetChartBars extends React.Component<any, any> {

  constructor(props) {
    super(props)
    this.state = {focused: -1}
  }

  drawDataText = () => {
    const {data, format} = this.props
    d3.select(this.refs['achieved-data'])
      .text(`${formatValue(data[1].values[0], format)}`)
    d3.select(this.refs['achieved-data-percentage'])
      .attr('x', this.refs['achieved-data'].getBoundingClientRect().width + GAP)
      .text(`${formatValue(data[1].values[0] / data[0].values[0], percentageFormat)}`)
    d3.select(this.refs['target-data'])
      .text(formatValue(data[0].values[0], format))
  }
  draw = () => {
    const {data, scale, animation} = this.props
    const {x} = scale
    const heightForSelected = 16
    const heightForOthers = 16
    const yForSelected = achievedY + 15
    const yForOthers = achievedY + 36
    this.drawDataText()
    for (let i = 0; i < data.length; i++) {
      const d = data[i]
      const {label, values} = d
      const y = criteria.group(d, i) ? yForSelected : yForOthers
      const height = criteria.group(d, i) ? heightForSelected : heightForOthers
      if (animation === 'reset' && i === data.length - 1) {
        d3.select(this.refs[label])
          .attr('x', x(0))
          .attr('width', 0)
          .attr('y', y)
          .attr('height', height);
        d3.select(this.refs[label])
          .transition()
          .attr('x', Math.min(x(0), x(values[0])))
          .attr('width', Math.max(x(values[0]) - x(0), x(0) - x(values[0])))
          .attr('y', y)
          .attr('height', height)
          .duration(transitionTime);
      } else {
        d3.select(this.refs[label])
          .attr('x', Math.min(x(0), x(values[0])))
          .attr('width', Math.max(x(values[0]) - x(0), x(0) - x(values[0])))
          .attr('y', y)
          .attr('height', height)
      }
    }
    // draw target line
    d3.select(this.refs['target-line']).attr("d", d3.line()([
      [Math.max(x(data[0].values[0]), x(0)), yForSelected],
      [Math.max(x(data[0].values[0]), x(0)), yForOthers + heightForOthers],
    ]));
  };

  enter = i => () => {
    this.props.focusTooltip(this.props.data[i]);
    this.setState({focused: i});
    this.props.viewTooltip(true);
  };
  leave = () => {
    this.setState({focused: -1});
    this.props.viewTooltip(false);
  };
  move = event => {
    this.props.moveTooltip({x: event.clientX, y: event.clientY});
  };

  componentDidMount() {
    this.draw();
  }

  componentDidUpdate() {
    this.draw();
  }


  render() {
    const {data} = this.props
    const mainColor = data[2].values[0] > data[1].values[0] ? 'var(--target-red)' : 'var(--target-green)'
    const stdColor = 'var(--target-blue)'
    const dataLegendText = {fontSize: 14}
    const dataText = {fontSize: 20}
    const legendText = {fontSize: 14}
    const legendData = {fontSize: 20}
    const startY = 15
    const targetLine = {strokeWidth: 1, stroke: stdColor, strokeDasharray: '5,4'}
    // in order to get a z-index for the datas (previsions "under" achieved), we are going to put the series in a order (achieved and previsions are "under")
    const dataSorted = data.map((d, i) => ({d, i}))
    // eslint-disable-next-line no-nested-ternary
    dataSorted.sort((a, b) => criteria.empty(a.d, a.i) ? -1 : (criteria.empty(b.d, b.i) ? 1 : 0))
    return <g>
      <text ref={'achieved-label'} style={dataLegendText} x={33} y={startY}>{Language.get('target-legend-achieved')}</text>
      <text ref={'achieved-data'} style={dataText} x={15} y={startY + GAP}/>
      <text ref={'achieved-data-percentage'} style={{dataLegendText, fill: "var(--medium-grey)"}} x={15} y={startY + GAP}/>
      <circle cx="20" cy="10" r="5" style={{fill: mainColor}}/>

      <text ref={'target-label'} style={legendText} x={268} y={startY} width={100}>{Language.get('target-legend-target')}</text>
      <text ref={'target-data'} style={legendData} x={250} y={startY + GAP}/>
      <circle cx="255" cy="10" r="5" style={{fill: "var(--primary)"}}/>

      {dataSorted.map(({d, i}) => {
        const color = criteria.group(d, i) ? mainColor : stdColor
        const style = criteria.empty(d, i)
          ? {fillOpacity: this.state.focused === i ? '0.4' : '0.1', fill: color, stroke: 'none'}
          : {fillOpacity: this.state.focused === i ? '1' : '0.8', fill: color, stroke: 'none'}
        return <rect
          ref={d.label}
          onMouseEnter={this.enter(i)}
          onMouseLeave={this.leave}
          onMouseMove={this.move}
          className={`chartBar ${targetsCssClass[i]}`}
          style={style}
          key={i}/>;
      })}
      <path ref={'target-line'} style={targetLine}/>
    </g>;
  }
}

export default TargetChartBars

TargetChartBars.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    values: PropTypes.arrayOf(PropTypes.number),
    label: PropTypes.string,
    format: PropTypes.object,
  })),
  scale: PropTypes.object,
  height: PropTypes.number,
  animation: PropTypes.string,
  format: PropTypes.object,
  focusTooltip: PropTypes.func,
  viewTooltip: PropTypes.func,
  moveTooltip: PropTypes.func,
}
