import {RootState} from "redux/store"
import {ChartDtoDetail} from "types/charts"
import {MetricSelectorValue} from "@biron-data/react-bqconf"

export const getCopiedChart = () => (state: RootState): ChartDtoDetail => {
  return state.clipboard.chart as ChartDtoDetail
}

export const getCopiedMetric = () => (state: RootState): MetricSelectorValue => {
  return state.clipboard.metric as MetricSelectorValue
}


