import React from 'react'
import MainLayout from "components/mainLayout/MainLayout"
import Language from "./language"
import styled from "styled-components"

const StyledDiv = styled.div`
  height: 100%;
  margin: 20px;
`

const NoAuthorization = () => {
  return <MainLayout canEditDashboardContent={false} canEditDashboardProperties={false}>
    <StyledDiv>{Language.get('navigation-noAuthorization')}</StyledDiv>
  </MainLayout>
}

export default NoAuthorization
