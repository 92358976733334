import React from 'react'
import PositionMenu from '.././confItems/PositionMenu'
import {FormItemKeys} from 'commons/keywords/form'
import {FormBodyComponentProps} from "components/forms/confBodies/FormBody"
import {Loader} from "@biron-data/react-components"

const NavEditMenuFormBody = <T, >({
                                    loading,
                                    getPopupContainer,
                                    availables: {menusPositions = []},
                                    value,
                                    onChange,
                                  }: FormBodyComponentProps<T>) => {
  return <Loader loading={loading}>
    <PositionMenu
      name={FormItemKeys.POSITION_MENU}
      options={menusPositions}
      span={24}
      {...{
        getPopupContainer,
        value,
        onChange,
      }}/>
  </Loader>
}

export default NavEditMenuFormBody
