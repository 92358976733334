import API from './api'
import {RawExpandedWorkspace, SummarizedWorkspace} from "redux/models/workspace"
import {DashboardDto, MenuDto} from "components/workspace/WorkspaceBridge.SiderContainer"
import {PeriodSummary} from "@biron-data/period-resolver"
import {DashboardDtoTypes, RawDashboardDtoTypes} from "types/dashboards"
import {NormalizedDashboardTypes} from "schemas/dashboard"

export const getWorkspaces = () =>
  API.instanceData.get<void, SummarizedWorkspace[]>(`/workspace/authorized`)
export const getWorkspace = async (workspaceId: number) => API.instanceData.get<void, RawExpandedWorkspace>(`/workspace/${workspaceId}`)

export const addMenu = ({positionMenu, ...data}: MenuDto, workspaceId: number) =>
  API.instanceData.post(`/menu`, data, {
    params: {
      position: positionMenu,
      workspaceId,
    },
  })
export const editMenu = ({positionMenu, workspaceId, ...data}: MenuDto & { workspaceId: number }, menuId: number | string) =>
  API.instanceData.put(`/menu/${menuId}`, data, {
    params: {
      position: positionMenu,
      workspaceId,
    },
  })
export const deleteMenu = (menuId: string | number) =>
  API.instanceData.delete(`/menu/${menuId}`)

export const addDashboard = ({positionDashboard, ...data}: DashboardDto, menuId: number) =>
  API.instanceData.post<void, RawDashboardDtoTypes>(`/dashboard/w`, data, {
    params: {
      menuId,
      position: positionDashboard,
    },
  })

export const updateDashboardPeriodAndFilters = (
  dashboardId: number,
  dashboard: Pick<NormalizedDashboardTypes, 'filters'> & {
    period: PeriodSummary
  }) => API.instanceData.put<void, RawDashboardDtoTypes>(`/dashboard/w/${dashboardId}`, dashboard)

export const updateDashboardTitleAndPosition = (
  dashboardId: number,
  dashboard: Omit<DashboardDtoTypes, 'relativeId' | 'charts' | 'slicers' | 'filters'>,
  uriParams: {
    menuId?: number,
    newMenuId: number,
    position: number
  }) => API.instanceData.put<void, RawDashboardDtoTypes>(`/dashboard/w/${dashboardId}`,
  dashboard,
  {params: uriParams},
)

export const cloneWorkspaceDashboard = ({parentMenu, title, positionDashboard, originDashboard}: DashboardDto & { originDashboard: number }) =>
  API.instanceData.post(`/dashboard/w/clone`, null, {
    params: {
      menuId: parentMenu,
      position: positionDashboard,
      refId: originDashboard,
      newTitle: title,
    },
  })

export const deleteDashboard = ({dashboardId, menuId}: { dashboardId: number, menuId: number }) =>
  API.instanceData.delete(`/dashboard/w/${dashboardId}?menuId=${menuId}`)


