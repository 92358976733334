import {convertFilterDetailToConfFilter, extractSlicerDate, extractSlicersDimension, isFilterEmpty,
  DimensionFilterDtoDetail,
  DimensionFilterTypesWithSource,
  FilterDtoDetailTypes,
  Source, ConsolidatedDimension, ConfSlicer, DataSelection, SlicerDateDtoDetailWithSource, SlicerDtoDetailTypes} from "@biron-data/react-bqconf"
import {isEmpty} from "@biron-data/react-components"
import {Granularity, PeriodTypes, TimeInterval, getResolvedFromPeriod} from "@biron-data/period-resolver"
import {ExportMetricWithViewCodeAndAlias} from "components/forms/Form.types"


export const consolidateSlicers = (slicers: SlicerDtoDetailTypes[], dashboardSelection: DataSelection, withDateSlicer: boolean, withChartOverriddenPeriod: boolean | undefined) => {
  const dateSlicer = extractSlicerDate(slicers)
  const consolidatedDateSlicer: SlicerDateDtoDetailWithSource = !dateSlicer || isEmpty(dateSlicer?.granularity) || withChartOverriddenPeriod === false ? {
    type: "date",
    granularity: dashboardSelection.date.granularity ?? Granularity.DAY,
    source: Source.QUERY,
  } : {
    ...dateSlicer,
    granularity: dateSlicer.granularity as Granularity,
    source: Source.CHART,
  }

  const consolidatedSlicers = slicers.map(slicer => {
    switch (slicer.type) {
      case "dimension":
        return slicer
      case "date":
        return consolidatedDateSlicer
      default:
        // eslint-disable-next-line no-case-declarations
        const checkExhaustive: never = slicer
        return checkExhaustive
    }
  })

  if (dateSlicer === undefined && withDateSlicer) {
    return [consolidatedDateSlicer, ...consolidatedSlicers]
    // eslint-disable-next-line no-negated-condition
  } else if (!withDateSlicer) {
    return extractSlicersDimension(consolidatedSlicers)
  } else {
    return consolidatedSlicers
  }
}

export const consolidateFilters = (dashboardSelection: DataSelection, filters: FilterDtoDetailTypes[]): FilterDtoDetailTypes[] => [
  ...dashboardSelection.filters.filter(filter => !isFilterEmpty(filter)),
  ...filters,
]

export const consolidateFiltersAndAddSource = (dashboardSelection: DataSelection, filters: DimensionFilterDtoDetail[]): DimensionFilterTypesWithSource[] => [
  ...dashboardSelection.filters.filter(filter => !isFilterEmpty(filter)).map(filter => ({source: Source.QUERY, ...filter})),
  ...filters.map(filter => ({source: Source.CHART, ...filter})),
]

export const getMetricTimeInterval = (metricPeriod: PeriodTypes | undefined): TimeInterval | undefined => metricPeriod ? {
  start: getResolvedFromPeriod(metricPeriod)?.start.format('YYYY-MM-DD'),
  end: getResolvedFromPeriod(metricPeriod)?.end.format('YYYY-MM-DD'),
} : undefined

export const convertSlicersToConfModel = (slicers: SlicerDtoDetailTypes[]): ConfSlicer[] => slicers.map(slicer => ({
  ...slicer,
  isDefault: false,
}))

export const convertMetricToConfModel = (metrics: ExportMetricWithViewCodeAndAlias[], availableDimensions?: ConsolidatedDimension[]) => metrics.map(metric => ({
  ...metric,
  additionalFilters: convertFilterDetailToConfFilter(metric.additionalFilters, availableDimensions),
}))